import { Ionicons } from '@expo/vector-icons';
import { HStack, Pressable, Text } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { colorTheme } from '~/config/build/tokens';
import type { ReactElement } from 'react';

type Props = {
  title: string;
};

const ModalHeader = ({ title }: Props): ReactElement | null => {
  const navigation = useNavigation();

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      px={4}
      py={4}
      borderBottomWidth={1}
      borderBottomColor="mono300"
      style={{ backgroundColor: colorTheme.mono0 }}
    >
      <Text color="mono900" fontSize="xl" fontWeight="bold">
        {title}
      </Text>
      <Pressable onPress={navigation.goBack}>
        <Ionicons name="close" size={24} color="black" />
      </Pressable>
    </HStack>
  );
};

export default ModalHeader;
