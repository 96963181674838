import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Icon, Pressable, ScrollView, Text, VStack } from '@gluestack-ui/themed-native-base';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import { setChatBotQuickAction } from '~/slices/chatBotQuickActionSlice';
import { useAppDispatch } from '~/store';
import type { Channel as StreamChannel } from 'stream-chat';
interface HistoryChatBotProps {
  appChannel?: StreamChannel<DefaultStreamChatGenerics>;
}

export const HistoryChatBot: React.FC<HistoryChatBotProps> = ({ appChannel }) => {
  const closeThreadButton = document.querySelector('.str-chat__thread-container.str-chat__thread') as HTMLElement;
  const dispatch = useAppDispatch();

  return (
    <VStack
      space="lg"
      paddingHorizontal="sm"
      paddingVertical="xl"
      borderRightWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outline"
      width={300}
    >
      <Pressable
        onPress={() => {
          dispatch(setChatBotQuickAction(true));
          if (closeThreadButton) {
            closeThreadButton.style.display = 'none';
          }
        }}
      >
        <HStack
          space="2xs"
          alignItems="center"
          paddingHorizontal="sm"
          paddingVertical="xs"
          borderRadius={gluestackUIConfig.tokens.radii.lg}
          borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          justifyContent="center"
        >
          <Icon as={MaterialIcons} name="add" size="md" color="primary" />
          <Text color="primary" fontSize="md">
            チャットを新規作成
          </Text>
        </HStack>
      </Pressable>
      <Text color="onSurfaceBright" fontSize="lg" lineHeight={gluestackUIConfig.tokens.lineHeights.lg} bold>
        最近
      </Text>
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        {appChannel?.state?.messageSets[0].messages
          .slice()
          .reverse()
          .map((message) => {
            return (
              <Pressable key={message.id}>
                <HStack padding="xs" space="sm" alignItems="center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 21C9.9 21 8.04167 20.3625 6.425 19.0875C4.80833 17.8125 3.75833 16.1833 3.275 14.2C3.20833 13.95 3.25833 13.7208 3.425 13.5125C3.59167 13.3042 3.81667 13.1833 4.1 13.15C4.36667 13.1167 4.60833 13.1667 4.825 13.3C5.04167 13.4333 5.19167 13.6333 5.275 13.9C5.675 15.4 6.5 16.625 7.75 17.575C9 18.525 10.4167 19 12 19C13.95 19 15.6042 18.3208 16.9625 16.9625C18.3208 15.6042 19 13.95 19 12C19 10.05 18.3208 8.39583 16.9625 7.0375C15.6042 5.67917 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H8C8.28333 8 8.52083 8.09583 8.7125 8.2875C8.90417 8.47917 9 8.71667 9 9C9 9.28333 8.90417 9.52083 8.7125 9.7125C8.52083 9.90417 8.28333 10 8 10H4C3.71667 10 3.47917 9.90417 3.2875 9.7125C3.09583 9.52083 3 9.28333 3 9V5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4C4.28333 4 4.52083 4.09583 4.7125 4.2875C4.90417 4.47917 5 4.71667 5 5V6.35C5.85 5.28333 6.8875 4.45833 8.1125 3.875C9.3375 3.29167 10.6333 3 12 3C13.25 3 14.4208 3.2375 15.5125 3.7125C16.6042 4.1875 17.5542 4.82917 18.3625 5.6375C19.1708 6.44583 19.8125 7.39583 20.2875 8.4875C20.7625 9.57917 21 10.75 21 12C21 13.25 20.7625 14.4208 20.2875 15.5125C19.8125 16.6042 19.1708 17.5542 18.3625 18.3625C17.5542 19.1708 16.6042 19.8125 15.5125 20.2875C14.4208 20.7625 13.25 21 12 21ZM13 11.6L15.5 14.1C15.6833 14.2833 15.775 14.5167 15.775 14.8C15.775 15.0833 15.6833 15.3167 15.5 15.5C15.3167 15.6833 15.0833 15.775 14.8 15.775C14.5167 15.775 14.2833 15.6833 14.1 15.5L11.3 12.7C11.2 12.6 11.125 12.4875 11.075 12.3625C11.025 12.2375 11 12.1083 11 11.975V8C11 7.71667 11.0958 7.47917 11.2875 7.2875C11.4792 7.09583 11.7167 7 12 7C12.2833 7 12.5208 7.09583 12.7125 7.2875C12.9042 7.47917 13 7.71667 13 8V11.6Z"
                      fill="#737373"
                    />
                  </svg>
                  <Text color="onSurface" fontSize="lg" lineHeight={gluestackUIConfig.tokens.lineHeights.lg}>
                    {message?.text?.replace(/^@chumly\s*/, '')}
                  </Text>
                </HStack>
              </Pressable>
            );
          })}
      </ScrollView>
    </VStack>
  );
};
