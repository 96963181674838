import { U_FEED_URL_BASE } from '@env';
import { Avatar, Box, HStack, Text, Tooltip } from '@gluestack-ui/themed-native-base';
import DOMPurify from 'dompurify';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import rehypeHighlight from 'rehype-highlight';
import remarkRehype from 'remark-rehype';
import {
  useMessageContext,
  renderText,
  useChannelStateContext,
  RenderTextPluginConfigurator,
  defaultAllowedTagNames,
  MessageSimple,
} from 'stream-chat-react';
import { ChatCustomPinIndicator } from '../ChatCustomPinIndicator';
import { anchorComponent } from '../CustomAnchorComponent.web';
import { Previews } from '../CustomMessageText/Previews';
import { MessageParentThreadsLink } from '../MessageParentThreadsLink/index.web';
import ChatCustomEmojiPickerWeb from '~/components/ChatCustomEmojiPicker';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useAvatars } from '~/hooks';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';
import type { StreamChatGenerics } from '~/types';
import type { UserResponse } from 'stream-chat';
import type { DefaultStreamChatGenerics, MessageContextValue } from 'stream-chat-react';
export interface GptChunkEvent {
  chunk: string;
  message_id: string;
}

type Props = Partial<MessageContextValue<DefaultStreamChatGenerics>> & {
  isThreadParent?: boolean;
};

export const ChatCustomMessage = ({ isThreadParent = false }: Props) => {
  const { isMyMessage, message, threadList } = useMessageContext<StreamChatGenerics>();
  const { readBy } = useMessageContext<StreamChatGenerics>();
  const settings = useAppSelector(settingsSelector);
  const { channel } = useChannelStateContext();
  const { avatarsByChatUserId } = useAvatars();
  const isGptStreamed = !!message.is_gpt_streamed;

  const [readByMessage, setReadByMessage] = useState<UserResponse<StreamChatGenerics>[] | undefined>([]);
  const [text, setText] = useState(isGptStreamed ? '' : message.text || '');

  useEffect(() => {
    if (readBy) {
      setReadByMessage(readBy.filter((item) => item.id !== message?.user?.id));
    }
  }, [readBy]);

  useEffect(() => {
    if (!channel || !isGptStreamed) return;
    // @ts-expect-error TS(2345): Argument of type '"gpt_chunk"' is not assignable t... Remove this comment to see the full error message
    const subscription = channel.on('gpt_chunk', (event: GptChunkEvent) => {
      if (event.message_id !== message.id) return;
      setText((prevText) => prevText + event.chunk);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [channel, isGptStreamed, message.id]);

  useEffect(() => {
    if (isGptStreamed) return;
    setStreamedText(message.text || '');
  }, [isGptStreamed, message.text]);

  const pValue = useRef(isGptStreamed ? 0 : text.length);
  const [streamedText, setStreamedText] = useState(isGptStreamed ? 'ちょっと待ってください...' : text);

  useEffect(() => {
    const q = text.length;
    if (pValue.current >= q) return;

    const interval = setInterval(() => {
      const p = pValue.current;
      const batch = text.substring(p, p + 3);
      pValue.current += batch.length;
      setStreamedText((prevText) => prevText.replace(/^ちょっと待ってください\.\.\./, '') + batch);
      if (p > q) {
        clearInterval(interval);
      }
    }, 33);

    return () => {
      clearInterval(interval);
    };
  }, [text]);

  const getRehypePlugins: RenderTextPluginConfigurator = (plugins) => {
    return [remarkRehype, rehypeHighlight, ...plugins];
  };

  const getRemarkPlugins: RenderTextPluginConfigurator = (plugins) => {
    return [...plugins];
  };

  const extractRubyElements = useCallback((inputString: string) => {
    const rubyRegex = /<ruby>([^<]*)《([^<]*)》<\/ruby>/g;
    let match;
    const resultArray = [];

    while ((match = rubyRegex.exec(inputString)) !== null) {
      const key = match[1];
      const value = `<ruby>${match[1]}<rt>${match[2]}</rt></ruby>`;
      resultArray.push({ [key]: value });
    }

    return resultArray;
  }, []);

  const replaceWithRubyElements = useCallback(
    (inputString: string) => {
      const rubyElements = extractRubyElements(message?.ruby ?? '');

      const { result } = rubyElements.reduce(
        (prev, element) => {
          const { lastPos: prevLastPos, result: prevResult } = prev;
          const key = Object.keys(element)[0];
          const value = element[key];
          const lastPos = prevResult.indexOf(key, prevLastPos);
          if (lastPos === -1) return prev;
          const result = prevResult.slice(0, lastPos) + value + prevResult.slice(lastPos + key.length);
          return { lastPos: lastPos + value.length, result };
        },
        { lastPos: 0, result: inputString }
      );

      return result;
    },
    [message]
  );

  const renderTextMessage = useCallback((text?: string) => {
    return (
      <>
        {renderText(text, undefined, {
          customMarkDownRenderers: {
            a: (props) => {
              const parsedUrl = props.href?.match(/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/)?.[0];
              const domain = parsedUrl?.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1];
              const isInternalLink = domain === U_FEED_URL_BASE.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)?.[1];

              return anchorComponent({
                ...props,
                href: props.href?.replace(/%5C$/, '') ?? '',
                target: isInternalLink ? '_self' : '_blank',
                children: typeof props.children === 'string' ? props.children.replace(/\\$/, '') : props.children,
              });
            },
          },
          getRehypePlugins,
          getRemarkPlugins,
          allowedTagNames: [...defaultAllowedTagNames, 'span'],
        })}
      </>
    );
  }, []);

  const customRenderText: MessageContextValue['renderText'] = (text) => {
    return (
      <>
        {isGptStreamed ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="41" height="8" viewBox="0 0 41 8" fill="none">
            <circle cx="4.5" cy="4" r="4" fill="#FBDDBA" />
            <circle cx="20.5" cy="4" r="4" fill="#FFA153" />
            <circle cx="36.5" cy="4" r="4" fill="#FF811A" />
          </svg>
        ) : (
          <>
            <Previews message={message} />
            {settings.enableKanasapo && message.ruby ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    replaceWithRubyElements(ReactDOMServer.renderToString(renderTextMessage(text) as any))
                  ),
                }}
              />
            ) : (
              renderTextMessage(text)
            )}
            {isThreadParent && <span id={`parent-action-${message.id}`} />}
          </>
        )}
      </>
    );
  };

  const messageUpdatedAt = useMemo(() => {
    const { message_text_updated_at, updated_at, created_at } = message;
    if (message_text_updated_at) {
      return message_text_updated_at;
    }

    if (!(updated_at instanceof Date && created_at instanceof Date)) {
      return undefined;
    }

    const UPDATED_AT_TIMESTAMP_DELTA = 3000; // 3000 ms
    const isMessageEdited = updated_at.getTime() > created_at.getTime() + UPDATED_AT_TIMESTAMP_DELTA;
    if (isMessageEdited) {
      return updated_at.toISOString();
    }

    return undefined;
  }, [message]);

  return (
    <Box style={message.pinned && { backgroundColor: '#fff8e5' }}>
      {!threadList && <MessageParentThreadsLink />}
      {message.pinned && <ChatCustomPinIndicator />}
      <MessageSimple
        renderText={customRenderText}
        message={{
          ...message,
          message_text_updated_at: messageUpdatedAt,
          user: {
            ...message.user,
            id: message.user?.id || '',
            name: message.user?.id === 'u-feed-chat-administretor' ? 'チャムリー' : message?.user?.name || '',
          },
          text: streamedText,
        }}
      />
      <HStack gap="2xs" justifyContent="end" alignItems="center">
        {readByMessage && readByMessage.length > 10 && (
          <Text
            py="none"
            px="2xs"
            fontSize="2xs"
            fontWeight="regular"
            color="onSurfaceBright"
            backgroundColor="surface"
            lineHeight={gluestackUIConfig.tokens.space[4]}
            borderRadius={gluestackUIConfig.tokens.borderRadius.full}
          >
            {readByMessage.length - 10}
          </Text>
        )}
        {readByMessage?.map((user, index) => {
          if (index > 9) return;
          return (
            <Tooltip key={index} label={user.name} openDelay={100} bg={gluestackUIConfig.tokens.colors.onPrimary}>
              {avatarsByChatUserId[user.id] ? (
                <Avatar
                  height={gluestackUIConfig.tokens.space['2xs']}
                  width={gluestackUIConfig.tokens.space['2xs']}
                  borderColor="outlineVariant"
                  backgroundColor="onSurfaceBrightest"
                  source={{ uri: avatarsByChatUserId[user.id].avatar }}
                />
              ) : (
                <Box
                  width="md"
                  height="md"
                  borderColor="outlineVariant"
                  backgroundColor="onSurfaceBrightest"
                  borderRadius="full"
                />
              )}
            </Tooltip>
          );
        })}
      </HStack>
      <ChatCustomEmojiPickerWeb />
    </Box>
  );
};
