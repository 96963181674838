import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { useActor } from '@xstate/react';
import { VStack, Text, Button, Center, Alert } from 'native-base';
import React, { useCallback, useState } from 'react';
import { fromPromise } from 'xstate';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { U_MOTION_API_REQUEST_INTERVAL } from '~/constants';
import { bleIdApi } from '~/packages/u-motion-api/api/bleIdCowApi';
import { useUmotionCow } from '~/packages/u-motion-api/hooks/useUmotionCow';
import { useAppDispatch } from '~/store';
import { AlertDupFound } from './components/AlertDupFound';
import { RegisterToUmotion } from './components/RegisterToUmotion';
import { umotionApplySensorMachine, ApplySensorData } from './UmotionApplySensorScreen.machine';

type UmotionApplySensorScreenParams = {
  UmotionApplySensorScreen: {
    data: ApplySensorData[];
  };
};

type RootStackParamList = {
  UmotionApplySensorScreen: UmotionApplySensorScreenParams['UmotionApplySensorScreen'];
};

export const UmotionApplySensorScreen: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<RootStackParamList, 'UmotionApplySensorScreen'>>();
  const { data } = route.params;
  const { searchCowBySensorId, searchCowByUid } = useUmotionCow();
  const dispatch = useAppDispatch();
  const [appliedCowIds, setAppliedCowIds] = useState<string[]>([]);
  const [state, send] = useActor(
    umotionApplySensorMachine.provide({
      actors: {
        checkDups: fromPromise(async ({ input }) => {
          if (input?.data?.length === 0) {
            return;
          }

          const promises = input?.data?.map((data) => searchCowBySensorId(data?.sensor_id));

          const results = await Promise.all(promises).catch((error) => {
            console.error('UmotionApplySensorScreen checkDups error', error);
          });

          if (results?.length === 0) {
            return;
          }

          console.log('checkDups results', results);

          const dups = results
            .filter((result) => result?.data?.length > 0)
            .map((result) => ({
              cowUid: result?.data?.[0]?.cowUid,
              bleId: result?.data?.[0]?.bleId,
            }));

          return dups;
        }),
        checkCowExists: fromPromise(async ({ input }) => {
          if (input?.data?.length === 0) {
            return;
          }

          const promises = input?.data?.map(
            (data, index) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve(searchCowByUid(data?.uid));
                }, U_MOTION_API_REQUEST_INTERVAL * index);
              })
          );

          const results = await Promise.all(promises).catch((error) => {
            console.error('UmotionApplySensorScreen checkCowExists error', error);
          });

          if (results?.length === 0) {
            return;
          }
          const existingCows = results
            ?.filter((result) => result?.data?.length > 0)
            .reduce((acc, cur) => ({ ...acc, [cur?.data?.[0]?.cowUid]: cur?.data?.[0] }), {});

          return existingCows;
        }),
      },
    }),
    {
      input: { data },
    }
  );

  const handleProcessConfirm = useCallback(() => {
    send({ type: 'CONFIRM_PROCESS' });
  }, [send]);

  const handleConfirmRegister = useCallback(async () => {
    console.log('handleConfirmRegister', state.context.filteredData);

    const promises = state.context.filteredData.map((data) => {
      console.log('state.context.existingCows[data.uid]', state.context.existingCows[data.uid]);

      const cowId = state.context.existingCows[data.uid]?.cowId
        ? Number(state.context.existingCows[data.uid]?.cowId)
        : undefined;
      const bleId = data.sensor_id?.toString().padStart(12, '0');

      const startDate = new Date(data?.apply_date).getTime();

      if (cowId === undefined || bleId === undefined || isNaN(startDate)) {
        return Promise.resolve(null);
      }

      return dispatch(
        bleIdApi.endpoints.equipBleId.initiate({
          cowId: Number(state.context.existingCows[data.uid].cowId),
          bleId: data.sensor_id.toString(),
          startDate,
        })
      ).unwrap();
    });

    const results = await Promise.all(promises).catch((error) => {
      console.error('apply sensorerror', error);
      send({ type: 'REGISTER_ERROR' });
    });

    if (results?.length) {
      console.log(
        'state.context.filteredData.map((cow) => cow.cowId)',
        state.context.filteredData.map((cow) => cow)
      );
      setAppliedCowIds(state.context.filteredData.map((cow) => cow.cowId));
      send({ type: 'REGISTER_SUCCESS' });
    } else {
      send({ type: 'REGISTER_ERROR' });
    }
  }, [send, state.context.filteredData]);

  const handleCancel = useCallback(() => {
    send({ type: 'CANCEL' });
    navigation.goBack();
  }, [navigation, send]);

  return (
    <ScreenWidthModal>
      {state.matches('checkingDups') && (
        <Center flex={1}>
          <VStack space={4}>
            <Text bold>紐づけ済みセンサーのチェック中...</Text>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('checkDupsError') && (
        <Center flex={1}>
          <VStack space={4}>
            <Text bold>紐づけ済みセンサーのチェック中に、エラーが発生しました</Text>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('dupsChecked') && state.context?.dupSensorCows?.length > 0 && (
        <AlertDupFound
          dupSensorCows={state.context.dupSensorCows}
          onConfirm={handleProcessConfirm}
          onCancel={handleCancel}
        />
      )}
      {state.matches('checkingCowExists') && (
        <Center flex={1}>
          <VStack space={4}>
            <Text bold>センサー紐づけ対象牛の登録状況をチェック中...</Text>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('checkCowExistsError') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="error" colorScheme="error">
              <Alert.Icon />
              <Text>対象牛の登録をチェック中にエラーが発生しました</Text>
            </Alert>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('cowChecked') && (
        <RegisterToUmotion
          data={state.context.filteredData}
          onConfirm={handleConfirmRegister}
          onCancel={handleCancel}
        />
      )}
      {state.matches('cowCheckedNoCows') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="error" colorScheme="error">
              <Alert.Icon />
              <Text>センサー紐づけ可能な個体がみつかりませんでした。</Text>
              <Text>指定された牛番号の個体データが登録されているか確認してください。</Text>
            </Alert>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}

      {state.matches('registerError') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="error" colorScheme="error">
              <Alert.Icon />
              <Text bold>センサー紐づけに失敗しました</Text>
            </Alert>
            <Button variant="ghost" onPress={handleCancel}>
              キャンセル
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('registered') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="success" colorScheme="success">
              <Alert.Icon />
              <Text bold>センサーが正常に登録されました。</Text>
            </Alert>
            <Button variant="ghost" onPress={handleCancel}>
              閉じる
            </Button>
          </VStack>
        </Center>
      )}
    </ScreenWidthModal>
  );
};
