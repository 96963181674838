import { HStack, VStack, Text, Pressable } from '@gluestack-ui/themed-native-base';
import { NotificationsScreen } from '../NotificationsScreen';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import CloseIcons from '~/icons/CloseIcons';
import DragIcons from '~/icons/DragIcons';

type Props = {
  width: number;
};

export const NotificationColumn = ({ width: columnWidth }: Props) => {
  return (
    <VStack width={columnWidth} height="100%" flexGrow={0} backgroundColor="surfaceBrightest">
      <HStack
        px="md"
        py="xl"
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
      >
        <HStack>
          <strong>
            <Pressable>
              <DragIcons />
            </Pressable>
          </strong>
          <Text fontSize="xl" fontWeight="bold" color="onSurface">
            通知
          </Text>
        </HStack>
        <CloseIcons />
      </HStack>
      <NotificationsScreen />
    </VStack>
  );
};
