import { VStack, Divider } from '@gluestack-ui/themed-native-base';
import React from 'react';

export const ItemList: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <VStack space="sm" divider={<Divider backgroundColor="#d4d4d4" />}>
      {children}
    </VStack>
  );
};
