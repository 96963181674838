import { cowApi } from '~/packages/u-motion-api/api/cowApi';
import { store } from '~/store';

export const useUmotionCow = () => {
  const { dispatch } = store;

  const searchCowByUid = (uid: string) => {
    try {
      return dispatch(
        cowApi.endpoints.fetchCowSearch.initiate({
          searchType: 'cow_uid',
          searchId: uid,
        })
      );
    } catch (error) {
      console.error('searchCowByUid error', error);
    }
  };
  const searchCowBySensorId = (sensorId: string) => {
    try {
      return dispatch(
        cowApi.endpoints.fetchCowSearch.initiate({
          searchType: 'ble_id',
          searchId: sensorId,
        })
      );
    } catch (error) {
      console.error('searchCowBySensorId error', error);
    }
  };

  return {
    searchCowByUid,
    searchCowBySensorId,
  };
};
