import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createContext, FC, useMemo } from 'react';
import { ChannelColumnWithId } from '~/screens/DashboardScreen/ChannelColumn';

interface SortableItemProps {
  id: string;
  COLUMN_WIDTH: number;
  channelId: string;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export const SortableItem: FC<SortableItemProps> = ({ id, channelId, COLUMN_WIDTH }) => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const styles = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <SortableItemContext.Provider value={context}>
      <div ref={setNodeRef} style={styles}>
        <ChannelColumnWithId width={COLUMN_WIDTH} channelId={channelId} />
      </div>
    </SortableItemContext.Provider>
  );
};
