import { Box } from '@gluestack-ui/themed-native-base';
import { useState, useCallback, memo } from 'react';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { BookmarkList } from './BookmarkList';
import type { StreamMessage } from 'stream-chat-react';

type Selected = { type: 'my' | 'dm'; channelId?: string; messageId?: string };

const SideMenu: React.FC<{ setSelected: React.Dispatch<React.SetStateAction<Selected>> }> = memo(({ setSelected }) => {
  const handleMessagePress = useCallback(
    (message?: StreamMessage) => {
      setSelected({
        type: message?.channel?.isDM ? 'dm' : 'my',
        channelId: message?.channel?.id,
        messageId: message?.id,
      });
    },
    [setSelected]
  );

  return (
    <Box width={450} height="100%" borderColor="surface" borderRightWidth={gluestackUIConfig.tokens.borderWidths.thick}>
      <BookmarkList handleMessagePress={handleMessagePress} />
    </Box>
  );
});

export const ChatBookmarkListScreen: React.FC = () => {
  const [selected, setSelected] = useState<Selected>({
    type: 'my',
  });
  return (
    <>
      {selected.channelId ? (
        <ChatChannelListWeb
          type={selected?.type}
          channelId={selected?.channelId}
          messageId={selected?.messageId}
          sideMenu={<SideMenu setSelected={setSelected} />}
          hideChannelList
        />
      ) : (
        <SideMenu setSelected={setSelected} />
      )}
    </>
  );
};
