import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Box, HStack, VStack, Text, Pressable } from '@gluestack-ui/themed-native-base';
import { useState, useCallback, useRef } from 'react';
import { FlatList } from 'react-native';
import ChannelInfoMediaContent from './Content';
import type { FC } from 'react';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

const PER_PAGE = 24;

export type Media = {
  id: string;
  url: string;
  type: 'image' | 'video';
};

const ChannelInfoMedia: FC<{
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
  onClose: () => void;
}> = ({ appChannel, onClose }) => {
  const [isMore, setIsMore] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<Media[]>([]);

  const isFetchingRef = useRef(false);
  const pageRef = useRef(1);

  const getMedia = useCallback(
    async (page = 1) => {
      if (isFetchingRef.current || !isMore) return;

      isFetchingRef.current = true;
      const result = await appChannel?.search(
        {
          'attachments.type': {
            $in: ['image', 'video'],
          },
        },
        {
          limit: PER_PAGE,
          offset: (page - 1) * PER_PAGE,
          sort: [{ created_at: -1 }],
        }
      );

      const placeholderLength = (result?.results.length ?? 0) % 3 ? 3 - ((result?.results.length ?? 0) % 3) : 0;

      setIsMore(!!result?.next);
      setMediaList((prev) => [
        ...prev,
        ...(result?.results.map((message) => ({
          id: message.message.id,
          url: message.message.attachments?.[0].image_url || message.message.attachments?.[0].asset_url || '',
          type: (message.message.attachments?.[0].type || 'image') as 'image' | 'video',
        })) || []),
        ...Array(placeholderLength)
          .fill(null)
          .map((_, index) => ({
            id: index.toString(),
            url: '',
            type: 'image' as 'image' | 'video',
          })),
      ]);
      pageRef.current = page + 1;
      isFetchingRef.current = false;
    },
    [appChannel]
  );

  useDidMount(() => {
    getMedia();
  });

  return (
    <VStack width="full" maxWidth="300px" borderLeftWidth="medium" borderColor="outlineVariant">
      <HStack
        p="md"
        justifyContent="flex-start"
        alignItems="center"
        gap="2xs"
        borderBottomWidth="medium"
        borderColor="outlineVariant"
      >
        <Pressable onPress={onClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons">
              <path
                id="arrow_back_ios"
                d="M3.54922 12.0001L10.8992 19.3501C11.1492 19.6001 11.2701 19.8918 11.2617 20.2251C11.2534 20.5584 11.1242 20.8501 10.8742 21.1001C10.6242 21.3501 10.3326 21.4751 9.99922 21.4751C9.66589 21.4751 9.37422 21.3501 9.12422 21.1001L1.42422 13.4251C1.22422 13.2251 1.07422 13.0001 0.974219 12.7501C0.874219 12.5001 0.824219 12.2501 0.824219 12.0001C0.824219 11.7501 0.874219 11.5001 0.974219 11.2501C1.07422 11.0001 1.22422 10.7751 1.42422 10.5751L9.12422 2.87511C9.37422 2.62511 9.67005 2.50428 10.0117 2.51261C10.3534 2.52094 10.6492 2.65011 10.8992 2.90011C11.1492 3.15011 11.2742 3.44178 11.2742 3.77511C11.2742 4.10844 11.1492 4.40011 10.8992 4.65011L3.54922 12.0001Z"
                fill="#737373"
              />
            </g>
          </svg>
        </Pressable>
        <Text color="onSurface" fontSize="xl" fontWeight="bold">
          写真・動画
        </Text>
      </HStack>
      <FlatList
        data={mediaList}
        keyExtractor={(item: Media) => item.id}
        numColumns={3}
        renderItem={({ item }: { item: Media }) =>
          item.url ? <ChannelInfoMediaContent item={item} appChannel={appChannel} /> : <Box width="full" flex={1} />
        }
      />
    </VStack>
  );
};

export default ChannelInfoMedia;
