import React from 'react';

const ArrowRightIcons = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="keyboard_arrow_right"
          d="M10.8083 9.70443L7.55827 6.45443C7.40549 6.30165 7.3291 6.1072 7.3291 5.87109C7.3291 5.63498 7.40549 5.44054 7.55827 5.28776C7.71105 5.13498 7.90549 5.05859 8.1416 5.05859C8.37771 5.05859 8.57216 5.13498 8.72494 5.28776L12.5583 9.12109C12.6416 9.20443 12.7006 9.2947 12.7354 9.39193C12.7701 9.48915 12.7874 9.59332 12.7874 9.70443C12.7874 9.81554 12.7701 9.9197 12.7354 10.0169C12.7006 10.1141 12.6416 10.2044 12.5583 10.2878L8.72494 14.1211C8.57216 14.2739 8.37771 14.3503 8.1416 14.3503C7.90549 14.3503 7.71105 14.2739 7.55827 14.1211C7.40549 13.9683 7.3291 13.7739 7.3291 13.5378C7.3291 13.3016 7.40549 13.1072 7.55827 12.9544L10.8083 9.70443Z"
          fill="#737373"
        />
      </g>
    </svg>
  );
};

export default ArrowRightIcons;
