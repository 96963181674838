import { setup } from 'xstate';
import { Cow } from '~/packages/u-motion-api/api/cowAutoGeneratedApi';

export const umotionCowRegisterScreenMachine = setup({
  types: {
    context: {} as {
      cow: Cow;
    },
    events: {} as { type: 'CANCEL' } | { type: 'REGISTER_SUCCESS' } | { type: 'REGISTER_ERROR' },
    input: {} as { cow: Cow },
  },
}).createMachine({
  id: 'umotionCowRegisterScreenMachine',
  initial: 'registeringCow',
  context: ({ input }) => ({
    cow: input.cow,
    dups: [],
    existingCows: [],
  }),
  states: {
    registeringCow: {
      on: {
        REGISTER_SUCCESS: 'registered',
        REGISTER_ERROR: 'registerError',
      },
    },
    registerError: {
      type: 'final',
    },
    registered: {
      type: 'final',
    },
    exit: {
      type: 'final',
    },
  },
});
