import { Divider, VStack, Text } from '@gluestack-ui/themed-native-base';
import { Platform, Linking } from 'react-native';
import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { useAuth } from '~/hooks/useAuth';
import { useHelpUrl } from '~/hooks/useHelpUrl';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';
import { MenuItem } from './MenuItem';
import { MenuSection } from './MenuSection';

export const MenuScreen = () => {
  const { handleLogout } = useAuth();
  const currentUser = useGetCurrentUserQuery({});
  const settings = useAppSelector(settingsSelector);
  const { helpUrl } = useHelpUrl();

  return (
    <VStack padding="2xs" paddingTop="md" space="xs" backgroundColor="surfaceBrightest" flex={1}>
      <MenuSection>
        <MenuItem icon="list-outline" title="牧場・グループ" navigateTo="FarmList" />
      </MenuSection>
      <Divider />
      {currentUser.data?.roles?.includes('account_admin') ||
      currentUser.data?.roles?.includes('group_admin') ||
      currentUser.data?.roles?.includes('member_admin') ? (
        <MenuSection>
          <Text bold color="onSurfaceBright">
            管理者
          </Text>
          {currentUser.data?.roles?.includes('account_admin') && (
            <MenuItem title="企業情報" navigateTo="AdminAccountEdit" />
          )}
          {currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'group_admin') && (
            <MenuItem title="牧場・グループ" navigateTo="AdminFarmList" />
          )}
          {currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'member_admin') && (
            <MenuItem title="企業ユーザー管理" navigateTo="AdminUserList" />
          )}
        </MenuSection>
      ) : null}
      <Divider marginTop={2} />
      <VStack paddingY={2} divider={<Divider />} space={2}>
        <MenuSection>
          <MenuItem icon="settings-outline" title="ユーザー設定" navigateTo="SettingsTop" testID="SettingsTop" />
          <MenuItem icon="bulb-outline" title="Chumlyについて" navigateTo="AboutThisApp" />
          <MenuItem
            icon="help-circle-outline"
            title="使い方"
            navigateTo={Platform.OS === 'web' ? undefined : 'Help'}
            onPress={
              Platform.OS === 'web'
                ? () => {
                    Linking.openURL(helpUrl).catch((err) => console.error('URLを開けませんでした。', err));
                  }
                : undefined
            }
          />
          <MenuItem
            title="ログアウト"
            icon="log-out-outline"
            onPress={() => {
              handleLogout();
            }}
            testID="LogoutButton"
          />
        </MenuSection>
      </VStack>
    </VStack>
  );
};
