import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Text, Icon, Box, Image, Pressable, VStack } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation, useNavigationState } from '@react-navigation/native';
import React from 'react';
import { Platform, TouchableOpacity, useWindowDimensions } from 'react-native';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

import { useNotificationBadge } from '~/hooks/useNotificationBadge';

export const AppHeader: React.FC = () => {
  const navigation = useNavigation();
  const rootName = useNavigationState((state) => state.routeNames[state.index]);
  const linkTo = useLinkTo();

  const { shouldShowNotificationBadge } = useNotificationBadge();
  const { height } = useWindowDimensions();

  const HeaderItem = ({
    iconName,
    label,
    navigateTo,
    path,
    badge = false,
  }: {
    iconName: string;
    label: string;
    navigateTo: string;
    path: string;
    badge?: boolean;
  }) => {
    const isSelected = rootName === navigateTo;
    return (
      <VStack textAlign="center" w="full" space="md">
        <Pressable
          // @ts-expect-error TS(2769): No overload matches this call.
          onPress={() => (Platform.OS === 'web' ? linkTo(path) : navigation.navigate(navigateTo))}
          _hover={{
            backgroundColor: gluestackUIConfig.tokens.colors.mono100,
          }}
        >
          <Icon as={MaterialIcons} name={iconName} size="xl" color="onSurface" />
          {badge && (
            <Box
              borderRadius={30}
              height={3}
              width={3}
              backgroundColor="#FF4949"
              top={-10}
              left={-12}
              margin={0}
              padding={0}
            />
          )}
          <Text fontSize="2xs" bold color="onSurface" fontWeight="bold" textAlign="center">
            {label}
          </Text>
        </Pressable>
      </VStack>
    );
  };

  return (
    <VStack backgroundColor="surfaceBrightest" w="76px" h={height} px="none" py="xl">
      <VStack alignItems="center" backgroundColor="surfaceBrightest" space="md">
        <HStack alignItems="center" mb="xs">
          {/* @ts-expect-error TS(2786): 'TouchableOpacity' cannot be used as a JSX compone... Remove this comment to see the full error message */}
          <TouchableOpacity onPress={() => navigation.navigate('Chat')}>
            <Image source={require('../../assets/logo/app_logo_chumly.png')} alt="Chumly" width={68} height={36.559} />
          </TouchableOpacity>
        </HStack>
        <HeaderItem iconName="chat" navigateTo="Chat" label="チャット" path="/chat" />
        <HeaderItem iconName="view-column" navigateTo="Chat" label="ダッシュボード" path="/dashboard" />
        <HeaderItem
          iconName="bookmark-border"
          navigateTo="BookmarkNavigator"
          label="コンシェルジュ"
          path="/bookmarks"
        />
        <HeaderItem
          iconName="notifications-none"
          navigateTo="NotificationsNavigator"
          label="お知らせ"
          path="/notifications"
          badge={shouldShowNotificationBadge}
        />
        <HeaderItem iconName="bookmark-border" navigateTo="BookmarkNavigator" label="ブックマーク" path="/bookmarks" />
        <HeaderItem
          iconName="settings"
          navigateTo="NotificationsNavigator"
          label="設定"
          path="/notifications"
          badge={shouldShowNotificationBadge}
        />
      </VStack>
    </VStack>
  );
};
