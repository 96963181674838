import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Pressable, Text, View, VStack } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { useGetCurrentUserSettingQuery } from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import type { StackNavigationProp } from '@react-navigation/stack';

export const BackgroundColorSettingScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { appChannel, chatUserId } = useStreamChatContext();
  const colors = ['#FAD1D1', '#E7CCCC', '#EDE8DC', '#A5B68D', '#C1CFA1'];

  const userSetting = useGetCurrentUserSettingQuery();
  const userChatBackground = userSetting.data?.details?.channel_background ?? {};
  const chatColor = userChatBackground[appChannel?.id as string] || '#FFF';
  navigation?.setOptions({
    title: '',
    headerLeft: () => (
      <Text fontSize="md" bold lineHeight="3xs" color="onSurface">
        プレビュー確認
      </Text>
    ),
    headerRight: () => (
      <Icon
        onPress={() => {
          navigation.goBack();
        }}
        as={Ionicons}
        name="close"
        size="lg"
        color="onSurfaceBright"
      />
    ),
  });

  return (
    <VStack backgroundColor="white" flex="1">
      <HStack padding="md" space="md">
        {colors.includes(chatColor || '') && (
          <View backgroundColor={chatColor} height={70} width={70} borderRadius="md" />
        )}
        <Pressable
          height={70}
          width={70}
          justifyContent="center"
          alignItems="center"
          backgroundColor="surface"
          borderRadius="md"
          onPress={() => navigation.navigate('BackgroundImageSetting')}
        >
          <Icon as={<Ionicons name="images-sharp" />} color="onSurfaceBright" width={8} height={8} fontSize={32} />
        </Pressable>
      </HStack>

      <View flexDirection="row" flexWrap="wrap" gap="0.5" justifyContent="start">
        {colors.map((colorI, index) => (
          <Pressable
            key={colorI}
            width="33%"
            height={170}
            backgroundColor={colorI}
            borderWidth={chatColor === colorI ? gluestackUIConfig.tokens.borderWidths.thick : 0}
            borderColor="primary"
            onPress={() => {
              navigation.navigate('BackgroundChannelPreview', { colorI });
            }}
          />
        ))}
      </View>
    </VStack>
  );
};
