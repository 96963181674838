import { Ionicons } from '@expo/vector-icons';
import { Icon, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { FC } from 'react';
import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

export const SettingsOtherScreen: FC = () => {
  const currentUser = useGetCurrentUserQuery({});
  const navigation = useNavigation();

  const shouldShowAdmin =
    currentUser.data?.roles?.includes('account_admin') ||
    currentUser.data?.roles?.includes('group_admin') ||
    currentUser.data?.roles?.includes('member_admin');

  return (
    <ScreenWidthModal title="その他">
      <BaseScreenBuilder title="その他">
        {shouldShowAdmin && (
          <VStack paddingVertical="xs" borderBottomWidth="medium" borderColor="outlineVariant">
            <Text bold color="onSurfaceBright">
              管理者
            </Text>
            <ListItem
              title="企業情報"
              right={
                <Icon
                  as={Ionicons}
                  name="chevron-forward-outline"
                  alignSelf="center"
                  color="onSurfaceBright"
                  size="sm"
                />
              }
              style={{ paddingVertical: gluestackUIConfig.tokens.space.xs }}
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onPress={() => navigation.navigate('AdminAccountEdit')}
            />
            <ListItem
              title="牧場・グループ"
              right={
                <Icon
                  as={Ionicons}
                  name="chevron-forward-outline"
                  alignSelf="center"
                  color="onSurfaceBright"
                  size="sm"
                  bold
                />
              }
              style={{ paddingVertical: gluestackUIConfig.tokens.space.xs }}
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onPress={() => navigation.navigate('AdminFarmList')}
            />
            <ListItem
              title="企業ユーザー管理"
              right={
                <Icon
                  as={Ionicons}
                  name="chevron-forward-outline"
                  alignSelf="center"
                  color="onSurfaceBright"
                  size="sm"
                  bold
                />
              }
              style={{ paddingVertical: gluestackUIConfig.tokens.space.xs }}
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              onPress={() => navigation.navigate('AdminUserList')}
            />
          </VStack>
        )}
        <ListItem
          title="チャムリーについて"
          left={
            <Icon
              as={Ionicons}
              name="information-circle-outline"
              alignSelf="center"
              color="onSurfaceBright"
              size="md"
              marginRight="2xs"
            />
          }
          right={
            <Icon
              as={Ionicons}
              name="chevron-forward-outline"
              alignSelf="center"
              color="onSurfaceBright"
              size="sm"
              bold
            />
          }
          style={{ paddingVertical: gluestackUIConfig.tokens.space.xs }}
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          onPress={() => navigation.navigate('AboutThisApp')}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
