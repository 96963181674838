import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const HomeIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.33398 15V7.50004C3.33398 7.23615 3.39301 6.98615 3.51107 6.75004C3.62912 6.51393 3.79232 6.31949 4.00065 6.16671L9.00065 2.41671C9.29232 2.19449 9.62565 2.08337 10.0007 2.08337C10.3757 2.08337 10.709 2.19449 11.0007 2.41671L16.0007 6.16671C16.209 6.31949 16.3722 6.51393 16.4902 6.75004C16.6083 6.98615 16.6673 7.23615 16.6673 7.50004V15C16.6673 15.4584 16.5041 15.8507 16.1777 16.1771C15.8513 16.5035 15.459 16.6667 15.0007 16.6667H12.5007C12.2645 16.6667 12.0666 16.5868 11.9069 16.4271C11.7472 16.2674 11.6673 16.0695 11.6673 15.8334V11.6667C11.6673 11.4306 11.5875 11.2327 11.4277 11.073C11.268 10.9132 11.0701 10.8334 10.834 10.8334H9.16732C8.93121 10.8334 8.73329 10.9132 8.57357 11.073C8.41385 11.2327 8.33398 11.4306 8.33398 11.6667V15.8334C8.33398 16.0695 8.25412 16.2674 8.0944 16.4271C7.93468 16.5868 7.73676 16.6667 7.50065 16.6667H5.00065C4.54232 16.6667 4.14996 16.5035 3.82357 16.1771C3.49718 15.8507 3.33398 15.4584 3.33398 15Z"
        fill={gluestackUIConfig.tokens.colors.mono700}
      />
    </svg>
  );
};

export default HomeIcons;
