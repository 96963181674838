import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { HStack, Text } from '@gluestack-ui/themed-native-base';
import * as Clipboard from 'expo-clipboard';
import React, { useRef, useState } from 'react';
import { CustomMessageActionsListProps, useChannelActionContext, useMessageContext } from 'stream-chat-react';

import {
  type Bookmark,
  useGetCurrentUserBookmarksQuery,
  usePostCurrentUserBookmarksMutation,
  useDeleteCurrentUserBookmarksByIdMutation,
} from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useCustomToast } from '~/hooks';
import BookmarkIcons from '~/icons/BookmarkIcons';
import CopyIcons from '~/icons/CopyIcons';
import DeleteIcons from '~/icons/DeleteIcons';
import EditIcons from '~/icons/EditIcons';
import LinkIcons from '~/icons/LinkIcons';
import MessageUnReadIcons from '~/icons/MessageUnReadIcons';
import PinIcons from '~/icons/PinIcons';
import ReplyIcons from '~/icons/ReplyIcons';
import { StreamChatGenerics } from '~/types';

type Props = CustomMessageActionsListProps & {
  isDM?: boolean;
};

type HoverState = {
  [key: string]: boolean; // Allows any string key with a boolean value
};

export const CustomMessageActionsListWeb: React.FC<Props> = ({ customMessageActions, message, isDM }) => {
  const [isHovered, setIsHovered] = useState<HoverState>({});
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [post] = usePostCurrentUserBookmarksMutation();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef<Bookmark[] | null>(null);
  const bookmarksList: Bookmark[] = bookmarksRef.current ? bookmarksRef.current : [];
  const bookMarkID = bookmarksList.find((b) => b.message_id === message.id)?.id;
  const { appChannel } = useStreamChatContext();
  const { handleMarkUnread, handleDelete, isMyMessage, handleEdit, handlePin } =
    useMessageContext<StreamChatGenerics>('MessageActions');
  const { setQuotedMessage } = useChannelActionContext<StreamChatGenerics>('MessageActionsBox');
  const toast = useCustomToast();

  const myMessage = isMyMessage();

  const handleMouseEnter = (id: string) => {
    setIsHovered((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: string) => {
    setIsHovered((prev) => ({ ...prev, [id]: false }));
  };

  const handleQuote = () => {
    setQuotedMessage(message);

    const elements = message.parent_id
      ? document.querySelectorAll('.str-chat__thread .str-chat__textarea__textarea')
      : document.getElementsByClassName('str-chat__textarea__textarea');
    const textarea = elements.item(0);

    if (textarea instanceof HTMLTextAreaElement) {
      textarea.focus();
    }
  };

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data, message],
    true
  );

  const updateBookMark = () => {
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    } else {
      post({
        body: {
          user_bookmark: {
            message_id: message.id,
            bookmark_type: 'chat-message',
          },
        },
      });
    }
  };

  const copyLink = async () => {
    await Clipboard.setStringAsync(`${U_FEED_URL_BASE}/${isDM ? 'dm' : 'chat'}/${appChannel?.id}/${message.id}`);
  };

  const copyText = async () => {
    await Clipboard.setStringAsync(`${message.text}`);
    toast.show('メッセージをコピーしました');
  };

  const buttonClassName = 'str-chat__message-actions-list-item str-chat__message-actions-list-item-button';

  return (
    <div className="custom-menu-action">
      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Copy')}
        onMouseLeave={() => handleMouseLeave('Copy')}
        onClick={copyText}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Copy'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <CopyIcons />
          <Text fontSize="md" color="onSurface">
            メッセージのコピー
          </Text>
        </HStack>
      </button>
      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Reply')}
        onMouseLeave={() => handleMouseLeave('Reply')}
        onClick={handleQuote}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Reply'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <ReplyIcons />
          <Text fontSize="md" color="onSurface">
            返信する
          </Text>
        </HStack>
      </button>
      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Bookmark')}
        onMouseLeave={() => handleMouseLeave('Bookmark')}
        onClick={updateBookMark}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Bookmark'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <BookmarkIcons />
          <Text fontSize="md" color="onSurface">
            {bookMarkID ? 'ブックマーク解除' : 'ブックマークする'}
          </Text>
        </HStack>
      </button>
      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Link')}
        onMouseLeave={() => handleMouseLeave('Link')}
        onClick={copyLink}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Link'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <LinkIcons />
          <Text fontSize="md" color="onSurface">
            リンクをコピー
          </Text>
        </HStack>
      </button>
      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Unread')}
        onMouseLeave={() => handleMouseLeave('Unread')}
        onClick={handleMarkUnread}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Unread'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <MessageUnReadIcons />
          <Text fontSize="md" color="onSurface">
            未読に戻す
          </Text>
        </HStack>
      </button>

      <button
        aria-selected="false"
        className={buttonClassName}
        onMouseEnter={() => handleMouseEnter('Pin')}
        onMouseLeave={() => handleMouseLeave('Pin')}
        onClick={handlePin}
        role="option"
      >
        <HStack
          backgroundColor={isHovered['Pin'] ? 'surface' : 'onPrimary'}
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outline"
          p="xs"
          gap="xs"
        >
          <PinIcons />
          <Text fontSize="md" color="onSurface">
            ピン
          </Text>
        </HStack>
      </button>

      {myMessage && (
        <button
          aria-selected="false"
          className={buttonClassName}
          onMouseEnter={() => handleMouseEnter('Edit')}
          onMouseLeave={() => handleMouseLeave('Edit')}
          onClick={handleEdit}
          role="option"
        >
          <HStack
            backgroundColor={isHovered['Edit'] ? 'surface' : 'onPrimary'}
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outline"
            p="xs"
            gap="xs"
          >
            <EditIcons />
            <Text fontSize="md" color="onSurface">
              メッセージを編集
            </Text>
          </HStack>
        </button>
      )}

      {myMessage && (
        <button
          aria-selected="false"
          className={buttonClassName}
          onMouseEnter={() => handleMouseEnter('Delete')}
          onMouseLeave={() => handleMouseLeave('Delete')}
          onClick={handleDelete}
          role="option"
        >
          <HStack
            backgroundColor={isHovered['Delete'] ? 'surface' : 'onPrimary'}
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outline"
            p="xs"
            gap="xs"
          >
            <DeleteIcons />
            <Text fontSize="md" color="negative">
              削除
            </Text>
          </HStack>
        </button>
      )}
    </div>
  );
};
