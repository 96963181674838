import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelectPopover } from '~/components/builder/FormSelectPopover';
import { useFetchCowGroupIndexQuery } from '~/packages/u-motion-api/api/cowGroupApi';

export const UmotionCowGroupSelector = ({ onChange, label }: { onChange: (value: string) => void; label: string }) => {
  const cowGroupsQuery = useFetchCowGroupIndexQuery();

  const [groupsOption, setGroupsOption] = useState<{ label: string; value: number }[]>([]);

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      cowGroupId: '',
    },
    mode: 'onChange',
  });

  useDidUpdate(
    () => {
      if (cowGroupsQuery.isSuccess) {
        console.log('cowGroupsQuery.data', cowGroupsQuery.data);
        setGroupsOption(
          cowGroupsQuery.data.map((group) => ({ label: group.cowGroupName, value: group.cowGroupId || 0 })) || []
        );
        setValue('cowGroupId', cowGroupsQuery.data[0].cowGroupId || '');
      }
    },
    [cowGroupsQuery.data, cowGroupsQuery.isSuccess],
    true
  );

  const { cowGroupId } = getValues();

  useDidUpdate(() => {
    onChange(cowGroupId);
  }, [cowGroupId]);

  return (
    <FormSelectPopover
      control={control}
      name="cowGroupId"
      label={label}
      options={groupsOption}
      defaultValue={{ cowGroupId: '' }}
      wrapperStyle={{ zIndex: 999999 }}
    />
  );
};
