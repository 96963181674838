import { HStack, Text, VStack, Box, ScrollView } from 'native-base';

export const FlatListTable = ({
  labels,
  properties,
  data,
  renderItem,
}: {
  labels: string[];
  properties: string[];
  data: any[];
  renderItem: (item: any) => React.ReactNode;
}) => {
  return (
    <VStack space="md" flex={1}>
      <HStack space="md">
        {labels.map((label) => (
          <Text key={label} width={`${100 / labels.length}%`} bold>
            {label}
          </Text>
        ))}
      </HStack>
      <ScrollView>
        {data.map((item) => (
          <VStack key={item.uid}>
            <HStack space="md">
              {properties.map((property) => (
                <Text width={`${100 / properties.length}%`}>{item[property]}</Text>
              ))}
            </HStack>
            {data.length > 1 && <Box height={0} borderTopWidth={1} borderColor="gray.300" />}
          </VStack>
        ))}
      </ScrollView>
    </VStack>
  );
};
