export const channelType = (cid: string) => {
  if (cid.match(/chumly-self/)) {
    return 'My';
  } else if (cid.match(/u-feed-alert/)) {
    return 'Alert';
  } else if (cid.match(/u-feed-general/)) {
    return 'General';
  } else if (cid.match(/user-channel-secret/)) {
    return 'Secret';
  } else if (cid.match(/user-channel-DM/)) {
    return 'DM';
  } else if (cid.match(/user-channel-public/)) {
    return 'Public';
  } else if (cid.match(/u-feed-group/)) {
    return 'Group';
  } else if (cid.match(/concierge/)) {
    return 'Concierge';
  } else {
    return 'Other';
  }
};

const messagingChannelTypes = ['Secret', 'DM', 'My', 'Concierge'];

const getStreamChatChannelType = (ChumlychannelType: string) =>
  messagingChannelTypes.includes(ChumlychannelType) ? 'messaging' : 'team';

export const getStreamChatChannelTypeById = (channelId: string) => {
  const ChumlychannelType = channelType(channelId);
  return getStreamChatChannelType(ChumlychannelType);
};
