import { Center, HStack, Pressable, Text } from '@gluestack-ui/themed-native-base';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { ChannelPreviewUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { usePinnedChannels } from '~/hooks';
import PinIcons from '~/icons/PinIcons';

import { DateUtil } from '~/utils/DateUtils';

const UnMemoizedChannelPreviewMessenger = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: ChannelPreviewUIComponentProps<StreamChatGenerics>
) => {
  const {
    active,
    Avatar,
    channel,
    className: customClassName = '',
    displayImage,
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { pinnedChannels, pinnedChannelsQuery, addPinnedChannel, removePinnedChannel } = usePinnedChannels();

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const onPinChannel = () => {
    const pinned = pinnedChannels.includes(props.channel.cid);
    if (!pinned) {
      addPinnedChannel(props.channel.cid);
    } else {
      removePinnedChannel(props.channel.cid);
    }
  };

  const lastMessageCreatedAt = props.lastMessage?.created_at ? new Date(props.lastMessage?.created_at) : undefined;
  const isToday = lastMessageCreatedAt ? DateUtil.isToday(lastMessageCreatedAt) : false;

  const lastmassageCreateAtString = lastMessageCreatedAt
    ? isToday
      ? DateUtil.tohmm(lastMessageCreatedAt)
      : DateUtil.toYYYYMMDD(lastMessageCreatedAt)
    : '';

  const unreadCount = channel.state.unreadCount;
  return (
    <HStack
      style={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        aria-label={`Select Channel: ${displayTitle || ''}`}
        aria-selected={active}
        className={clsx(
          `str-chat__channel-preview-messenger str-chat__channel-preview`,
          active && 'str-chat__channel-preview-messenger--active',
          unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
          customClassName
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
        role="option"
      >
        <div className="str-chat__channel-preview-messenger--left" style={{ alignSelf: 'flex-start', padding: 0 }}>
          {Avatar && <Avatar image={displayImage} name={avatarName} size={40} />}
        </div>
        <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
          <div className="str-chat__channel-preview-end-first-row">
            <div className="str-chat__channel-preview-messenger--name">
              <span style={unreadCount > 0 ? { fontWeight: 700 } : {}}>{displayTitle}</span>
            </div>
            <Text
              style={{ textAlign: 'right', whiteSpace: 'nowrap' }}
              fontSize="sm"
              fontWeight="400"
              textAlign="right"
              color="onSurfaceBright"
            >
              {lastmassageCreateAtString}
            </Text>
          </div>
          <div className="str-chat__channel-preview-messenger--last-message_container">
            <HStack justifyContent="space-between" alignItems="center" width="full">
              <div className="str-chat__channel-preview-messenger--last-message">{latestMessage}</div>
              {!!unread && (
                <Center
                  ml="2xs"
                  height="5"
                  width="5"
                  borderRadius={gluestackUIConfig.tokens.radii.full}
                  backgroundColor="primary"
                >
                  <Text color="onPrimary" fontWeight="bold">
                    {unread}
                  </Text>
                </Center>
              )}
            </HStack>
          </div>
        </div>
      </button>
      {isHovered && (
        <Pressable
          style={{
            position: 'absolute',
            top: -24,
            right: 0,
            zIndex: 200,
          }}
          p="2xs"
          borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
          borderColor="outline"
          backgroundColor="surfaceBrightest"
          onPress={onPinChannel}
        >
          <PinIcons />
        </Pressable>
      )}
    </HStack>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
) as typeof UnMemoizedChannelPreviewMessenger;
