import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Foundation } from '@expo/vector-icons';
import { Text, HStack, VStack, Box, Pressable, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useUserChannel } from '~/hooks/useUserChannel';

export const ChatChannelCreateEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const [primary] = useToken('colors', ['primary']);
  const { chatUserId } = useStreamChatAuthContext();

  const [groupsOption, setGroupsOption] = useState([]);
  const [membersOption, setMembersOption] = useState<{ id: string; avatar: string; name: string; yomi: string }[]>([]);
  const [selectedMemberIdList, setSelectedMemberIdList] = useState<string[]>([chatUserId || '']);
  const [selectedChatId, setSelectedChatId] = useState();
  const [selectedChannelType, setSelectedChannelType] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery();
  const { createUserChannel, errorMessages } = useUserChannel();

  const selectedMemberNameList = React.useMemo(() => {
    return membersOption.filter((m) => selectedMemberIdList.includes(m.id)).map((m) => m.name);
  }, [membersOption, selectedMemberIdList]);

  const onSubmit = async (sendingData: any) => {
    const createChannelResult = await createUserChannel({
      name: sendingData.name,
      members: sendingData.members,
      chatTeamId: sendingData.chatTeamId,
      description: sendingData.description,
      isPublic: sendingData.channelType === 'public',
      isSecret: sendingData.channelType === 'secret',
    });

    if (createChannelResult) {
      if (!('isError' in createChannelResult) || !createChannelResult.isError) {
        if (Platform.OS === 'web') {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'QueryCh... Remove this comment to see the full error message
          linkTo(`/chat/${createChannelResult.channel.id}`);
        } else {
          // @ts-expect-error TS(2339): Property 'channel' does not exist on type 'QueryCh... Remove this comment to see the full error message
          const { id, type } = createChannelResult.channel;
          navigation.goBack();
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', {
            channelId: id,
          });
        }
      }
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  useDidUpdate(
    () => {
      if (!currentUserGroupsQuery.isSuccess) {
        return;
      }

      const groups = currentUserGroupsQuery.data?.map((g) => {
        const detail =
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          g?.farm?.name === g?.farm?.account?.name ? g?.farm?.name : `${g?.farm?.name} / ${g?.farm?.account?.name}`;
        return {
          value: g?.chat_team_id,
          label: `${g?.name} (${detail})`,
        };
      });

      // @ts-expect-error TS(2345): Argument of type '{ value: string | undefined; lab... Remove this comment to see the full error message
      setGroupsOption(groups);

      const memberList = currentUserGroupsQuery.data?.[0]?.approved_users
        ?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          avatar: u.avatar || '',
          name: u.name || '',
          yomi: u.yomi || '',
        }))
        .flat();
      setMembersOption(memberList ?? []);

      setDefaultValues({
        // @ts-expect-error TS(2345): Argument of type '{ channelType: string; chatTeamI... Remove this comment to see the full error message
        channelType: 'secret',
        chatTeamId: groups?.[0]?.value,
        members: [chatUserId],
      });
    },
    [currentUserGroupsQuery.data],
    true
  );

  useDidUpdate(() => {
    const chatIds = currentUserGroupsQuery.data
      ?.filter((d) => d.chat_team_id === selectedChatId)
      .map((g) =>
        g.approved_users?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          avatar: u.avatar || '',
          name: u.name || '',
          yomi: u.yomi || '',
        }))
      )
      .flat();
    // @ts-expect-error TS(2345): Argument of type '({ id: any; name: string; } | un... Remove this comment to see the full error message
    setMembersOption(chatIds);
  }, [selectedChatId]);

  if ((groupsOption?.length <= 0 ?? true) || !defaultValues) {
    return null;
  }

  return (
    <>
      <ScreenWidthModal title="ルームを作成">
        <BaseScreenBuilder title="ルームを作成">
          <BaseFormBuilder
            // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
            queryResult={{}}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onFieldUpdate={(updated) => {
              if (updated.chatTeamId !== selectedChatId) {
                updated.setValue('members', [chatUserId]);
              }
              if (updated.chatTeamId) {
                setSelectedChatId(updated.chatTeamId);
              }
              if (updated.channelType) {
                setSelectedChannelType(updated.channelType);
              }
            }}
            emptyMessage="グループがありません"
            defaultValues={defaultValues}
            fields={[
              // @ts-expect-error TS(2322): Type '{ key: string; label: string; rules: { requi... Remove this comment to see the full error message
              {
                key: 'name',
                label: '名前',
                rules: {
                  required: {
                    value: true,
                    message: '名前を入力してください',
                  },
                },
                watch: true,
              },
              {
                key: 'description',
                label: '説明',
                type: 'textarea',
              },
              {
                key: 'channelType',
                label: 'ルーム種別',
                type: 'selectPopover',
                wrapperStyle: { zIndex: 20 },
                options: [
                  {
                    label: '公開',
                    value: 'public',
                  },
                  {
                    label: 'シークレット',
                    value: 'secret',
                  },
                ],
                rules: {
                  required: {
                    value: true,
                    message: '種別を選択してください',
                  },
                },
                watch: true,
              },
              {
                key: 'chatTeamId',
                type: 'comboBox',
                label: '所属グループ',
                options: groupsOption,
                wrapperStyle: { zIndex: 10 },
                watch: true,
                rules: {
                  required: {
                    value: true,
                    message: 'グループを選択してください',
                  },
                },
              },
            ]}
          >
            <HStack alignItems="center" justifyContent="space-between" mb="xs">
              <Text fontSize="sm" color="onSurface">
                メンバー
              </Text>
              <Pressable
                onPress={() =>
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('ChatChannelSelectMembers', {
                    options: membersOption,
                    selectedOptions: selectedMemberIdList,
                    onSubmit: (idList: string[]) => setSelectedMemberIdList(idList),
                  })
                }
              >
                <Text fontSize="sm" fontWeight="bold" color="primary" px="sm">
                  メンバーを追加
                </Text>
              </Pressable>
            </HStack>
            <HStack alignItems="center" gap="xs" mb="md">
              {selectedMemberNameList.map((name, i) => (
                <Box key={`name-${i}`} px="sm" py="2xs" borderColor="outline" borderWidth={1} rounded="full">
                  <Text fontSize="sm" color="onSurface">
                    {name}
                  </Text>
                </Box>
              ))}
            </HStack>
            {selectedChannelType === 'secret' ? (
              <VStack backgroundColor="surface" p="sm" rounded="md">
                <HStack gap="2xs" alignItems="center" flex={1} w="100%">
                  <Foundation name="info" size={20} color={primary} />
                  <Text color="onSurface" bold>
                    シークレットチャンネル
                  </Text>
                </HStack>
                <Text color="onSurface">選択したメンバーのみ閲覧可能です。</Text>
              </VStack>
            ) : (
              <VStack backgroundColor="surface" p="sm" rounded="md">
                <HStack gap="2xs" alignItems="center" flex={1} w="100%">
                  <Foundation name="info" size={20} color={primary} />
                  <Text color="onSurface" bold>
                    公開チャンネル
                  </Text>
                </HStack>
                <Text color="onSurface">同じグループのメンバー全員が閲覧可能です。</Text>
              </VStack>
            )}
          </BaseFormBuilder>
          {errorMessages
            ? errorMessages.map((errorMessage, i) => (
                <Text fontSize="md" fontWeight="medium" color="negative" key={i}>
                  {errorMessage}
                </Text>
              ))
            : null}
        </BaseScreenBuilder>
      </ScreenWidthModal>
    </>
  );
};
