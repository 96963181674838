import { MaterialIcons, AntDesign } from '@expo/vector-icons';
import {
  ScrollView,
  FormControl,
  VStack,
  Pressable,
  Box,
  HStack,
  Text,
  useToken,
} from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import { useState } from 'react';
import { useController } from 'react-hook-form';

import { ViewStyle } from 'react-native';
import { useClickOutside } from 'react-native-click-outside';
import { FormFieldLabel } from './FormFieldLabel';

export const FormSelectPopover: React.FC<React.ComponentProps<typeof Logic> & { wrapperStyle?: ViewStyle }> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  textInputProps,
  options,
  wrapperStyle,
}) => {
  const [primary, onSurfaceBright] = useToken('colors', ['primary', 'onSurfaceBright']);
  const [showOptionList, setShowOptionList] = useState(false);
  const ref = useClickOutside<typeof Box>(() => (showOptionList ? setShowOptionList(false) : null));
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage} style={wrapperStyle}>
      <VStack>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <Box position="relative">
          <Pressable
            ref={ref}
            onPress={() => setShowOptionList(true)}
            borderWidth={1}
            borderColor="outline"
            p="3"
            testID={textInputProps?.testID}
            borderRadius="sm"
            _focus={{
              borderColor: 'primary',
              backgroundColor: 'surfaceBrightest',
            }}
          >
            <HStack alignItems="center" justifyContent="space-between">
              <Text color="onSurface" fontSize="md">
                {options?.find((val) => val.value === field.value)?.label}
              </Text>
              <MaterialIcons name="arrow-drop-down" size={24} color={onSurfaceBright} />
            </HStack>
          </Pressable>
          {showOptionList && (
            <VStack
              position="absolute"
              top={54}
              right={0}
              left={0}
              backgroundColor="surfaceBrightest"
              shadowColor="inverseSurface"
              style={{
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.2,
                shadowRadius: 8,
                elevation: 2,
              }}
            >
              {options?.map((option) => (
                <Pressable
                  key={`option-${option.label}`}
                  onPress={() => {
                    field.onChange(option.value as string);
                    setShowOptionList(false);
                  }}
                  py="xs"
                  px="sm"
                >
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text color="onSurface" fontSize="lg">
                      {option.label}
                    </Text>
                    {option.value === field.value && <AntDesign name="check" size={24} color={primary} />}
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          )}
        </Box>
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
