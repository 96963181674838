import { Ionicons } from '@expo/vector-icons';
import { Box, Center, HStack, Heading, Pressable, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { useCallback, useState, useEffect } from 'react';
import { Modal, Platform } from 'react-native';

type Props = {
  title?: string;
  canBack?: boolean;
  onHeaderRightPress?: () => void;
  headerRightLabel?: string;
  isChevron?: boolean;
};

export const ScreenWidthModal: React.FC<Props & React.PropsWithChildren> = ({
  title,
  canBack = true,
  isChevron = false,
  children,
}) => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [onSurface, onSurfaceBright] = useToken('colors', ['onSurface', 'onSurfaceBright']);

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const onClose = useCallback(() => {
    navigation.goBack();
    setIsVisible(false);
  }, []);

  useEffect(() => {
    setIsVisible(isFocused);
  }, [isFocused]);

  if (Platform.OS !== 'web') {
    return children;
  }

  return (
    <Modal visible={isVisible} animationType="none" transparent onRequestClose={onClose}>
      <Center flex={1} position="relative" overflow="hidden" bg="rgba(0, 0, 0, .5)" py={50}>
        <Pressable onPress={onClose} position="absolute" top={0} left={0} w="full" h="full" />
        <Box
          w="full"
          padding="xl"
          gap="md"
          maxWidth={800}
          maxHeight={600}
          borderRadius={10}
          backgroundColor="surfaceBrightest"
        >
          <HStack
            alignItems="center"
            justifyContent={isChevron ? 'flex-start' : 'space-between'}
            paddingVertical="xs"
            paddingHorizontal="md"
            borderBottomWidth={1}
            borderBottomColor="outlineVariant"
          >
            {canBack && isChevron && (
              <Pressable onPress={onClose} p={2}>
                <Ionicons name="chevron-back" size={24} color={onSurface} />
              </Pressable>
            )}
            <Heading color="onSurface" flex={1} fontSize="lg">
              {title}
            </Heading>
            {canBack && !isChevron && (
              <Pressable onPress={onClose}>
                <Ionicons name="close" size={24} color={onSurfaceBright} />
              </Pressable>
            )}
          </HStack>
          {children}
        </Box>
      </Center>
    </Modal>
  );
};
