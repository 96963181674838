import { VStack, HStack, Button, Text } from 'native-base';
import { useCallback } from 'react';
import { FlatListTable } from './FlatListTable';

export const RegisterToUmotion = ({ data, onCancel, onConfirm }) => {
  const renderItem = useCallback(({ item }: { item: any }) => {
    return (
      <HStack space="md">
        <Text width="50%">{item?.uid}</Text>
        <Text width="50%">{item?.sensor_id}</Text>
      </HStack>
    );
  }, []);
  return (
    <VStack space="lg" p={4} flex={1}>
      <FlatListTable
        labels={['個体識別番号', 'センサー番号']}
        properties={['uid', 'sensor_id']}
        data={data}
        renderItem={renderItem}
      />
      <HStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderTopWidth={1}
        borderColor="gray.100"
        paddingTop={4}
      >
        <Button onPress={onCancel} variant="ghost">
          キャンセル
        </Button>
        <Button onPress={onConfirm}>U-motionセンサーを対象個体に適用</Button>
      </HStack>
    </VStack>
  );
};
