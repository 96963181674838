import { AntDesign } from '@expo/vector-icons';
import { ScrollView, FormControl, VStack, Pressable, Box, HStack, Text, Input } from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import { useState, useMemo, useCallback, useRef } from 'react';
import { useController } from 'react-hook-form';

import { ViewStyle, TextInput as RNInput } from 'react-native';
import { FormFieldLabel } from './FormFieldLabel';

export const FormComboBox: React.FC<React.ComponentProps<typeof Logic> & { wrapperStyle?: ViewStyle }> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  textInputProps,
  options,
  wrapperStyle,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const [textValue, setTextValue] = useState<string>(options?.find((val) => val.value === field.value)?.label || '');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const inputRef = useRef<RNInput>(null);

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  const isSelected = useMemo(() => {
    return !!options?.find((val) => val.label === textValue);
  }, [textValue, options]);

  const filteredOptionList = useMemo(() => {
    return (textValue ? options?.filter((val) => val.label.includes(textValue)) : options) || [];
  }, [textValue, options]);

  const onSelect = useCallback(
    (value: string) => {
      setTextValue(options?.find((val) => val.value === value)?.label || '');
      field.onChange(value);
      inputRef.current?.blur();
    },
    [field]
  );

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage} style={wrapperStyle}>
      <VStack>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <Box position="relative">
          <Input
            ref={inputRef}
            value={textValue}
            onChangeText={setTextValue}
            color="onSurface"
            fontSize="md"
            size="lg"
            variant="outline"
            borderColor="outline"
            py="sm"
            testID={textInputProps?.testID}
            _focus={{
              borderColor: 'primary',
              backgroundColor: 'surfaceBrightest',
            }}
            placeholder="選択してください"
            //FIXMEplaceholderTextColor="onSurfaceBright"
            onFocus={() => {
              setTextValue('');
              setIsInputFocused(true);
            }}
            onBlur={() => {
              if (!isSelected) {
                setTextValue(options?.find((val) => val.value === field.value)?.label || '');
              }
              setIsInputFocused(false);
            }}
          />
          {isInputFocused && (
            <VStack
              position="absolute"
              top={54}
              right={0}
              left={0}
              backgroundColor="surfaceBrightest"
              shadowColor="inverseSurface"
              style={{
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.2,
                shadowRadius: 8,
                elevation: 2,
              }}
            >
              {!filteredOptionList.length && (
                <Text color="onSurfaceBright" fontSize="lg" py="xs" px="sm">
                  マッチする選択肢がありません
                </Text>
              )}
              {filteredOptionList.map((option) => (
                <Pressable
                  key={`option-${option.label}`}
                  onPress={() => onSelect(option.value as string)}
                  py="xs"
                  px="sm"
                >
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text color="onSurface" fontSize="lg">
                      {option.label}
                    </Text>
                    {option.value === field.value && <AntDesign name="check" size={24} color="primary" />}
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          )}
        </Box>
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
