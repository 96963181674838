import { useNavigation, useRoute } from '@react-navigation/native';
import { useState, useEffect, useCallback } from 'react';
import { errorMessageBuilder, BaseFormBuilder } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import type { SettingsStackParamList } from '~/routes/type';
import type { RouteProp } from '@react-navigation/native';
import type { FC } from 'react';

export const SettingsFormScreen: FC = () => {
  const navigation = useNavigation();
  const {
    params: { formBuilderProps },
  } = useRoute<RouteProp<SettingsStackParamList, 'SettingsForm'>>();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (sendingData: Record<string, string>) => {
      setErrorMessages([]);
      setIsUpdating(true);

      try {
        await formBuilderProps.onSubmit(sendingData);
        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        setErrorMessages(errorMessageBuilder(error, formBuilderProps.fields));
        console.error('update error: ', error);
      }
    },
    [formBuilderProps, setErrorMessages]
  );

  useEffect(() => {
    if (!formBuilderProps) {
      navigation.goBack();
    }
  }, []);

  if (!formBuilderProps) {
    return null;
  }

  return (
    <ScreenWidthModal title="" isChevron>
      <BaseScreenBuilder title="">
        <BaseFormBuilder
          {...formBuilderProps}
          onSubmit={onSubmit}
          errorMessage={errorMessages}
          isLoading={isUpdating}
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
