import { Button, useTheme, Text, Input, Box, Center, Image, ScrollView } from '@gluestack-ui/themed-native-base';
import { Formik } from 'formik';
import React from 'react';
import { Platform, SafeAreaView, View } from 'react-native';

import { useNetworkStatus } from '../../hooks/useNetworkStatus';
import { LogoImageDark } from '../UmotionLoginModalScreen/LogoImageDark';
import { LogoImageDefault } from '../UmotionLoginModalScreen/LogoImageDefault';

import { Alert } from '~/components/Alert';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { usePostResetPasswordMutation } from '~/packages/u-motion-api/uMotionApi';

import { validationSchema } from './validationSchema';

export const UmotionResetPasswordScreen = ({ route, navigation }: any) => {
  const [resetPassword] = usePostResetPasswordMutation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { isConnectedToInternet } = useNetworkStatus();
  const theme = useTheme();

  function showAlertSuccess() {
    Alert.alert('完了', 'パスワード変更のためのメールを送信しました。', [
      {
        text: 'OK',
        onPress: () => {
          navigation?.navigate('Login');
        },
      },
    ]);
  }

  const onSubmit = (values: any, actions: any) => {
    if (!isConnectedToInternet) {
      Alert.alert('エラー', 'インターネットを有効にしてください', [{ text: 'OK' }]);
    } else {
      setIsLoading(true);
      resetPassword({ email: values.email })
        .then((reset) => {
          // @ts-expect-error TS(2339): Property 'data' does not exist on type '{ data: un... Remove this comment to see the full error message
          switch (reset.data) {
            case 200: //Success
              showAlertSuccess();
              break;
            case 'Request failed with status code 400': //Network error
              actions.setFieldError('email', '正しいメールアドレスを入力してください');
              break;
            default:
              Alert.alert('エラー', 'パスワードを変更できませんでした。', [{ text: 'OK' }]);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: theme?.dark ? 'black' : 'white',
        justifyContent: 'center',
      }}
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        flex={1}
        justifyContent={Platform.OS === 'web' ? 'center' : undefined}
        mt={Platform.OS !== 'web' ? '2xl' : undefined}
      >
        {Platform.OS === 'web' ? (
          <Center>{theme?.dark ? <LogoImageDark /> : <LogoImageDefault />}</Center>
        ) : (
          <Center>
            <Image source={require('../../assets/logo-uMotion.png')} width="60" height="5xl" />
          </Center>
        )}

        <Center marginVertical="4xl" width="100%" paddingHorizontal="2xl">
          <Box
            flex={1}
            alignItems="center"
            justifyContent="center"
            backgroundColor="#F6F6F6"
            width={Platform.OS !== 'web' ? '100%' : undefined}
          >
            <Text fontSize="md" paddingTop={3} paddingBottom={3} color={theme?.dark ? 'white' : 'black'}>
              パスワード再設定手続き
            </Text>
            <Box flex={1} backgroundColor={theme?.dark ? 'black' : 'gray.100'} p={6} width="100%">
              <Formik
                initialValues={{ email: route.params['resetEmail'] }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(formikProps) => {
                  const emailErrors = formikProps?.touched['email'] && formikProps?.errors['email'];
                  const isButtonDisabled = isLoading || formikProps?.errors?.email;
                  return (
                    <View>
                      <Text fontSize="md" mt={2} mb={1} color={theme?.dark ? 'white' : 'black'}>
                        メールアドレス
                      </Text>
                      <Box mt={4} mb={4}>
                        <Input
                          type="text"
                          fontSize="sm"
                          bg="white"
                          borderWidth={0}
                          padding={4}
                          placeholder="メールアドレス"
                          onChangeText={formikProps?.handleChange('email')}
                          onBlur={formikProps?.handleBlur('email')}
                          autoCapitalize="none"
                          isDisabled={isLoading}
                          borderColor={emailErrors ? 'red.500' : 'white'}
                          width={Platform.OS === 'web' ? '448px' : '100%'}
                          height={Platform.OS === 'web' ? '42px' : 42}
                          placeholderTextColor="#9E9E9E"
                          color="#171717"
                        />
                        <Text style={{ color: 'red' }}>
                          <>{emailErrors}</>
                        </Text>
                      </Box>
                      <Center>
                        <Button
                          onPress={formikProps?.handleSubmit}
                          color="white"
                          loading={isLoading}
                          disabled={isButtonDisabled}
                          backgroundColor="primaryContainer"
                          width={Platform.OS === 'web' ? '280px' : '100%'}
                          _text={{
                            color: '#FFF',
                            fontWeight: 'bold',
                            fontSize: 'lg',
                            fontFamily: 'Noto Sans JP',
                          }}
                          paddingVertical={Platform.OS === 'web' ? '13px' : 13}
                          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
                        >
                          パスワードを再設定
                        </Button>
                      </Center>
                    </View>
                  );
                }}
              </Formik>
            </Box>
            <Button
              margin={4}
              variant="link"
              onPress={() => navigation?.navigate('UmotionLogin')}
              _text={{
                color: '#171717',
                fontSize: 'md',
                fontFamily: 'Noto Sans JP',
              }}
            >
              ログイン画面に戻る
            </Button>
          </Box>
        </Center>
      </ScrollView>
    </SafeAreaView>
  );
};
