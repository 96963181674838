import { Ionicons } from '@expo/vector-icons';
import { ImageBackground } from '@gluestack-ui/themed';
import { Icon, Pressable, Text, View } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { Dimensions } from 'react-native';
import type { StackNavigationProp } from '@react-navigation/stack';

export const BackgroundImageSettingScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();

  navigation?.setOptions({
    title: '',
    headerLeft: () => (
      <Text fontSize="xl" bold color="onSurface">
        写真選択
      </Text>
    ),
    headerRight: () => (
      <Icon
        onPress={() => {
          navigation.goBack();
        }}
        as={Ionicons}
        name="close"
        size="lg"
        color="onSurfaceBright"
      />
    ),
  });

  const pressableWidth = (Dimensions.get('window').width - 4) / 3;
  const IMAGE_URI =
    'https://images.unsplash.com/photo-1549125764-91425ca48850?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8NjF8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60';

  return (
    <View flexDirection="row" flexWrap="wrap" gap="0.5" justifyContent="start">
      <Pressable
        width={pressableWidth}
        height={pressableWidth}
        borderColor="primary"
        onPress={() => {
          navigation.navigate('BackgroundChannelPreview', { IMAGE_URI });
        }}
      >
        <ImageBackground
          style={{ flex: 1 }}
          source={{
            uri: IMAGE_URI,
          }}
        />
      </Pressable>
    </View>
  );
};
