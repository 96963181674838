import { VStack, Text, Divider } from '@gluestack-ui/themed-native-base';
import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import { useCallback } from 'react';
import { Linking, Platform } from 'react-native';

import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { useAuth } from '~/hooks/useAuth';
import { useHelpUrl } from '~/hooks/useHelpUrl';
import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';

import { DrawerMenuItem } from './DrawerMenuItem';

const Section: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <VStack paddingLeft="md" paddingRight="md" paddingY="2xs" space="xs">
      {children}
    </VStack>
  );
};

export const DrawerContent = (props: DrawerContentComponentProps) => {
  const { handleLogout } = useAuth();
  const currentUser = useGetCurrentUserQuery({});
  const settings = useAppSelector(settingsSelector);
  const { helpUrl } = useHelpUrl();

  const closeDrawer = useCallback(() => props.navigation.closeDrawer(), [props.navigation]);

  return (
    <DrawerContentScrollView {...props}>
      <VStack paddingBottom="2xs" paddingTop="3xs" space="xs">
        <Section>
          <DrawerMenuItem icon="list-outline" title="牧場・グループ" navigateTo="FarmList" closeDrawer={closeDrawer} />
        </Section>
        <Divider />
        {currentUser.data?.roles?.includes('account_admin') ||
        currentUser.data?.roles?.includes('group_admin') ||
        currentUser.data?.roles?.includes('member_admin') ? (
          <Section>
            <Text bold color="onSurfaceBright">
              管理者
            </Text>
            {currentUser.data?.roles?.includes('account_admin') && (
              <DrawerMenuItem title="企業情報" navigateTo="AdminAccountEdit" closeDrawer={closeDrawer} />
            )}
            {currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'group_admin') && (
              <DrawerMenuItem title="牧場・グループ" navigateTo="AdminFarmList" closeDrawer={closeDrawer} />
            )}
            {currentUser.data?.roles?.some((role) => role === 'account_admin' || role === 'member_admin') && (
              <DrawerMenuItem title="企業ユーザー管理" navigateTo="AdminUserList" closeDrawer={closeDrawer} />
            )}
          </Section>
        ) : null}
        <Divider marginTop={2} />
        <VStack paddingY={2} divider={<Divider />} space={2}>
          <Section>
            <DrawerMenuItem
              icon="settings-outline"
              title="ユーザー設定"
              navigateTo="SettingsTop"
              testID="SettingsTop"
              closeDrawer={closeDrawer}
            />
            <DrawerMenuItem
              icon="bulb-outline"
              title="Chumlyについて"
              navigateTo="AboutThisApp"
              closeDrawer={closeDrawer}
            />
            <DrawerMenuItem
              icon="help-circle-outline"
              title="使い方"
              navigateTo={Platform.OS === 'web' ? undefined : 'Help'}
              onPress={
                Platform.OS === 'web'
                  ? () => {
                      Linking.openURL(helpUrl).catch((err) => console.error('URLを開けませんでした。', err));
                    }
                  : undefined
              }
            />
            <DrawerMenuItem
              title="ログアウト"
              icon="log-out-outline"
              onPress={() => {
                props.navigation.closeDrawer();
                handleLogout();
              }}
              testID="LogoutButton"
            />
          </Section>
        </VStack>
      </VStack>
    </DrawerContentScrollView>
  );
};
