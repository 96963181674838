import { Entypo } from '@expo/vector-icons';
import { Button, Link, VStack, Text, Box, Input } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
// @ts-expect-error TS(2307): Cannot find module '@u-motion-apps/packages/u-moti... Remove this comment to see the full error message
import { usePostSessionMutation } from '@u-motion-apps/packages/u-motion-api/uMotionApi';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { useNetworkStatus } from '../../hooks/useNetworkStatus';

import { Alert } from '~/components/Alert';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { setUmotionToken } from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { useAppDispatch } from '~/store';

import { validationSchema } from './validationSchema';

const MAX_ATTEMPTS = 9;
const DISABLE_INTERVAL = 30000;

export const UmotionEmailLoginScreen = ({ theme }: any) => {
  const [isSecure, setIsSecure] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const { isConnectedToInternet } = useNetworkStatus();
  const [postSession] = usePostSessionMutation();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  function hideButton() {
    let icon = <Entypo name="eye-with-line" size={20} />;
    if (isSecure === true) {
      icon = <Entypo name="eye" size={20} />;
    }
    return (
      <TouchableOpacity
        onPress={switchShowHide}
        style={{ height: '100%', backgroundColor: '#FFF', justifyContent: 'center' }}
      >
        <Box mr="16px" backgroundColor="#FFF">
          {icon}
        </Box>
      </TouchableOpacity>
    );
  }

  const switchShowHide = () => {
    setIsSecure(!isSecure);
  };

  const loginError = (actions: any) => {
    setAttempts(attempts + 1);

    if (attempts != MAX_ATTEMPTS) {
      actions.setFieldError('general', 'メールアドレスかパスワードが正しくありません');
      actions.setFieldError('email', '');
      actions.setFieldError('password', '');
    } else {
      actions.setFieldError('general', '30秒間ログインできません');
      setIsDisabled(true);
      setTimeout(() => {
        actions.setFieldError('general', '');
        setIsDisabled(false);
        setAttempts(0);
      }, DISABLE_INTERVAL);
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    if (!isConnectedToInternet) {
      Alert.alert('エラー', 'インターネットを有効にしてください', [{ text: 'OK' }]);
    } else {
      setIsLoading(true);

      try {
        const result = await postSession({
          email: values.email,
          password: values.password,
        }).unwrap();

        if (!result) {
          actions.setFieldError('email', 'ログインできませんでした');
        } else {
          dispatch(
            setUmotionToken({
              sessionToken: result.sessionToken || '',
              refreshToken: result.refreshToken || '',
              expiredAt: result.expiredAt || 0,
            })
          );
          localStorage.setItem('LastLoginMethodStorageKey', 'email');
        }
      } catch (error) {
        console.error('login error => ', error);
        loginError(actions);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', remember: true }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const isButtonDisabled = !formikProps.isValid || isLoading || isDisabled;
        const emailErrors = formikProps?.touched['email'] && formikProps?.errors['email'];
        const passwordErrors = formikProps?.touched['password'] && formikProps?.errors['password'];
        return (
          <VStack space="2xl" flex={1} marginTop="2xl">
            <Box>
              <Input
                type="text"
                fontSize="sm"
                bg="white"
                padding="sm"
                borderWidth={0}
                width="448px"
                height="42px"
                placeholder="メールアドレス"
                placeholderTextColor="#9E9E9E"
                onChangeText={formikProps?.handleChange('email')}
                onBlur={formikProps?.handleBlur('email')}
                autoFocus
                autoCapitalize="none"
                isDisabled={isDisabled}
                borderColor={emailErrors ? 'red.500' : 'white'}
                color="#171717"
              />
              <Text style={{ color: 'red' }}>
                <>{emailErrors}</>
              </Text>
            </Box>
            <Box>
              <Input
                type="password"
                fontSize="sm"
                bg="white"
                padding="sm"
                borderWidth={0}
                placeholder="パスワード"
                height="42px"
                placeholderTextColor="#9E9E9E"
                onChangeText={formikProps?.handleChange('password')}
                onBlur={formikProps?.handleBlur('password')}
                autoCapitalize="none"
                isDisabled={isDisabled}
                secureTextEntry={isSecure}
                borderColor={passwordErrors ? 'red.500' : 'white'}
                keyboardType="ascii-capable"
                InputRightElement={hideButton()}
                color="#171717"
              />
              <Text style={{ color: 'red' }}>
                <>{passwordErrors}</>
              </Text>
            </Box>
            <VStack space="2xl" alignItems="center">
              <Button
                testID="loginButton"
                onPress={formikProps.handleSubmit}
                disabled={isButtonDisabled}
                loading={isLoading}
                backgroundColor="primaryContainer"
                _text={{
                  color: '#FFF',
                  fontWeight: 'bold',
                  fontSize: 'lg',
                  fontFamily: 'Noto Sans JP',
                }}
                paddingHorizontal="108px"
                paddingVertical="13px"
                borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
              >
                ログイン
              </Button>
              <Button
                variant="link"
                onPress={() =>
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('UmotionResetPassword', {
                    resetEmail: formikProps.values['email'],
                  })
                }
                _text={{
                  color: '#005FFF',
                  fontSize: 'sm',
                  fontFamily: 'Noto Sans JP',
                }}
                padding="0"
              >
                パスワードを忘れた方はこちら
              </Button>
              {/* @ts-expect-error TS(2339): Property 'general' does not exist on type 'FormikE... Remove this comment to see the full error message */}
              {formikProps.errors.general && (
                <Box
                  alignItems="center"
                  paddingTop={4}
                  paddingBottom={4}
                  style={{ backgroundColor: theme?.dark ? 'black' : '#eee' }}
                >
                  {/* @ts-expect-error TS(2339): Property 'general' does not exist on type 'FormikE... Remove this comment to see the full error message */}
                  <Text style={{ color: 'red' }}>{formikProps.errors.general}</Text>
                </Box>
              )}
              <Text color="#171717" fontSize="md" fontFamily="Noto Sans JP">
                「ログイン」をタップすると
                <Link href=" https://www.desamis.co.jp/u-feed-terms" isExternal>
                  <Text color="#005FFF" fontSize="md" fontFamily="Noto Sans JP">
                    利用規約
                  </Text>
                </Link>
                と
                <Link href="https://www.desamis.co.jp/u-feed-privacy-policy" isExternal color="#005FFF">
                  <Text color="#005FFF" fontSize="md" fontFamily="Noto Sans JP">
                    プライバシーポリシー
                  </Text>
                </Link>
                に同意したことになります。
              </Text>
            </VStack>
          </VStack>
        );
      }}
    </Formik>
  );
};
