import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, Text, Pressable, VStack } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Toast } from 'native-base';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  useDeletePushMuteConfigsByIdMutation,
  useGetPushMuteConfigsExistQuery,
  usePostPushMuteConfigsMutation,
} from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { ShowPinMessagesContext, ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChannelMembers } from '~/hooks';
import CloseIcons from '~/icons/CloseIcons';
import DragIcons from '~/icons/DragIcons';
import InforIcons from '~/icons/InforIcons';
import { useChannelStateContext, DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import { SortableItemContext } from './SortableItem';
import type { Channel } from 'stream-chat';

const UnMemoizedCustomHeader: React.FC<{
  handleOpenInfo?: (() => void) | undefined;
  channelSelected?: Channel<DefaultStreamChatGenerics>;
  multiSection?: boolean;
  onClose?: (() => void) | undefined;
}> = ({ handleOpenInfo, multiSection, channelSelected, onClose }) => {
  const { thread } = useChannelStateContext();

  if (thread) {
    return null;
  }
  const navigation = useNavigation();
  const { chatClient, appChannel } = useStreamChatContext();
  const channel = appChannel as Channel<DefaultStreamChatGenerics>;
  const linkTo = useLinkTo();

  const [mute, setMute] = useState(false);
  const pushMuteConfigQuery = useGetPushMuteConfigsExistQuery({ targetId: channel?.cid, targetType: 'channel' });
  const [postPushMuteConfig] = usePostPushMuteConfigsMutation();
  const [deletePushMuteConfig] = useDeletePushMuteConfigsByIdMutation();
  const { allMembers } = useChannelMembers(multiSection ? channelSelected?.id || '' : channel?.id || '');

  const dispatch = useContext(ShowPinMessagesDispatchContext);
  const showPinMessages = useContext(ShowPinMessagesContext);

  useEffect(() => {
    if (thread) {
      handleOpenInfo;
    }
  }, [thread]);

  const switchPinMessage = useCallback(() => {
    dispatch && dispatch({ type: 'switch' });
  }, [dispatch]);

  const handleSearchPress = useCallback(() => {
    const channelId = channel?.id;

    linkTo(`/search?id=${channelId}`);
  }, [channel]);

  const handleMemberlistPress = useCallback(() => {
    const index = (channel?.cid?.indexOf(':') || 0) + 1;
    const channelId = channel?.cid?.substring(index);
    navigation.getState().routeNames[0] === 'DirectMessageList'
      ? linkTo(`/dm/memberlist/${channelId}`)
      : linkTo(`/chat/memberlist/${channelId}`);
  }, [navigation]);

  const handleMutePress = useCallback(() => {
    if (mute) {
      const muteConfig = pushMuteConfigQuery.currentData;
      if (!muteConfig) {
        return;
      }
      deletePushMuteConfig({ id: muteConfig.id });
      Toast.show({
        description: 'プッシュ通知のミュートを解除しました',
        placement: 'top',
      });
    } else {
      postPushMuteConfig({ body: { push_mute_config: { target_type: 'channel', target_id: channel?.cid || '' } } });
      Toast.show({
        description: 'プッシュ通知をミュートしました',
        placement: 'top',
      });
    }
  }, [pushMuteConfigQuery, deletePushMuteConfig, postPushMuteConfig, mute]);

  useDidUpdate(
    () => {
      if (pushMuteConfigQuery.isLoading) {
        return;
      }
      const data = pushMuteConfigQuery.currentData;
      if (pushMuteConfigQuery.isSuccess && data?.id) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    [pushMuteConfigQuery],
    true
  );

  useDidUpdate(() => {
    dispatch && dispatch({ type: 'close' });
  }, [channel?.cid]);

  //トーストの問題の対応 https://github.com/GeekyAnts/NativeBase/issues/5107
  setTimeout(() => Toast.closeAll(), 5000);

  const memberIds = channel?.state?.members ? Object.keys(channel?.state?.members) : [];
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { attributes, listeners, ref } = useContext(SortableItemContext);
  return (
    <HStack
      position="absolute"
      top="0"
      width="100%"
      py="sm"
      px="md"
      borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
      style={{
        zIndex: 100,
        justifyContent: multiSection ? 'space-between' : 'center',
      }}
      backgroundColor="surfaceBrightest"
    >
      <HStack alignItems="center" justifyContent="space-between" width="100%">
        {multiSection ? (
          <HStack gap="2xs" alignItems="center">
            <strong>
              <Pressable {...attributes} {...listeners} ref={ref}>
                <DragIcons />
              </Pressable>
            </strong>
            <VStack>
              <Text fontWeight="bold" fontSize="lg" color="onSurface">
                {!channelSelected?.data?.isDM || memberIds.length <= 1
                  ? channelSelected?.data?.name
                  : memberIds
                      .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                      .map((key) => channelSelected?.state?.members[key]?.user?.name)
                      .join(', ')}
              </Text>
              <Text fontSize="sm" fontWeight="regular" color="onSurface">
                {allMembers?.length} 名のメンバー
              </Text>
            </VStack>
          </HStack>
        ) : (
          <VStack>
            <Text fontWeight="bold" fontSize="lg" color="onSurface">
              {!channel?.data?.isDM || memberIds.length <= 1
                ? channel?.data?.name
                : memberIds
                    .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                    .map((key) => channel?.state?.members[key]?.user?.name)
                    .join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="regular" color="onSurface">
              {allMembers?.length} 名のメンバー
            </Text>
          </VStack>
        )}
        <HStack marginRight={0} marginLeft="auto">
          {multiSection ? (
            <Pressable onPress={onClose}>
              <CloseIcons />
            </Pressable>
          ) : (
            <Pressable onPress={handleOpenInfo}>
              <HStack alignItems="center" space={1}>
                <InforIcons />
              </HStack>
            </Pressable>
          )}
        </HStack>
      </HStack>
    </HStack>
  );
};

export const CustomHeader = React.memo(UnMemoizedCustomHeader);
