import { useDidMount } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { StatusBar, Pressable, Icon, HStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { useEffect } from 'react';
import { Platform, Dimensions } from 'react-native';
import { SceneMap, TabView, TabBar } from 'react-native-tab-view';

import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks/usePinnedChannels';
import { Chat } from '~/lib/StreamChatReact';
import { ChatChannelListScreen } from '~/screens';

const initialLayout = {
  width: Dimensions.get('window').width,
};

const { useState, useMemo } = React;

export const ChatTabbedChannelListScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const { pinnedChannels } = usePinnedChannels();
  const { chatClient } = useStreamChatContext();

  const [index, setIndex] = useState(0);
  const routes = useMemo(() => {
    const routeSet = new Set<{ key: string; title: string }>([]);
    routeSet.add({
      key: 'my',
      title: '参加中',
    });
    if (pinnedChannels.length > 0) {
      routeSet.add({
        key: 'pinned',
        title: 'ピン',
      });
    }
    routeSet.add({
      key: 'others',
      title: 'その他',
    });
    return Array.from(routeSet);
  }, [pinnedChannels]);

  const my = () => <ChatChannelListScreen type="my" />;
  const pinned = () => <ChatChannelListScreen type="pinned" />;
  const others = () => <ChatChannelListScreen type="others" />;
  const linkTo = useLinkTo();

  const handleNavigation = (index: number) => {
    setIndex(index);
    linkTo(`/chat`);
  };
  useDidMount(() => {
    if (Platform.OS === 'web') {
      return;
    }

    navigation.setOptions({
      headerRight: () => {
        return (
          <HStack alignItems="center" space={4} paddingRight={Platform.OS === 'android' ? 4 : 0}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Pressable onPress={() => navigation.navigate('ChatBookmarkList')}>
              <Icon as={Ionicons} name="bookmark-outline" size="lg" />
            </Pressable>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Pressable onPress={() => navigation.navigate('DirectMessageList')}>
              <Icon as={Ionicons} name="mail-outline" size="xl" />
            </Pressable>
          </HStack>
        );
      },
    });
  });

  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
    if (params?.channelId) {
      // パラメータが与えられた場合、タブを「参加中」にリセットする
      setIndex(0);
    }
  }, [params]);

  const renderScene = useMemo(() => {
    return SceneMap({
      my,
      pinned,
      others,
    });
  }, []);

  const renderTabBar = (props: any) => {
    return (
      <TabBar
        {...props}
        index={index}
        setIndex={setIndex}
        indicatorStyle={{ backgroundColor: '#0EC9E5' }}
        style={{ backgroundColor: 'white', color: 'black' }}
        activeColor="black"
        inactiveColor="#bbb"
      />
    );
  };

  return (
    <Chat client={chatClient}>
      <TabView
        navigationState={{
          index,
          routes,
        }}
        onIndexChange={handleNavigation}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        initialLayout={initialLayout}
        style={{
          // @ts-expect-error TS(2339): Property 'currentHeight' does not exist on type 'F... Remove this comment to see the full error message
          marginTop: StatusBar.currentHeight,
        }}
        lazy
        // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
        swipeEnabled={!Platform.OS === 'web'}
      />
    </Chat>
  );
};
