import { baseApi as api } from '../uMotionBaseQuery';
const addTagTypes = ['Cow'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postCow: build.mutation<PostCowApiResponse, PostCowApiArg>({
        query: (queryArg) => ({ url: `/cow`, method: 'POST', body: queryArg.cow }),
        invalidatesTags: ['Cow'],
      }),
      getCowDeprecatedDigest: build.query<GetCowDeprecatedDigestApiResponse, GetCowDeprecatedDigestApiArg>({
        query: (queryArg) => ({ url: `/cow/deprecated_digest` }),
        providesTags: ['Cow'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as cowAutoGeneratedApi };
export type PostCowApiResponse = /** status 200 成功 */ {
  cowId?: number;
};
export type PostCowApiArg = {
  cow: Cow;
};
export type GetCowDeprecatedDigestApiResponse = /** status 200 イベント一括入力用が返される */ CowDeprecatedDigest;
export type GetCowDeprecatedDigestApiArg = {
  allowInactive?: boolean;
};
export type Cow = {
  cowUid: string;
  cowNo: string;
  introduceDate: string;
  birthday: string;
  gender: string;
  cowGroupId: number;
  bleId?: string;
  bleIdStartDate?: string;
  pen?: string;
  cowName?: string;
  breed?: string;
  raisingFarmName?: string;
  producingArea?: string;
  producingFarmName?: string;
  breedingPoint?: string;
  registrationNumber?: string;
  birthNumber?: string;
  latestCalvingDate?: string;
  latestBreedingCount?: string;
  latestBreedingMethod?: string;
  firstFertilizationDate?: string;
  latestFertilizationDate?: string;
  latestMasterSpermNo?: string;
  pregnancy?: string;
  latestJudgePregnantDate?: string;
  latestPregnancyDate?: string;
  latestDryPreviousPeriodStartDate?: string;
  latestDryLatePeriodStartDate?: string;
  startFatteningDate?: string;
  weanedDate?: string;
  masterMarketId?: number;
  purchasePrice?: string;
  weightOnPurchase?: string;
  motherName?: string;
  motherCowNo?: string;
  motherCowUid?: string;
  motherBirthNumber?: string;
  fatherName?: string;
  maternalGrandmotherName?: string;
  maternalGrandfatherName?: string;
  maternalGreatGrandfatherName?: string;
  sensorType?: number | undefined;
};
export type CowDeprecatedDigest = {
  cowId: number;
  cowNo: string;
  cowUid: string;
  gender: string;
  state: string;
  eliminateDate: string;
  fallingDeadDate: string;
  cowGroupId: number;
  pen: string;
  occurredMastitisDate: string;
  occurredPerinatalDifficultyDate: string;
  mastitisCowGroupId: number;
  hormoneProgramName: string;
  nextHormoneCode: string;
  nextHormoneName: string;
  stopMilkingDateOfBreastBl: string;
  stopMilkingDateOfBreastBr: string;
  stopMilkingDateOfBreastFl: string;
  stopMilkingDateOfBreastFr: string;
  latestCalvingDate: string;
  latestFertilizationDate: string;
  latestPregnancyDate: string;
  occurredLameDate: string;
  occurredInfectDate: string;
  latestHormoneDate: string;
  artificialInseminationDate: string;
  latestMasterSpermNo: string;
  latestBreedingMethod: string;
  producingArea: string;
  producingFarmName: string;
}[];
export const { usePostCowMutation, useGetCowDeprecatedDigestQuery } = injectedRtkApi;
