import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const CreateColumnIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_1648_8018" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill={gluestackUIConfig.tokens.colors.mono400} />
      </mask>
      <g mask="url(#mask0_1648_8018)">
        <path
          d="M15.8327 12.5C15.5966 12.5 15.3987 12.4201 15.2389 12.2604C15.0792 12.1007 14.9993 11.9028 14.9993 11.6667V10.8333H14.166C13.9299 10.8333 13.732 10.7535 13.5723 10.5938C13.4125 10.434 13.3327 10.2361 13.3327 10C13.3327 9.76389 13.4125 9.56597 13.5723 9.40625C13.732 9.24653 13.9299 9.16667 14.166 9.16667H14.9993V8.33333C14.9993 8.09722 15.0792 7.89931 15.2389 7.73958C15.3987 7.57986 15.5966 7.5 15.8327 7.5C16.0688 7.5 16.2667 7.57986 16.4264 7.73958C16.5862 7.89931 16.666 8.09722 16.666 8.33333V9.16667H17.4993C17.7355 9.16667 17.9334 9.24653 18.0931 9.40625C18.2528 9.56597 18.3327 9.76389 18.3327 10C18.3327 10.2361 18.2528 10.434 18.0931 10.5938C17.9334 10.7535 17.7355 10.8333 17.4993 10.8333H16.666V11.6667C16.666 11.9028 16.5862 12.1007 16.4264 12.2604C16.2667 12.4201 16.0688 12.5 15.8327 12.5ZM3.33268 17.5C2.87435 17.5 2.48199 17.3368 2.1556 17.0104C1.82921 16.684 1.66602 16.2917 1.66602 15.8333V4.16667C1.66602 3.70833 1.82921 3.31597 2.1556 2.98958C2.48199 2.66319 2.87435 2.5 3.33268 2.5H8.33268V17.5H3.33268ZM9.99935 17.5V2.5H14.9993C15.4577 2.5 15.85 2.66319 16.1764 2.98958C16.5028 3.31597 16.666 3.70833 16.666 4.16667V5.08333C16.666 5.30556 16.5827 5.48958 16.416 5.63542C16.2494 5.78125 16.0549 5.84722 15.8327 5.83333C14.6799 5.83333 13.6973 6.23611 12.8848 7.04167C12.0723 7.84722 11.666 8.83333 11.666 10C11.666 11.1667 12.0723 12.1528 12.8848 12.9583C13.6973 13.7639 14.6799 14.1667 15.8327 14.1667C16.0549 14.1667 16.2494 14.2361 16.416 14.375C16.5827 14.5139 16.666 14.6944 16.666 14.9167V15.8333C16.666 16.2917 16.5028 16.684 16.1764 17.0104C15.85 17.3368 15.4577 17.5 14.9993 17.5H9.99935Z"
          fill={gluestackUIConfig.tokens.colors.primary}
        />
      </g>
    </svg>
  );
};

export default CreateColumnIcons;
