import { refreshTokenApi } from './uMotionBaseQuery';

export const addTagTypes = [] as const;

const injectedRtkRefreshTokenApi = refreshTokenApi
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
        query: (queryArg) => ({
          url: `/session`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
      switchFarm: build.mutation<PutSwitchFarmApiResponse, PutSwitchFarmApiArg>({
        query: (body) => ({
          url: `/session/farm`,
          method: 'PUT',
          body,
        }),
      }),
    }),
    overrideExisting: false,
  });

export { injectedRtkRefreshTokenApi as uMotionRefreshTokenApi };

export type RefreshTokenApiResponse = /** status 200 Successful login */ {
  sessionToken?: string;
  refreshToken?: string;
  expiredAt?: string;
};

export type RefreshTokenApiArg = {
  body: {
    refreshToken: string;
  };
};

export type PutSwitchFarmApiArg = {
  id: number;
};

export type PutSwitchFarmApiResponse = {
  sessionToken: string;
  refreshToken: string;
  expiredAt: number;
};

export const { useRefreshTokenMutation, useSwitchFarmMutation } = injectedRtkRefreshTokenApi;
