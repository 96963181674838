import { HStack, Text } from '@gluestack-ui/themed-native-base';

interface Props {
  label?: string;
  isRequired?: boolean;
}

export const FormFieldLabel: React.FC<Props> = ({ isRequired, label }) => {
  return (
    <HStack space={2} alignItems="center">
      {label ? (
        <Text fontSize="sm" color="onSurface">
          {label}
        </Text>
      ) : null}
      {isRequired ? (
        <Text fontSize="md" color="negative">
          &nbsp;*
        </Text>
      ) : null}
    </HStack>
  );
};
