import React from 'react';

const PinIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_2094_17526" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2094_17526)">
        <path
          d="M13.3333 4.16667V10L14.75 11.4167C14.8333 11.5 14.8958 11.5938 14.9375 11.6979C14.9792 11.8021 15 11.9097 15 12.0208V12.5C15 12.7361 14.9201 12.934 14.7604 13.0938C14.6007 13.2535 14.4028 13.3333 14.1667 13.3333H10.8333V18.2083C10.8333 18.4444 10.7535 18.6424 10.5938 18.8021C10.434 18.9618 10.2361 19.0417 10 19.0417C9.76389 19.0417 9.56597 18.9618 9.40625 18.8021C9.24653 18.6424 9.16667 18.4444 9.16667 18.2083V13.3333H5.83333C5.59722 13.3333 5.39931 13.2535 5.23958 13.0938C5.07986 12.934 5 12.7361 5 12.5V12.0208C5 11.9097 5.02083 11.8021 5.0625 11.6979C5.10417 11.5938 5.16667 11.5 5.25 11.4167L6.66667 10V4.16667C6.43056 4.16667 6.23264 4.08681 6.07292 3.92708C5.91319 3.76736 5.83333 3.56944 5.83333 3.33333C5.83333 3.09722 5.91319 2.89931 6.07292 2.73958C6.23264 2.57986 6.43056 2.5 6.66667 2.5H13.3333C13.5694 2.5 13.7674 2.57986 13.9271 2.73958C14.0868 2.89931 14.1667 3.09722 14.1667 3.33333C14.1667 3.56944 14.0868 3.76736 13.9271 3.92708C13.7674 4.08681 13.5694 4.16667 13.3333 4.16667Z"
          fill="#737373"
        />
      </g>
    </svg>
  );
};

export default PinIcons;
