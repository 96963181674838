import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import * as React from 'react';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { useStreamChatContext } from '~/contexts/StreamChatContext';

type ChannelListCategory = 'my' | 'pinned' | 'others';

interface Props {
  type: ChannelListCategory;
  disableAddChannel?: boolean;
}

export const ChatBotScreen: React.FC<Props> = React.memo(({ type, disableAddChannel }) => {
  const { chatUserId, isUserConnected, chatClient } = useStreamChatContext();
  const [myConciergeChannel, setMyConciergeChannel] = React.useState<string | undefined>(undefined);

  useDidUpdate(() => {
    if (!chatUserId || !chatClient || !isUserConnected) return;

    chatClient
      .queryChannels({
        is_concierge_channel: true,
      })
      .then((channels) => {
        setMyConciergeChannel(channels[0]?.id);
      });
  }, [chatUserId, chatClient, isUserConnected]);

  if (myConciergeChannel === undefined) return null;

  return <ChatChannelListWeb type="concierge" channelId={myConciergeChannel} hideChannelList />;
});
