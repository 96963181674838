import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Center, HStack, Spinner } from '@gluestack-ui/themed-native-base';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { SortableItem } from '~/components/SortableItem';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { StreamChatGenerics } from '~/types';
import { AddChannelColumn } from './ChannelColumn';
import { NotificationColumn } from './NotificationColumn';
import type { Channel as StreamChannel } from 'stream-chat';

const COLUMN_WIDTH = 400;

type AllChannelContextType = {
  allChannels: StreamChannel<StreamChatGenerics>[];
};

const AllChannelContext = createContext<AllChannelContextType>({
  allChannels: [],
});

const AllChannelProvider = ({ children }: { children: React.ReactNode }) => {
  const { chatClient, chatUserId } = useStreamChatAuthContext();

  const [allChannels, setAllChannels] = useState<StreamChannel[]>([]);

  useEffect(() => {
    const getAllChannels = async (chatUserId: string) => {
      let readmore = true;
      let results: StreamChannel[] = [];
      let offset = 0;
      const limit = 100;
      while (readmore) {
        const channels = await chatClient.queryChannels({ members: { $in: [chatUserId] } }, {}, { limit, offset });
        results = [...results, ...channels];
        offset += channels.length;
        if (channels.length < limit) {
          readmore = false;
        }
      }
      return results;
    };

    if (!chatUserId) return;
    getAllChannels(chatUserId).then(setAllChannels);
  }, [chatUserId]);

  const contextValue = useMemo(() => ({ allChannels }), [allChannels]);
  return <AllChannelContext.Provider value={contextValue}>{children}</AllChannelContext.Provider>;
};

export const useAllChannel = () => {
  const context = React.useContext(AllChannelContext);
  if (context === undefined) {
    throw new Error('useAllChannel must be used within a AllChannelProvider');
  }
  return context;
};

type ColumnData = { key: string; cid: string };

export const DashboardScreen = () => {
  const { isUserConnected } = useStreamChatAuthContext();
  const [channelColumn, setChannelColumn] = useState<ColumnData[]>([]);
  const { pinnedChannels, pinnedChannelsQuery } = usePinnedChannels();
  const [items, setItems] = useState<string[]>(channelColumn.map((channel) => channel.cid));
  useEffect(() => {
    const newItems = channelColumn.map((channel) => channel.cid);
    setItems(newItems);
  }, [channelColumn]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setItems((items) => {
        return arrayMove(items, items?.indexOf(active.id as string), items?.indexOf(over?.id as string));
      });
    }
  };

  useDidUpdate(
    () => {
      if (isUserConnected) {
        pinnedChannelsQuery();
      }
    },
    [isUserConnected],
    true
  );

  useEffect(() => {
    const convertedArray = pinnedChannels.map((item, index) => ({
      key: index.toString(),
      cid: item,
    }));

    setChannelColumn(convertedArray);
  }, [pinnedChannels]);

  if (!isUserConnected)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  return (
    <AllChannelProvider>
      <HStack gap="2xs" backgroundColor="surface" height="100%" overflowX="scroll" width="auto">
        <div>
          <HStack gap="2xs" backgroundColor="surface" height="100%" width="auto">
            <NotificationColumn width={COLUMN_WIDTH} />
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
              <SortableContext items={items as any}>
                {items?.map((v) => <SortableItem key={v} id={v} channelId={v} COLUMN_WIDTH={COLUMN_WIDTH} />)}
              </SortableContext>
            </DndContext>
          </HStack>
        </div>

        <div>
          <AddChannelColumn />
        </div>
      </HStack>
    </AllChannelProvider>
  );
};
