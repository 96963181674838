import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { ScrollView, Box, Alert, VStack, HStack, Text, Skeleton, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { StyledProps } from 'native-base';
import * as React from 'react';
import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { ScreenWidthAdjuster } from '~/components';
import { HeaderRight } from '~/components/HeaderRight';

interface Props {
  title?: string;
  headerRightLabel?: string;
  onHeaderRightPress?: () => void;
  headerLeftLabel?: string;
  onHeaderLeftPress?: () => void;
  noScroll?: boolean;
  padding0?: boolean;
  stickyHeaderIndices?: number[];
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isEmpty?: boolean;
  emptyMessage?: string;
}

export const BaseScreenBuilder: React.FC<Props & StyledProps & React.PropsWithChildren> = ({
  headerRightLabel,
  onHeaderRightPress,
  headerLeftLabel,
  onHeaderLeftPress,
  children,
  title,
  noScroll = false,
  padding0 = false,
  stickyHeaderIndices,
  isLoading,
  isError,
  errorMessage,
  isEmpty,
  emptyMessage,
  ...props
}) => {
  const [surfaceBrightest] = useToken('colors', ['surfaceBrightest']);
  const navigation = useNavigation();

  useDidUpdate(
    () => {
      if (title) {
        navigation?.setOptions?.({
          title,
        });
      }

      if (headerRightLabel && onHeaderRightPress) {
        navigation?.setOptions({
          headerRight: () => <HeaderRight label={headerRightLabel} onPress={onHeaderRightPress} />,
        });
      }

      if (headerLeftLabel && onHeaderLeftPress) {
        navigation?.setOptions({
          headerLeft: () => <HeaderRight label={headerLeftLabel} onPress={onHeaderLeftPress} />,
        });
      }
    },
    [title, headerRightLabel, headerLeftLabel, onHeaderRightPress, onHeaderLeftPress],
    true
  );

  const padding = padding0 ? 0 : Platform.OS === 'web' ? 'md' : '2xs';

  if (isLoading) {
    return (
      <ScreenWidthAdjuster>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </ScreenWidthAdjuster>
    );
  }

  if (isError) {
    return (
      <ScreenWidthAdjuster>
        <Box padding="xs" flex={1} backgroundColor="inverseOnSurface">
          <Alert status="warning" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="flex-start" space={2} flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {errorMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </Box>
      </ScreenWidthAdjuster>
    );
  }

  if (isEmpty) {
    return (
      <ScreenWidthAdjuster>
        <Box padding="2xs" flex={1} backgroundColor="inverseOnSurface">
          <Alert status="info" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="flex-start" space={2} flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {emptyMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </Box>
      </ScreenWidthAdjuster>
    );
  }

  return noScroll ? (
    <Box paddingTop={padding} paddingBottom={0} paddingX={padding} backgroundColor="inverseOnSurface" {...props}>
      {children}
    </Box>
  ) : (
    <ScreenWidthAdjuster>
      <KeyboardAwareScrollView
        extraScrollHeight={80}
        style={{ backgroundColor: surfaceBrightest }}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <ScrollView
          contentContainerStyle={{ backgroundColor: surfaceBrightest }}
          {...props}
          keyboardShouldPersistTaps="handled"
          nestedScrollEnabled
          padding="xl"
        >
          <Box>{children}</Box>
        </ScrollView>
      </KeyboardAwareScrollView>
    </ScreenWidthAdjuster>
  );
};
