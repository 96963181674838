import React from 'react';

const ArrowUpIcons = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path
          id="keyboard_arrow_up"
          d="M12.0008 10.7999L8.10078 14.6999C7.91745 14.8832 7.68411 14.9749 7.40078 14.9749C7.11745 14.9749 6.88411 14.8832 6.70078 14.6999C6.51745 14.5166 6.42578 14.2832 6.42578 13.9999C6.42578 13.7166 6.51745 13.4832 6.70078 13.2999L11.3008 8.6999C11.5008 8.4999 11.7341 8.3999 12.0008 8.3999C12.2674 8.3999 12.5008 8.4999 12.7008 8.6999L17.3008 13.2999C17.4841 13.4832 17.5758 13.7166 17.5758 13.9999C17.5758 14.2832 17.4841 14.5166 17.3008 14.6999C17.1174 14.8832 16.8841 14.9749 16.6008 14.9749C16.3174 14.9749 16.0841 14.8832 15.9008 14.6999L12.0008 10.7999Z"
          fill="#737373"
        />
      </g>
    </svg>
  );
};

export default ArrowUpIcons;
