import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Box, HStack, Pressable, Text } from '@gluestack-ui/themed-native-base';
import * as Clipboard from 'expo-clipboard';
import { useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useWindowDimensions } from 'react-native';

import { useMessageContext } from 'stream-chat-react';
import { ChatCustomMessage } from '../ChatCustomMessage';
import {
  type Bookmark,
  useGetCurrentUserBookmarksQuery,
  usePostCurrentUserBookmarksMutation,
  useDeleteCurrentUserBookmarksByIdMutation,
} from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import ActionsIcons from '~/icons/ActionsIcons';
import BookmarkIcons from '~/icons/BookmarkIcons';
import CopyIcons from '~/icons/CopyIcons';
import LinkIcons from '~/icons/LinkIcons';
import type { Dispatch, ReactElement, SetStateAction } from 'react';
import type { DefaultStreamChatGenerics, StreamMessage } from 'stream-chat-react';

type ActionProps = {
  message: StreamMessage<DefaultStreamChatGenerics>;
  isRightAlign: boolean;
  isHovered: boolean;
  isActionOpen: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  setIsActionOpen: Dispatch<SetStateAction<boolean>>;
};
type HoverState = {
  [key: string]: boolean; // Allows any string key with a boolean value
};

const ChatCustomParentAction = ({
  message,
  isRightAlign,
  isHovered,
  isActionOpen,
  setIsHovered,
  setIsActionOpen,
}: ActionProps) => {
  const [isMouseHovered, setIsMouseHovered] = useState<HoverState>({});
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [post] = usePostCurrentUserBookmarksMutation();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef<Bookmark[]>();
  const bookmarksList: Bookmark[] = bookmarksRef.current ? bookmarksRef.current : [];
  const bookMarkID = bookmarksList.find((b) => b.message_id === message.id)?.id;
  const { appChannel } = useStreamChatContext();
  const { width: windowWidth } = useWindowDimensions();

  const actionButtonRef = useRef<HTMLDivElement>(null);
  const actionContainerRef = useRef<HTMLDivElement>(null);

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data, message],
    true
  );

  const handleMouseEnter = (id: string) => {
    setIsMouseHovered((prev) => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = (id: string) => {
    setIsMouseHovered((prev) => ({ ...prev, [id]: false }));
  };

  const updateBookMark = () => {
    setIsHovered(false);
    setIsActionOpen(false);
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    } else {
      post({
        body: {
          user_bookmark: {
            message_id: message.id,
            bookmark_type: 'chat-message',
          },
        },
      });
    }
  };

  const copyLink = async () => {
    setIsHovered(false);
    setIsActionOpen(false);
    return Clipboard.setStringAsync(`${U_FEED_URL_BASE}/chat/${appChannel?.id}/${message.id}`);
  };

  const copyText = async () => {
    setIsHovered(false);
    setIsActionOpen(false);
    return Clipboard.setStringAsync(`${message.text}`);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (actionContainerRef.current && !actionContainerRef.current.contains(e.target as Node)) {
      setIsActionOpen(false);
    }
  };

  const actionLeftPosition = useMemo(() => {
    if (!isRightAlign) {
      return 'auto';
    }

    return -200;
  }, [isRightAlign, windowWidth, actionButtonRef.current]);

  const actionRightPosition = useMemo(() => {
    if (isRightAlign) {
      return 'auto';
    }

    return -200;
  }, [isRightAlign, windowWidth, actionButtonRef.current]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  if (!isHovered) return null;

  return (
    <>
      <Box
        ref={actionButtonRef}
        position="absolute"
        left={isRightAlign ? -35 : 'auto'}
        right={isRightAlign ? 'auto' : -35}
        top={0}
      >
        <Pressable
          onPress={() => setIsActionOpen((val) => !val)}
          p="2xs"
          rounded="full"
          _hover={{ backgroundColor: '#f4f4f5' }}
        >
          <ActionsIcons />
        </Pressable>
      </Box>
      {isActionOpen && (
        <Box
          ref={actionContainerRef}
          position="absolute"
          top={0}
          left={actionLeftPosition}
          right={actionRightPosition}
          backgroundColor="onPrimary"
          style={{
            ...gluestackUIConfig.globalStyle.variants.hardShadow[2],
            shadowColor: gluestackUIConfig.tokens.colors.mono700,
            overflow: 'hidden',
          }}
          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
          w={40}
        >
          <Pressable
            onMouseEnter={() => handleMouseEnter('Bookmark')}
            onMouseLeave={() => handleMouseLeave('Bookmark')}
            onPress={updateBookMark}
          >
            <HStack
              backgroundColor={isMouseHovered['Bookmark'] ? 'surface' : 'onPrimary'}
              borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
              borderColor="outline"
              p="xs"
              gap="xs"
            >
              <BookmarkIcons />
              <Text fontSize="md" color="onSurface">
                {bookMarkID ? 'ブックマーク解除' : 'ブックマークする'}
              </Text>
            </HStack>
          </Pressable>
          <Pressable
            onMouseEnter={() => handleMouseEnter('Link')}
            onMouseLeave={() => handleMouseLeave('Link')}
            onPress={copyLink}
          >
            <HStack
              backgroundColor={isMouseHovered['Link'] ? 'surface' : 'onPrimary'}
              borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
              borderColor="outline"
              p="xs"
              gap="xs"
            >
              <LinkIcons />
              <Text fontSize="md" color="onSurface">
                リンクをコピー
              </Text>
            </HStack>
          </Pressable>
          <Pressable
            onMouseEnter={() => handleMouseEnter('Copy')}
            onMouseLeave={() => handleMouseLeave('Copy')}
            onPress={copyText}
          >
            <HStack
              backgroundColor={isMouseHovered['Copy'] ? 'surface' : 'onPrimary'}
              borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
              borderColor="outline"
              p="xs"
              gap="xs"
            >
              <CopyIcons />
              <Text fontSize="md" color="onSurface">
                テキストをコピー
              </Text>
            </HStack>
          </Pressable>
        </Box>
      )}
    </>
  );
};

const ChatCustomParentMessage = (): ReactElement | null => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isActionOpen, setIsActionOpen] = useState<boolean>(false);

  const { message, isMyMessage } = useMessageContext();

  const actionRef = document.getElementById(`parent-action-${message.id}`);
  const containerRef = useRef<HTMLDivElement>(null);

  const isRightAlign = useMemo(isMyMessage, [isMyMessage]);

  const handleOutsideClick = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      setIsHovered(false);
      setIsActionOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  return (
    <Box
      overflow="visible"
      ref={containerRef}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(isActionOpen)}
    >
      <ChatCustomMessage isThreadParent />
      {actionRef &&
        createPortal(
          (
            <ChatCustomParentAction
              message={message}
              isRightAlign={isRightAlign}
              isHovered={isHovered}
              isActionOpen={isActionOpen}
              setIsHovered={setIsHovered}
              setIsActionOpen={setIsActionOpen}
            />
          ) as any,
          actionRef
        )}
    </Box>
  );
};

export default ChatCustomParentMessage;
