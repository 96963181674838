import { VStack, useToken } from '@gluestack-ui/themed-native-base';
import { Switch, Platform } from 'react-native';
import { ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import type { SettingsState } from '~/slices/settingsSlice';
import { settingsSelector, setEnableUDFont, setEnableKanasapo } from '~/slices/settingsSlice';
import { useAppSelector, useAppDispatch } from '~/store';
import type { FC } from 'react';

export const SettingsKanasapoScreen: FC = () => {
  const settings: SettingsState = useAppSelector(settingsSelector);
  const dispatch = useAppDispatch();

  const [primary, surfaceBright, surfaceBrightest, outline] = useToken('colors', [
    'primary',
    'surfaceBright',
    'surfaceBrightest',
    'outline',
  ]);

  return (
    <ScreenWidthModal title="かなサポ">
      <BaseScreenBuilder title="かなサポ">
        <VStack gap="md">
          <ListItem
            title="チャットをやさしい日本語で表示"
            right={
              <Switch
                value={settings.enableKanasapo}
                onValueChange={(value: boolean) => {
                  dispatch(setEnableKanasapo(value));
                }}
                trackColor={{ true: primary, false: outline }}
                ios_backgroundColor={settings.enableKanasapo ? primary : surfaceBrightest}
                thumbColor={settings.enableKanasapo ? surfaceBrightest : surfaceBright}
                {...Platform.select({
                  web: {
                    activeThumbColor: surfaceBrightest,
                  },
                })}
              />
            }
          />
          <ListItem
            title="UDフォントで表示"
            right={
              <Switch
                value={settings.enableUDFont}
                onValueChange={(value: boolean) => {
                  dispatch(setEnableUDFont(value));
                }}
                trackColor={{ true: primary, false: outline }}
                ios_backgroundColor={settings.enableUDFont ? primary : surfaceBrightest}
                thumbColor={settings.enableUDFont ? surfaceBrightest : surfaceBright}
                {...Platform.select({
                  web: {
                    activeThumbColor: surfaceBrightest,
                  },
                })}
              />
            }
          />
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
