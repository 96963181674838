import { useToast, HStack, Button, Text } from '@gluestack-ui/themed-native-base';
import { useRef } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

export const useCustomToast = () => {
  const toast = useToast();
  const toastIdRef = useRef();
  const id = 'custom-toast';

  const show = (description: string, optionString?: string, onOptionPress?: () => void) => {
    if (toast.isActive(id)) {
      return;
    }
    const hasOption = optionString && onOptionPress;
    // @ts-expect-error
    toastIdRef.current = toast.show({
      id,
      render: () => (
        <HStack
          px="sm"
          py={hasOption ? 0 : 'xs'}
          alignItems="center"
          backgroundColor="surface"
          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
        >
          <Text color="onSurfaceBright" fontSize="sm">
            {description}
          </Text>
          {hasOption ? (
            <Button variant="link" onPress={onOptionPress}>
              {optionString}
            </Button>
          ) : null}
        </HStack>
      ),
      placement: 'top',
    });
  };

  const showWithShare = (description: string, onSharePress: () => void) => {
    show(description, 'チャットで共有', onSharePress);
  };

  const showWithClose = (description: string) => {
    show(description, '閉じる', () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    });
  };

  return {
    showWithShare,
    showWithClose,
    show,
  };
};
