import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { ScrollView } from '@gluestack-ui/themed';
import {
  Skeleton,
  Alert,
  Heading,
  Button,
  Box,
  HStack,
  Center,
  Hidden,
  Spacer,
} from '@gluestack-ui/themed-native-base';
import { useDrawerStatus } from '@react-navigation/drawer';
import { useRoute, useNavigation, useLinkTo } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/query/react';
import * as React from 'react';
import { useCallback } from 'react';
import { Platform, RefreshControl } from 'react-native';

import { baseApi } from '~/api/baseQuery';
import { useGetGroupsByGroupIdQuery, useGetGroupsByGroupIdMilkingsStatisticQuery } from '~/api/uFeedApi';
import { ActivityDashboard, ActivityChartSwitcher, TaskCard, ChartDateNavi, SectionTitle } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ChatChannelSummary } from '~/components/ChatChannelSummary';
import { FarmCard } from '~/components/FarmCard';
import { GroupMemberCard } from '~/components/GroupMemberCard';
import { MilkAmountChartSwitcher } from '~/components/MilkAmountChartSwitcher';
import { MilkAmountDashboard } from '~/components/MilkAmountDashboard';
import { useDateByRange } from '~/hooks/useDateByRange';
import { useAppDispatch } from '~/store';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  groupId?: number;
  farmId?: number;
  showEdit?: boolean;
  showTitle?: boolean;
  selected?: boolean;
  renderFab?: boolean;
}

const { useState } = React;

export const GroupDetailScreen: React.FC<Props> = ({
  groupId,
  farmId,
  showEdit = false,
  showTitle = true,
  selected = true,
  renderFab = true,
}) => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const isDrawerOpen = useDrawerStatus() === 'open';
  const dispatch = useAppDispatch();
  const linkTo = useLinkTo();

  const queryParams = React.useMemo(() => {
    return {
      farmId,
      groupId,
      ...params,
    };
    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type 'object'.
  }, [params?.farmId, params?.groupId, farmId, groupId]);

  // @ts-expect-error TS(2345): Argument of type '{ farmId: number | undefined; gr... Remove this comment to see the full error message
  const group = useGetGroupsByGroupIdQuery(queryParams);
  const milkingsStatisticQuery = useGetGroupsByGroupIdMilkingsStatisticQuery(
    queryParams.groupId
      ? {
          groupId: queryParams.groupId,
        }
      : skipToken
  );
  const [chatChannelId, setChatChannelId] = useState(undefined);

  const { beginDate, endDate, range, preEndDate, nextEndDate, setEndDate, setRange } = useDateByRange(
    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{ farmI... Remove this comment to see the full error message
    queryParams.endDate ? DateUtil.dateHourToDate(queryParams.endDate) : new Date(),
    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{ farmId:... Remove this comment to see the full error message
    Number(queryParams.range) || 30
  );

  const [refreshing, setRefreshing] = useState(false);

  const anyFunction = useCallback(async () => {
    setRefreshing(true);
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['Tasks']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['Visits']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupUsers']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['FeedDesigns']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['Additives']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['AppliedFeedDesigns']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupMilkings']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupMilkingsParities']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupMilkingsUMCattleGroups']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupMilkingsStatistic']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupActivities']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['Groups']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupUsers']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['Farms']));
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FullTagDe... Remove this comment to see the full error message
    await dispatch(baseApi.util.invalidateTags(['GroupFeedDesigns']));
    setRefreshing(false);
  }, []);

  useDidUpdate(
    () => {
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      group.data && setChatChannelId(group.data?.chat_channel_id);
    },
    [group],
    true
  );

  const onPressPrev = () => {
    setEndDate(preEndDate);
  };

  const onPressNext = () => {
    setEndDate(nextEndDate);
  };

  const handleTaskCardAddPress = useCallback(() => {
    Platform.OS === 'web'
      ? linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${undefined}/create_edit`)
      : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        navigation.navigate('TaskCreateEdit', {
          ...queryParams,
        });
  }, [navigation, queryParams]);

  if (group.isLoading) {
    return (
      <BaseScreenBuilder>
        <Skeleton />
      </BaseScreenBuilder>
    );
  } else if (group.isError) {
    return (
      <BaseScreenBuilder>
        <Alert colorScheme="error">
          <Alert.Icon />
          {/* @ts-expect-error TS(2571): Object is of type 'unknown'. */}
          {'data' in group.error ? (group.error.data?.error ?? 'エラーが発生しました') : 'エラーが発生しました'}
        </Alert>
      </BaseScreenBuilder>
    );
  }
  if (milkingsStatisticQuery.isLoading) {
    return (
      <BaseScreenBuilder>
        <Skeleton />
      </BaseScreenBuilder>
    );
  } else if (milkingsStatisticQuery.isError) {
    return (
      <BaseScreenBuilder>
        <Alert colorScheme="error">
          <Alert.Icon />
          {/* @ts-expect-error TS(2339): Property 'data' does not exist on type 'FetchBaseQ... Remove this comment to see the full error message */}
          {milkingsStatisticQuery.error.data.error}
        </Alert>
      </BaseScreenBuilder>
    );
  }
  return (
    <ScrollView
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={anyFunction} />}
      contentContainerStyle={{ paddingTop: 16, paddingHorizontal: 16, backgroundColor: '#f8f9fb' }}
    >
      <Center>
        <Box width="full" maxWidth={1440}>
          <>
            {showTitle ? (
              <Heading fontSize="xl" margin={4}>
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                {group.data.name}
              </Heading>
            ) : null}
          </>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {group.data?.details?.length > 0 && (
            <Box backgroundColor="white" rounded="xl" marginBottom={8}>
              <SectionTitle
                title="活動量の変化"
                paddingX={{ base: 4, md: 10 }}
                paddingY={{ base: 4, md: 6 }}
                backgroundColor="transparent"
              />
              <ActivityDashboard
                chatChannelId={chatChannelId}
                farmId={queryParams.farmId}
                groupId={queryParams.groupId}
                endDate={endDate}
                range={range}
                paddingX={{ base: 4, md: 10 }}
                paddingTop={0}
              />
            </Box>
          )}
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {milkingsStatisticQuery.data?.has_milking_data && group.data?.details?.length > 0 && (
            <Box backgroundColor="white" rounded="xl" marginBottom={8}>
              <SectionTitle
                title="搾乳量の変化"
                paddingX={{ base: 4, md: 10 }}
                paddingY={{ base: 4, md: 6 }}
                backgroundColor="transparent"
              />
              <MilkAmountDashboard
                chatChannelId={chatChannelId}
                farmId={queryParams.farmId}
                groupId={queryParams?.groupId}
                endDate={endDate}
                range={range}
                paddingX={{ base: 4, md: 10 }}
                paddingTop={0}
              />
            </Box>
          )}
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {group.data?.details?.length > 0 && (
            <Box backgroundColor="white" rounded="xl" marginBottom={8}>
              <SectionTitle
                title="分析グラフ"
                paddingX={{ base: 4, md: 10 }}
                paddingY={{ base: 4, md: 6 }}
                backgroundColor="transparent"
              />
              <ChartDateNavi
                beginDate={beginDate}
                endDate={endDate}
                onPressPrev={onPressPrev}
                onPressNext={onPressNext}
                // @ts-expect-error
                paddingX={{ base: 4, md: 10 }}
                paddingTop={2}
                paddingBottom={8}
                range={range}
                onRangeChange={(newRange) => setRange(newRange)}
              />
              <ActivityChartSwitcher
                chatChannelId={chatChannelId}
                farmId={queryParams.farmId}
                groupId={queryParams.groupId}
                endDate={endDate}
                range={range}
                paddingX={{ base: 4, md: 10 }}
                // @ts-expect-error TS(2339): Property 'type' does not exist on type '{ farmId: ... Remove this comment to see the full error message
                type={queryParams.type ? queryParams.type : 'line'}
                marginBottom={{ base: 0, md: 16 }}
              />
              <Hidden from="md">
                <Box my={8} w="full" h={1.5} backgroundColor="gray.50" />
              </Hidden>
              <MilkAmountChartSwitcher
                chatChannelId={chatChannelId}
                farmId={queryParams.farmId}
                groupId={queryParams.groupId}
                endDate={endDate}
                range={range}
                // @ts-expect-error TS(2339): Property 'type' does not exist on type '{ farmId: ... Remove this comment to see the full error message
                type={queryParams.type ? queryParams.type : 'group'}
                paddingX={{ base: 4, md: 10 }}
                paddingBottom={8}
              />
            </Box>
          )}
          <Box backgroundColor="white" rounded="xl" marginBottom={8} paddingBottom={6}>
            <HStack
              paddingX={{ base: 4, md: 10 }}
              paddingTop={{ base: 4, md: 6 }}
              paddingBottom={4}
              alignItems="center"
              justifyContent="space-between"
            >
              <Heading fontSize="xl">タスク</Heading>
              <Button
                variant="unstyled"
                onPress={() => {
                  Platform.OS === 'web'
                    ? linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks`)
                    : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate('TaskList', {
                        farmId: queryParams.farmId,
                        groupId: queryParams.groupId,
                      });
                }}
                size="lg"
                padding={0}
                leftIcon={<Ionicons name="list" color="black" size={16} />}
              >
                すべてのタスクを見る
              </Button>
            </HStack>
            <TaskCard
              // @ts-expect-error
              farmId={queryParams.farmId}
              // @ts-expect-error
              groupId={queryParams.groupId}
              onAddPress={handleTaskCardAddPress}
              paddingX={{ base: 4, md: 10 }}
            />
          </Box>
          <Box backgroundColor="white" rounded="xl" marginBottom={8}>
            <SectionTitle
              title="チャット"
              paddingX={{ base: 4, md: 10 }}
              paddingY={{ base: 4, md: 6 }}
              backgroundColor="transparent"
            />
            <ChatChannelSummary
              paddingX={{ base: 4, md: 10 }}
              paddingBottom={{ base: 4, md: 6 }}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              groupId={queryParams?.groupId}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              farmId={queryParams.farmId}
            />
          </Box>
          <Box backgroundColor="white" rounded="xl" marginBottom={8}>
            <SectionTitle
              title="牧場情報"
              paddingX={{ base: 4, md: 10 }}
              paddingY={{ base: 4, md: 6 }}
              backgroundColor="transparent"
            />
            {/* @ts-expect-error */}
            <FarmCard farmId={queryParams.farmId} paddingX={{ base: 4, md: 10 }} />
          </Box>
          <Box backgroundColor="white" rounded="xl" marginBottom={8}>
            <SectionTitle
              title="グループのメンバー"
              paddingX={{ base: 4, md: 10 }}
              paddingY={{ base: 4, md: 6 }}
              backgroundColor="transparent"
            />
            {/* @ts-expect-error */}
            <GroupMemberCard groupId={queryParams?.groupId} paddingX={{ base: 4, md: 10 }} />
          </Box>
        </Box>
      </Center>
      <Spacer height={200} />
    </ScrollView>
  );
};
