import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const PenEditIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <mask id="mask0_1516_14690" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill={gluestackUIConfig.tokens.colors.outline} />
      </mask>
      <g mask="url(#mask0_1516_14690)">
        <path
          d="M4.69963 20.9749C4.34963 21.0582 4.04546 20.9707 3.78713 20.7124C3.5288 20.4541 3.4413 20.1499 3.52463 19.7999L4.39963 15.5499L8.94963 20.0999L4.69963 20.9749ZM10.5746 18.8749L5.62463 13.9249L15.9496 3.5999C16.333 3.21657 16.808 3.0249 17.3746 3.0249C17.9413 3.0249 18.4163 3.21657 18.7996 3.5999L20.8996 5.6999C21.283 6.08324 21.4746 6.55824 21.4746 7.1249C21.4746 7.69157 21.283 8.16657 20.8996 8.5499L10.5746 18.8749Z"
          fill={gluestackUIConfig.tokens.colors.onSurfaceBright}
        />
      </g>
    </svg>
  );
};

export default PenEditIcons;
