import { useMemo } from 'react';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { AvatarWithDM, AvatarWithOnline, AvatarWithRoom } from './AvatarWithOnline';
import type { ChannelAvatarProps } from 'stream-chat-expo';

export const ChatCustomChannelAvatar: React.FC<
  ChannelAvatarProps & { size?: string | number; iconSize?: string | number }
> = ({ channel, size = 'xs', iconSize = '4xl' }) => {
  const { chatUserId } = useStreamChatContext();

  const user = useMemo(() => {
    const messages = channel?.state.messages;
    const lastMessage = messages.length ? messages[messages.length - 1] : undefined;
    const userId = channel?.data?.is_my_channel ? chatUserId : lastMessage?.user?.id;
    return {
      id: userId,
      name: channel.state.members[userId || '']?.user?.name,
    };
  }, [channel, channel?.state.messages, chatUserId]);

  if (!channel?.data?.isDM) {
    return <AvatarWithRoom userId={user?.id || ''} name={user?.name || ''} size={size} iconSize={iconSize} />;
  } else {
    if (Object.keys(channel?.state?.members).length > 2) {
      return <AvatarWithDM channel={channel || ''} />;
    } else {
      const userId = Object.keys(channel?.state?.members).filter((key) => key !== chatUserId);
      return (
        <AvatarWithOnline
          channel={channel || ''}
          userId={(userId.length === 1 && userId[0]) || ''}
          name={user?.name || ''}
          size={size}
          iconSize={iconSize}
        />
      );
    }
  }
};
