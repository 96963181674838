import emojiData, { EmojiMartData } from '@emoji-mart/data';
import { Ionicons } from '@expo/vector-icons';
import { HStack, Box, VStack, Image, Icon, Text } from '@gluestack-ui/themed-native-base';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { CustomMessageText } from '~/components';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { DateUtil } from '~/utils/DateUtils';
import { customEmojis } from '~/utils/emoji';
import type { NotificationInfo } from './types';
import type { ReactionResponse } from 'stream-chat';

type ReactionType = { type: string; count: number; isOwnReactioned: boolean };

type Props = NotificationInfo & {
  handleMessagePress: (arg: {
    id: number;
    sourceChannelId?: string;
    sourceChannelType?: string;
    sourceMessageId?: string;
    sourceLink: string;
    isDM: boolean;
  }) => void;
};

const UnMemoizedNotificationItem = ({
  id,
  notifiedAt,
  type,
  notifiedUser,
  notificationBody,
  isRead,
  isDM,
  sourceLink,
  streamMessageObj,
  handleMessagePress,
}: Props) => {
  const { chatClient, isUserConnected } = useStreamChatContext();

  let emoji, foundEmoji;
  const [userName, setUserName] = useState<string | undefined>(notifiedUser.name);
  const [userAvatar, setUserAvatar] = useState<string | undefined>(notifiedUser.avatar);
  const [allReactionList, setAllReactionList] = useState<ReactionResponse[]>(streamMessageObj?.latest_reactions || []);

  const onPress = useCallback(() => {
    handleMessagePress({
      id,
      sourceChannelId: streamMessageObj?.channel?.id,
      sourceChannelType: streamMessageObj?.channel?.type,
      sourceMessageId: streamMessageObj?.id,
      sourceLink: sourceLink || '',
      isDM: !!isDM,
    });
  }, [id, streamMessageObj, sourceLink, isDM, handleMessagePress]);

  const getReactNode = (reaction: string) => {
    const foundEmoji2 = reaction in customEmojis ? customEmojis[reaction as keyof typeof customEmojis].image : null;
    if (foundEmoji2) {
      return <Image source={foundEmoji2} alt={reaction} width={5} height={5} />;
    } else {
      // @ts-expect-error TS(2339): Property 'emojis' does not exist on type 'typeof i... Remove this comment to see the full error message
      return <Text fontSize={20}>{emojiData.emojis[reaction]?.skins[0]?.native}</Text>;
    }
  };

  const ownReactionTypeList = useMemo(() => {
    if (streamMessageObj?.own_reactions) {
      return streamMessageObj?.own_reactions.map((reaction) => reaction.type);
    }
    return [];
  }, [streamMessageObj?.own_reactions]);

  const reactionTypeList = useMemo(() => {
    const reactionTypeListCandidate: ReactionType[] = [];

    allReactionList
      .filter((reaction) => new Date(reaction.created_at).getTime() <= new Date(notifiedAt).getTime())
      .forEach((reaction) => {
        const index = reactionTypeListCandidate.findIndex((item) => item.type === reaction.type);
        if (index === -1) {
          reactionTypeListCandidate.push({
            type: reaction.type,
            count: 1,
            isOwnReactioned: !!ownReactionTypeList.includes(reaction.type),
          });
          return;
        }
        reactionTypeListCandidate[index].count++;
      });

    return reactionTypeListCandidate;
  }, [allReactionList]);

  let reactionNode;
  if (type === 'reaction' && notificationBody) {
    emoji = notificationBody;
    foundEmoji = emoji in customEmojis ? customEmojis[emoji as keyof typeof customEmojis].image : null;
    if (foundEmoji) {
      reactionNode = <Image source={foundEmoji} alt={emoji} width={6} height={6} />;
    } else {
      reactionNode = <Text fontSize={24}>{(emojiData as EmojiMartData).emojis[emoji]?.skins[0]?.native}</Text>;
    }
  }

  useEffect(() => {
    if (type === 'chat' && notifiedUser.name == null && streamMessageObj?.id) {
      chatClient.getMessage(streamMessageObj?.id).then((res) => {
        if (res?.message?.latest_reactions) {
          setAllReactionList([...res?.message?.latest_reactions].reverse());
        }
        if (res.message?.user?.name) {
          setUserName(res.message.user.name);
        }
        if (res.message?.user?.image) {
          setUserAvatar(res.message.user.image);
        }
      });
    }
  }, [chatClient, isUserConnected]);

  return (
    <HStack alignItems="center">
      <TouchableOpacity onPress={onPress} style={{ flex: 1 }}>
        <VStack gap="xs" py="xs" px="sm" style={{ flex: 1 }}>
          <HStack gap="xs">
            <Text fontWeight="regular" color="onSurfaceBright" fontSize="sm">
              {streamMessageObj?.channel?.name}
            </Text>
          </HStack>
          <HStack flex={1}>
            {type === 'reaction' ? (
              <Box width={9} height={9} alignItems="center" justifyContent="center">
                {reactionNode}
              </Box>
            ) : (
              <>
                {userAvatar ? (
                  <Image source={{ uri: userAvatar }} size="xs" borderRadius="full" alt="" width={9} height={9} />
                ) : (
                  <Icon as={Ionicons} name="person-circle-outline" size="3xl" />
                )}
              </>
            )}
            <VStack flex={1} overflow="hidden" marginLeft="xs">
              <HStack alignItems="center" justifyContent="space-between">
                <Text fontSize="md" fontWeight="bold" color="onSurface">
                  {userName}
                </Text>
                <Text color="onSurfaceBright" fontSize="sm" fontWeight="regular">
                  {DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(notifiedAt))} {DateUtil.tohmm(new Date(notifiedAt))}
                </Text>
              </HStack>
              {reactionNode ? (
                <CustomMessageText message={{ text: streamMessageObj?.text }} />
              ) : (
                <CustomMessageText message={{ text: notificationBody }} />
              )}
              <HStack justifyContent="flex-start" flexWrap="wrap" mt="2xs">
                {reactionTypeList.map((item) => {
                  return (
                    <HStack key={item.type} px={1} py={1}>
                      <HStack
                        alignItems="center"
                        px="xs"
                        py="2xs"
                        borderRadius={gluestackUIConfig.tokens.radii.full}
                        borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
                        gap="2xs"
                        mb="3xs"
                        backgroundColor={item.isOwnReactioned ? gluestackUIConfig.tokens.colors.orange300 : 'surface'}
                        borderColor={item.isOwnReactioned ? 'primary' : 'surface'}
                      >
                        {getReactNode(item.type)}
                        <Text fontSize="sm" color="onSurfaceBright">
                          {item.count}
                        </Text>
                      </HStack>
                    </HStack>
                  );
                })}
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </TouchableOpacity>
      {!isRead && type !== 'reaction' && (
        <Box backgroundColor="amber.100" borderRadius={10} padding={1}>
          <Text color="black" fontSize="xs">
            未読
          </Text>
        </Box>
      )}
    </HStack>
  );
};

export const NotificationItem = memo(UnMemoizedNotificationItem) as typeof UnMemoizedNotificationItem;
