import { Image, Pressable, ScrollView, Text, View, VStack } from '@gluestack-ui/themed-native-base';
// @ts-expect-error
import calendar from '../../../u-feed-app/assets/app_icons/calendar.png';
// @ts-expect-error
import cow from '../../../u-feed-app/assets/app_icons/cow.png';
// @ts-expect-error
import email from '../../../u-feed-app/assets/app_icons/email.png';
// @ts-expect-error
import link from '../../../u-feed-app/assets/app_icons/link.png';
// @ts-expect-error
import recycle from '../../../u-feed-app/assets/app_icons/recycle.png';
// @ts-expect-error
import tree from '../../../u-feed-app/assets/app_icons/tree.png';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

interface ChatBotQuickActionsProps {
  onSendMessage: (messageText: string) => Promise<void>;
}

export const ChatBotQuickActions: React.FC<ChatBotQuickActionsProps> = ({ onSendMessage }) => {
  return (
    <VStack
      position="absolute"
      backgroundColor="surfaceBrightest"
      alignItems="center"
      padding="5xl"
      space="sm"
      height="100%"
      width="100%"
    >
      <View
        backgroundColor="surfaceBrightest"
        width={20}
        height={20}
        marginTop="5xl"
        borderRadius={gluestackUIConfig.tokens.radii.full}
        borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
        style={{
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.12,
          shadowRadius: 5,
          elevation: 5,
        }}
      >
        <Image source={require('../../assets/app_icons/app_adaptive_icon_chumly.png')} alt="logo" />
      </View>
      <Text fontSize="xl" color="primary" textAlign="center" bold lineHeight="xl">
        こんにちは！{`\n`} お困りのことを質問してください
      </Text>
      <ScrollView padding="xs">
        <View
          backgroundColor="surfaceBrightest"
          padding="xs"
          horizontal
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
          maxWidth={600}
        >
          <Pressable
            onPress={() => onSendMessage('牛個体情報を登録したい')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={cow} />
            <Text color="onSurface" fontSize="md">
              牛個体情報を登録したい
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('U-motionにイベントを登録する')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={calendar} />
            <Text color="onSurface" fontSize="md">
              U-motionにイベントを登録する
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('牛個体情報とセンサー番号を紐づけたい')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={link} />
            <Text color="onSurface" fontSize="md">
              牛個体情報とセンサー番号を紐づけたい
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('牛個体情報の所属牛群を変更したい')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={recycle} />
            <Text color="onSurface" fontSize="md">
              牛個体情報の所属牛群を変更したい
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('DMの使い方')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={email} />
            <Text color="onSurface" fontSize="md">
              DMの使い方
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('質問例テキスト')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={tree} />
            <Text color="onSurface" fontSize="md">
              質問例テキスト
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('質問例テキスト')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={tree} />
            <Text color="onSurface" fontSize="md">
              質問例テキスト
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('質問例テキスト')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={tree} />
            <Text color="onSurface" fontSize="md">
              質問例テキスト
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('質問例テキスト')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={tree} />
            <Text color="onSurface" fontSize="md">
              質問例テキスト
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('その他')}
            backgroundColor="surfaceBrightest"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
              <path
                d="M7.0638 16.3923C6.42214 16.3923 5.87283 16.1638 5.41589 15.7068C4.95894 15.2499 4.73047 14.7006 4.73047 14.0589C4.73047 13.4173 4.95894 12.8679 5.41589 12.411C5.87283 11.9541 6.42214 11.7256 7.0638 11.7256C7.70547 11.7256 8.25477 11.9541 8.71172 12.411C9.16866 12.8679 9.39714 13.4173 9.39714 14.0589C9.39714 14.7006 9.16866 15.2499 8.71172 15.7068C8.25477 16.1638 7.70547 16.3923 7.0638 16.3923ZM14.0638 16.3923C13.4221 16.3923 12.8728 16.1638 12.4159 15.7068C11.9589 15.2499 11.7305 14.7006 11.7305 14.0589C11.7305 13.4173 11.9589 12.8679 12.4159 12.411C12.8728 11.9541 13.4221 11.7256 14.0638 11.7256C14.7055 11.7256 15.2548 11.9541 15.7117 12.411C16.1687 12.8679 16.3971 13.4173 16.3971 14.0589C16.3971 14.7006 16.1687 15.2499 15.7117 15.7068C15.2548 16.1638 14.7055 16.3923 14.0638 16.3923ZM21.0638 16.3923C20.4221 16.3923 19.8728 16.1638 19.4159 15.7068C18.9589 15.2499 18.7305 14.7006 18.7305 14.0589C18.7305 13.4173 18.9589 12.8679 19.4159 12.411C19.8728 11.9541 20.4221 11.7256 21.0638 11.7256C21.7055 11.7256 22.2548 11.9541 22.7117 12.411C23.1687 12.8679 23.3971 13.4173 23.3971 14.0589C23.3971 14.7006 23.1687 15.2499 22.7117 15.7068C22.2548 16.1638 21.7055 16.3923 21.0638 16.3923Z"
                fill="#737373"
              />
            </svg>
            <Text color="onSurface" fontSize="md">
              その他
            </Text>
          </Pressable>
        </View>
      </ScrollView>
    </VStack>
  );
};
