import React from 'react';

const ImageAlbumIcons = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="image">
        <mask id="mask0_1279_2318" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1279_2318)">
          <path
            id="image_2"
            d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333ZM5.83333 14.1667H14.1667C14.3333 14.1667 14.4583 14.0903 14.5417 13.9375C14.625 13.7847 14.6111 13.6389 14.5 13.5L12.2083 10.4375C12.125 10.3264 12.0139 10.2708 11.875 10.2708C11.7361 10.2708 11.625 10.3264 11.5417 10.4375L9.375 13.3333L7.83333 11.2708C7.75 11.1597 7.63889 11.1042 7.5 11.1042C7.36111 11.1042 7.25 11.1597 7.16667 11.2708L5.5 13.5C5.38889 13.6389 5.375 13.7847 5.45833 13.9375C5.54167 14.0903 5.66667 14.1667 5.83333 14.1667Z"
            fill="#737373"
          />
        </g>
      </g>
    </svg>
  );
};

export default ImageAlbumIcons;
