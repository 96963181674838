import { Foundation } from '@expo/vector-icons';
import { HStack, VStack, Pressable, Text, Button, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useDeleteCurrentUserMutation, useGetCurrentUserQuery, usePutUsersByIdMutation } from '~/api/uFeedApi';
import { Alert } from '~/components';
import type { FieldsProps } from '~/components/builder';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useAuth } from '~/hooks';
import ArrowRightIcon from '~/icons/arrow_right.svg';
import type { SettingsStackParamList } from '~/routes/type';
import type { NavigationProp } from '@react-navigation/native';
import type { FC } from 'react';

export const SettingsAccountScreen: FC = () => {
  const navigation = useNavigation<NavigationProp<SettingsStackParamList>>();
  const { isUmotionUser, handleLogout } = useAuth();
  const [primary] = useToken('colors', ['primary']);
  const queryResult = useGetCurrentUserQuery({});
  const [update] = usePutUsersByIdMutation();
  const [deleteCurrentUser, { isLoading: isDeletingCurrentUser, isSuccess: isDeleteCurrentUserSuccess }] =
    useDeleteCurrentUserMutation();

  const onSubmitEmail = useCallback(
    async (sendingData: Record<string, string>) => {
      const formData = new FormData();
      for (const key in sendingData) {
        formData.append(`user[${key}]`, sendingData[key]);
      }

      return update({
        id: queryResult.data?.id ?? 0,
        // @ts-expect-error TS(2741): Property 'user' is missing in type 'FormData' but ... Remove this comment to see the full error message
        body: formData,
      })
        .unwrap()
        .then(navigation.goBack);
    },
    [queryResult.data?.id]
  );

  const onSubmitPassword = useCallback(
    async (sendingData: Record<string, string>) => {
      const { password } = sendingData;
      return update({
        id: queryResult.data?.id ?? 0,
        body: {
          user: {
            password,
          },
        },
      })
        .unwrap()
        .then(navigation.goBack);
    },
    [queryResult.data?.id]
  );

  const onDeleteCurrentUserPress = useCallback(async () => {
    Alert.alert(
      'Chumlyを退会',
      'アカウントを削除します。作成済みのメッセージやタスクは「削除済みユーザーのデータ」として残ります。',
      [
        { text: 'キャンセル' },
        {
          text: '確認',
          onPress: () => {
            deleteCurrentUser().then(() => {
              handleLogout();
            });
          },
        },
      ]
    );
  }, [deleteCurrentUser, handleLogout]);

  return (
    <ScreenWidthModal title="アカウント">
      <BaseScreenBuilder title="アカウント">
        <VStack gap="md">
          <Pressable
            disabled={isUmotionUser}
            onPress={() => {
              const fields: FieldsProps = [
                {
                  type: 'email',
                  key: 'email',
                  label: 'メールアドレス',
                  rules: {
                    required: {
                      value: true,
                      message: 'メールアドレスを入力してください',
                    },
                    pattern: {
                      value:
                        /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
                      message: 'メールアドレスの形式に誤りがあります',
                    },
                  },
                },
              ];

              navigation.navigate('SettingsForm', {
                formBuilderProps: {
                  // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
                  queryResult,
                  fields,
                  onCancel: navigation.goBack,
                  onSubmit: onSubmitEmail,
                },
              });
            }}
          >
            <HStack alignItems="center" justifyContent="space-between">
              <VStack>
                <Text color="onSurface" fontSize="sm" lineHeight="sm">
                  メールアドレス
                </Text>
                <Text color="onSurface" fontSize="md">
                  {queryResult?.data?.email}
                </Text>
              </VStack>
              {!isUmotionUser && (
                <>
                  {Platform.OS === 'web' ? (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Icons">
                        <path
                          id="keyboard_arrow_right"
                          d="M10.8083 9.70443L7.55827 6.45443C7.40549 6.30165 7.3291 6.1072 7.3291 5.87109C7.3291 5.63498 7.40549 5.44054 7.55827 5.28776C7.71105 5.13498 7.90549 5.05859 8.1416 5.05859C8.37771 5.05859 8.57216 5.13498 8.72494 5.28776L12.5583 9.12109C12.6416 9.20443 12.7006 9.2947 12.7354 9.39193C12.7701 9.48915 12.7874 9.59332 12.7874 9.70443C12.7874 9.81554 12.7701 9.9197 12.7354 10.0169C12.7006 10.1141 12.6416 10.2044 12.5583 10.2878L8.72494 14.1211C8.57216 14.2739 8.37771 14.3503 8.1416 14.3503C7.90549 14.3503 7.71105 14.2739 7.55827 14.1211C7.40549 13.9683 7.3291 13.7739 7.3291 13.5378C7.3291 13.3016 7.40549 13.1072 7.55827 12.9544L10.8083 9.70443Z"
                          fill="#737373"
                        />
                      </g>
                    </svg>
                  ) : (
                    <ArrowRightIcon />
                  )}
                </>
              )}
            </HStack>
          </Pressable>
          <Pressable
            disabled={isUmotionUser}
            onPress={() => {
              const fields: FieldsProps = [
                {
                  key: 'password',
                  label: 'パスワード',
                  type: 'password',
                  rules: {
                    required: {
                      value: true,
                      message: 'パスワードを入力してください',
                    },
                    minLength: {
                      value: 8,
                      message: '8文字以上入力してください',
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/,
                      message: 'パスワードは半角英数・記号のみで入力してください',
                    },
                  },
                },
              ];

              navigation.navigate('SettingsForm', {
                formBuilderProps: {
                  // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
                  queryResult,
                  fields,
                  onCancel: navigation.goBack,
                  onSubmit: onSubmitPassword,
                },
              });
            }}
          >
            <HStack alignItems="center" justifyContent="space-between">
              <VStack>
                <Text color="onSurface" fontSize="sm" lineHeight="sm">
                  パスワード
                </Text>
                <Text color="onSurface" fontSize="md">
                  ********
                </Text>
              </VStack>
              {!isUmotionUser && (
                <>
                  {Platform.OS === 'web' ? (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Icons">
                        <path
                          id="keyboard_arrow_right"
                          d="M10.8083 9.70443L7.55827 6.45443C7.40549 6.30165 7.3291 6.1072 7.3291 5.87109C7.3291 5.63498 7.40549 5.44054 7.55827 5.28776C7.71105 5.13498 7.90549 5.05859 8.1416 5.05859C8.37771 5.05859 8.57216 5.13498 8.72494 5.28776L12.5583 9.12109C12.6416 9.20443 12.7006 9.2947 12.7354 9.39193C12.7701 9.48915 12.7874 9.59332 12.7874 9.70443C12.7874 9.81554 12.7701 9.9197 12.7354 10.0169C12.7006 10.1141 12.6416 10.2044 12.5583 10.2878L8.72494 14.1211C8.57216 14.2739 8.37771 14.3503 8.1416 14.3503C7.90549 14.3503 7.71105 14.2739 7.55827 14.1211C7.40549 13.9683 7.3291 13.7739 7.3291 13.5378C7.3291 13.3016 7.40549 13.1072 7.55827 12.9544L10.8083 9.70443Z"
                          fill="#737373"
                        />
                      </g>
                    </svg>
                  ) : (
                    <ArrowRightIcon />
                  )}
                </>
              )}
            </HStack>
          </Pressable>
          {isUmotionUser ? (
            <VStack backgroundColor="surface" p="sm" rounded="md">
              <HStack gap="xs" alignItems="center" flex={1} w="100%" pr="md">
                <Foundation name="info" size={20} color={primary} />
                <Text color="onSurface" bold>
                  U-motion IDでログイン中:
                </Text>
              </HStack>
              <Text color="onSurface" lineHeight="xs">
                メールアドレスとパスワードは、U-motionのユーザー管理画面で変更できます
              </Text>
            </VStack>
          ) : (
            <VStack gap="md">
              <Button
                onPress={onDeleteCurrentUserPress}
                isLoading={isDeletingCurrentUser}
                isDisabled={isDeleteCurrentUserSuccess || isDeletingCurrentUser}
                rounded="md"
                backgroundColor="negative"
                _text={{
                  color: 'onNegative',
                  fontWeight: 'bold',
                }}
              >
                Chumlyを退会する
              </Button>
              <VStack backgroundColor="surface" p="sm" rounded="md">
                <HStack gap="xs" alignItems="center" flex={1} w="100%" pr="md">
                  <Foundation name="info" size={20} color={primary} />
                  <Text color="onSurface" lineHeight="xs">
                    アカウントを削除します。作成済みのメッセージやタスクは「削除済みユーザーのデータ」として残ります。
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          )}
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
