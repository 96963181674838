import React from 'react';

const ReplyIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20.0008 18.9999C19.7174 18.9999 19.4801 18.9039 19.2888 18.7119C19.0968 18.5206 19.0008 18.2832 19.0008 17.9999V14.9999C19.0008 14.1666 18.7091 13.4582 18.1258 12.8749C17.5424 12.2916 16.8341 11.9999 16.0008 11.9999H6.80078L9.72578 14.9249C9.90911 15.1082 10.0008 15.3332 10.0008 15.5999C10.0008 15.8666 9.90078 16.0999 9.70078 16.2999C9.51745 16.4832 9.28411 16.5749 9.00078 16.5749C8.71745 16.5749 8.48411 16.4832 8.30078 16.2999L3.70078 11.6999C3.60078 11.5999 3.53011 11.4916 3.48878 11.3749C3.44678 11.2582 3.42578 11.1332 3.42578 10.9999C3.42578 10.8666 3.44678 10.7416 3.48878 10.6249C3.53011 10.5082 3.60078 10.3999 3.70078 10.2999L8.32578 5.6749C8.50911 5.49157 8.73411 5.3999 9.00078 5.3999C9.26745 5.3999 9.50078 5.4999 9.70078 5.6999C9.88411 5.88324 9.97578 6.11657 9.97578 6.3999C9.97578 6.68324 9.88411 6.91657 9.70078 7.0999L6.80078 9.9999H16.0008C17.3841 9.9999 18.5634 10.4872 19.5388 11.4619C20.5134 12.4372 21.0008 13.6166 21.0008 14.9999V17.9999C21.0008 18.2832 20.9048 18.5206 20.7128 18.7119C20.5214 18.9039 20.2841 18.9999 20.0008 18.9999Z"
        fill="#737373"
      />
    </svg>
  );
};

export default ReplyIcons;
