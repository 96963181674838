import { baseApi } from '../uMotionBaseQuery';

export interface Farm {
  id?: number;
  agentFarm: boolean;
  alertDysstasia: boolean;
  alertLocation: boolean;
  allowDuplicationCowNo: boolean;
  autoMovement: boolean;
  birthStates: string;
  bulls: string;
  buyers: string;
  calfManagement: 'always' | 'never' | 'calved';
  cattleSperms: string;
  certificateInseminator: {
    name: string;
    address: string;
    licencedNumber: string;
    licencedPrefecture: string;
  };
  contractedPlan: 'full' | 'light';
  cowNoMatchingPattern: 'complete' | 'prefix' | 'fuzzy';
  cowNoPattern: 'number' | 'alphanumeric';
  depositorFarm: boolean;
  dhiLinkage: 'none' | 'both' | 'hmrt' | 'liaj';
  farmAddress: string;
  farmCode: string;
  farmKind: '乳用' | '肉用';
  farmName: string;
  farmPhone: string;
  farmTypeBreeding: boolean;
  farmTypeDairy: boolean;
  farmTypeFattening: boolean;
  fatteningStageBaseDate: 'birthday' | 'start_fattening_date';
  faxNumber: string;
  feedThresholdRate: string;
  fiscalYearStartMonth: number;
  flowMeterType: string;
  genomLinkage: 'none' | 'beef';
  groupRole: '' | 'organizer' | 'member';
  heatAlertTargetStates: string;
  identificationType: 'cow_no' | 'regulation_number_4' | 'regulation_number_5' | 'name';
  inseminators: string;
  introduceStates: string;
  judgePregnantTimings: string;
  lieThresholdRate: string;
  managementStyle: 'standard' | 'depositor' | 'agent';
  milkThresholdRate: string;
  otherReproductionWorks: string;
  physicalExaminers: string;
  pregnancyAppraisers: string;
  producingAreas: string;
  producingFarmNames: string;
  raisingFarmNames: string;
  settingLabels: string;
  showHeatIndexFattening: boolean;
  startDate?: string;
  thresholdActivityRate1: string;
  thresholdActivityRate2: string;
  thresholdActivityRate3: string;
  thresholdActivityRate4: string;
  thresholdActivityRate5: string;
  transporters: string;
  treatmentPersons: string;
  useAlertCalving: boolean;
  useAlertSensor: boolean;
  useCalfManagement: boolean;
  useLegacyReportMenu: boolean;
  useReproductionCowGroupSummary: boolean;
  useTemporaryGroupView: boolean;
  useUClips: boolean;
  vaccineTimings: string;
  washingWater2: string;
  washingWater3: string;
  washingWater4: string;
  washingWater5: string;
  waterThresholdRate: string;
  managementStyleLabel?: string;

  // テーブル定義では必須項目だが参照しているテストコードの修正を避けるために「?」指定にします
  fatteningStartMonthAge?: number;
  stopShipmentDisplay?: string;
  stopShipmentDisplayPeriod?: number;
}

const CACHE_TAG_TYPE = 'Farm';
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [CACHE_TAG_TYPE, 'FatteningStage'] });

const mutationResponseHandler = (resp) => {
  if (resp.ok === true) {
    return Promise.resolve({});
  } else {
    return Promise.reject(`ERROR: ${resp.url}`);
  }
};

export const farmApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchFarm: builder.query<[], void>({
      query: (response) => ({
        url: '/farm',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchFarmCurrent: builder.query<Farm, void>({
      query: (response) => ({
        url: '/farm/current',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE, 'FatteningStage'],
    }),
    createFarm: builder.mutation<Farm, Partial<Farm> & Pick<Farm, 'farmName'>>({
      query: (newFarm) => ({
        url: '/farm',
        method: 'POST',
        body: newFarm,
      }),
      invalidatesTags: [CACHE_TAG_TYPE],
    }),
    updateFarm: builder.mutation<Farm, Partial<Farm> & Pick<Farm, 'farmName'>>({
      query: (newFarm) => ({
        url: '/farm',
        method: 'PUT',
        body: newFarm,
        responseHandler: mutationResponseHandler,
      }),
      invalidatesTags: [CACHE_TAG_TYPE],
    }),
    deleteFarm: builder.mutation<Farm, number>({
      query: (farmId) => {
        return {
          url: `/farm/${farmId}`,
          method: 'DELETE',
          responseHandler: mutationResponseHandler,
        };
      },
      invalidatesTags: [CACHE_TAG_TYPE],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchFarmQuery,
  useFetchFarmCurrentQuery,
  useCreateFarmMutation,
  useDeleteFarmMutation,
  useUpdateFarmMutation,
} = farmApi;
