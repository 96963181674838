import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const InfoIcons = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="info">
        <mask id="mask0_1279_5246" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect id="Bounding box" width="24" height="24" fill={gluestackUIConfig.tokens.colors.outline} />
        </mask>
        <g mask="url(#mask0_1279_5246)">
          <path
            id="info_2"
            d="M10.0003 14.1667C10.2364 14.1667 10.4344 14.0868 10.5941 13.9271C10.7538 13.7674 10.8337 13.5695 10.8337 13.3334V10C10.8337 9.76391 10.7538 9.56599 10.5941 9.40627C10.4344 9.24655 10.2364 9.16669 10.0003 9.16669C9.76421 9.16669 9.5663 9.24655 9.40658 9.40627C9.24685 9.56599 9.16699 9.76391 9.16699 10V13.3334C9.16699 13.5695 9.24685 13.7674 9.40658 13.9271C9.5663 14.0868 9.76421 14.1667 10.0003 14.1667ZM10.0003 7.50002C10.2364 7.50002 10.4344 7.42016 10.5941 7.26044C10.7538 7.10072 10.8337 6.9028 10.8337 6.66669C10.8337 6.43058 10.7538 6.23266 10.5941 6.07294C10.4344 5.91321 10.2364 5.83335 10.0003 5.83335C9.76421 5.83335 9.5663 5.91321 9.40658 6.07294C9.24685 6.23266 9.16699 6.43058 9.16699 6.66669C9.16699 6.9028 9.24685 7.10072 9.40658 7.26044C9.5663 7.42016 9.76421 7.50002 10.0003 7.50002ZM10.0003 18.3334C8.84755 18.3334 7.76421 18.1146 6.75033 17.6771C5.73644 17.2396 4.85449 16.6459 4.10449 15.8959C3.35449 15.1459 2.76074 14.2639 2.32324 13.25C1.88574 12.2361 1.66699 11.1528 1.66699 10C1.66699 8.84724 1.88574 7.76391 2.32324 6.75002C2.76074 5.73613 3.35449 4.85419 4.10449 4.10419C4.85449 3.35419 5.73644 2.76044 6.75033 2.32294C7.76421 1.88544 8.84755 1.66669 10.0003 1.66669C11.1531 1.66669 12.2364 1.88544 13.2503 2.32294C14.2642 2.76044 15.1462 3.35419 15.8962 4.10419C16.6462 4.85419 17.2399 5.73613 17.6774 6.75002C18.1149 7.76391 18.3337 8.84724 18.3337 10C18.3337 11.1528 18.1149 12.2361 17.6774 13.25C17.2399 14.2639 16.6462 15.1459 15.8962 15.8959C15.1462 16.6459 14.2642 17.2396 13.2503 17.6771C12.2364 18.1146 11.1531 18.3334 10.0003 18.3334Z"
            fill={gluestackUIConfig.tokens.colors.primary}
          />
        </g>
      </g>
    </svg>
  );
};

export default InfoIcons;
