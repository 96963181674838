// u-motion-front/src/js/react/common/api/cowApi.ts

import { baseApi } from '~/packages/u-motion-api/uMotionBaseQuery';

export type FetchCowApiResponse = {
  cowId: number;
  cowNo: string;
  cowUid: string;
  state: string;
  eliminateDate: string;
  fallingDeadDate: string;
  cowGroupId: number;
  pen: string;
  occurredMastitisDate: string;
  occurredPerinatalDifficultyDate: string;
  mastitisCowGroupId: number;
  hormoneProgramName: string;
  nextHormoneCode: string;
  nextHormoneName: string;
  stopMilkingDateOfBreastBl: string;
  stopMilkingDateOfBreastBr: string;
  stopMilkingDateOfBreastFl: string;
  stopMilkingDateOfBreastFr: string;
}[];
export type FetchCowApiArg = void;

export type FetchCowBullApiResponse = {
  cowId: number;
  cowNo: string;
  cowUid: string;
  cowName: string;
  breed: string;
  cowLabels: string;
  cowGroupName: string;
}[];
export type FetchCowBullApiArg = void;

export type FetchCowByIdApiResponse = {
  cowId: number;
  birthday: string;
  breed: string;
  buyer: string;
  color: string;
  cowNoForOrder: string;
  gender: string;
  kyosaiNo: string;
  motherKind: string;
  motherName: string;
  cowName: string;
  purchasePrice: string;
  registrationNumber2: string;
  salesPrice: string;
  masterMarketId: number;
  weightOnBirth: string;
  weightOnPurchase: string;
  weightOnSale: string;
  artificialInseminationDate: string;
  beefMedicineNames: string;
  beefWashoutPeriodDaysCount: string;
  beforeCowGroupId: number;
  birthNumber: string;
  bleId: string;
  breedingDifficultyMedicineNames: string;
  breedingExcludeDate: string;
  breedingRemarks: string;
  calvingInterval: string;
  calvingRemarks: string;
  cowUid: string;
  cowInfoRemarks: string;
  cowLabels: string;
  cowNo: string;
  deleteDate: string;
  dryePeriodDaysCount: string;
  dryePeriodRemarks: string;
  eliminateAndFallingDeadRemarks: string;
  eliminateDate: string;
  endDateOfBeefWashoutPeriod: string;
  endDateOfMilkWashoutPeriod: string;
  enterFarmDate: string;
  estrusRemarks: string;
  examinationRemarks: string;
  exitFarmDate: string;
  fallingDeadDate: string;
  fatherName: string;
  fatherRegistrationNo: string;
  fertilizationDateOnPurchase: string;
  firstFertilizationDate: string;
  firstTreatmentDate: string;
  freshCheckResult: string;
  cowGroupId: number;
  cowGroupName: string;
  hormoneCode: string;
  hormoneName: string;
  hormoneProgramName: string;
  infectMedicineNames: string;
  initialBreedingCount: string;
  initialOccurredMastitisCountOfBreastBl: string;
  initialOccurredMastitisCountOfBreastBr: string;
  initialOccurredMastitisCountOfBreastFl: string;
  initialOccurredMastitisCountOfBreastFr: string;
  initialSynchronizationCount: string;
  initialTreatmentMastitisCountOfBreastBl: string;
  initialTreatmentMastitisCountOfBreastBr: string;
  initialTreatmentMastitisCountOfBreastFl: string;
  initialTreatmentMastitisCountOfBreastFr: string;
  injuryMedicineNames: string;
  introduceDate: string;
  introduceRemarks: string;
  judgePregnantRemarks: string;
  lameMedicineNames: string;
  latestAbortDate: string;
  latestBcsDate: string;
  latestBcs: string;
  latestBreedingCount: string;
  latestBreedingMethod: string;
  latestCalvingDate: string;
  latestDryLatePeriodDaysCount: string;
  latestDryLatePeriodStartDate: string;
  latestDryPreviousPeriodDaysCount: string;
  latestDryPreviousPeriodStartDate: string;
  latestEstrusDate: string;
  latestFertilizationDate: string;
  latestHoofTrimDate: string;
  latestHormoneDate: string;
  latestJudgePregnantDate: string;
  latestPregnancyDate: string;
  latestTreatmentDate: string;
  mastitisMedicineNames: string;
  maternalGrandmotherName: string;
  maternalGrandmotherRegistrationNo: string;
  milkMedicineNames: string;
  milkWashoutPeriodDaysCount: string;
  modifyDatetime: string;
  motherRegistrationNo: string;
  motherCowId: number;
  moveCowGroupDate: string;
  nextHormoneCode: string;
  nextHormoneDate: string;
  nextHormoneName: string;
  occurredLameDate: string;
  occurredMastitisCountOfBreastBl: string;
  occurredMastitisCountOfBreastBr: string;
  occurredMastitisCountOfBreastFl: string;
  occurredMastitisCountOfBreastFr: string;
  occurredMastitisDateOfBreastBl: string;
  occurredMastitisDateOfBreastBr: string;
  occurredMastitisDateOfBreastFl: string;
  occurredMastitisDateOfBreastFr: string;
  otherDifficultyMedicineNames: string;
  otherRemarks: string;
  perinatalDifficultyMedicineNames: string;
  pregnancy: string;
  purchasePregnancy: string;
  reproductionCode: string;
  startDateOfBeefWashoutPeriod: string;
  startDateOfMilkWashoutPeriod: string;
  startHormoneProgramDate: string;
  state: string;
  stopMilkingDateOfBreastBl: string;
  stopMilkingDateOfBreastBr: string;
  stopMilkingDateOfBreastFl: string;
  stopMilkingDateOfBreastFr: string;
  stopShipmentBeefFlg: boolean;
  stopShipmentMilkingFlg: boolean;
  synchronizationCount: string;
  synchronizationRemarks: string;
  treatmentMastitisCountOfBreastBl: string;
  treatmentMastitisCountOfBreastBr: string;
  treatmentMastitisCountOfBreastFl: string;
  treatmentMastitisCountOfBreastFr: string;
  treatmentMastitisDateOfBreastBl: string;
  treatmentMastitisDateOfBreastBr: string;
  treatmentMastitisDateOfBreastFl: string;
  treatmentMastitisDateOfBreastFr: string;
  treatmentRemarks: string;
  vaccinationDate: string;
  vaccineName: string;
  vaccineTiming: string;
  expelledReason: string;
  pregnantBreedingMethod: string;
  bullName: string;
  bullRegistrationNo: string;
  bullSpermNo: string;
  bullBreed: string;
  donorRegistrationNo: string;
  donorBreed: string;
  donorName: string;
  donorSpermNo: string;
  motherRegistrationNoOfDonor: string;
  motherNameOfDonor: string;
  fatherSpermNo: string;
  fatherBreed: string;
  maternalGrandfatherSpermNo: string;
  birthCalvingDifficulty: string;
  calfNo: string;
  producingArea: string;
  producingFarmName: string;
  introduceNo: string;
  shipmentNo: string;
  weanedDate: string;
  dehornedDate: string;
  dehornMethod: string;
  castratedDate: string;
  castratMethod: string;
  latestWeight: string;
  latestDg: string;
  latestWeighingDate: string;
  latestBreedingCheckDate: string;
  startFatteningDate: string;
  expectedCalvingDate: string;
  expectedDryDate: string;
  calfInfos: string;
  raisingFarmName: string;
  mastitisAllMedicineNames: string;
  diseaseName: string;
  startTreatmentDate: string;
  introduceSpermNo: string;
  latestMasterSpermNo: string;
  maxMilkMedicineName: string;
  maxBeefMedicineName: string;
  judgePregnantTiming: string;
  birthState: string;
  calvingState: string;
  glycerinCount: string;
  occurredMastitisText: string;
  stopMilkingText: string;
  introduceTransporter: string;
  introduceState: string;
  johneDiagnosisResult: string;
  hormoneDate1: string;
  hormoneName1: string;
  hormoneDate2: string;
  hormoneName2: string;
  hormoneDate3: string;
  hormoneName3: string;
  hormoneDate4: string;
  hormoneName4: string;
  deadProcessCost: string;
  otherReproductionWork: string;
  otherReproductionDate: string;
  occurredMastitisDate: string;
  occurredPerinatalDifficultyDate: string;
  grade: string;
  yieldGrade: string;
  meetGrade: string;
  beforeSlaughterWeight: string;
  dressedCarcassWeightOfL: string;
  dressedCarcassWeightOfR: string;
  loinArea: string;
  ribsThickness: string;
  subcutaneousFat: string;
  yieldBaseValue: string;
  bmsNo: string;
  marblingGrade: string;
  bcsNo: string;
  gloss: string;
  bcsAndGlossGrade: string;
  tight: string;
  texture: string;
  tightAndTextureGrade: string;
  bfsNo: string;
  fatLuster: string;
  bfsAndFatLusterGrade: string;
  fault: string;
  otherFault: string;
  otherFaultCorrection: string;
  dressedCarcassUnitPrice: string;
  dressedCarcassSalesPrice: string;
  visceralDestruction: string;
  salesDate: string;
  maternalGrandfatherName: string;
  maternalGreatGrandfatherName: string;
  slaughterNo: string;
  totalDressedCarcassWeight: string;
  deathReason: string;
  createdAt: string;
  updatedAt: string;
  mastitisCowGroupId: number;
  active: boolean;
  pumpCount: string;
  latestBullName: string;
  motherCowUid: string;
  motherCowNo: string;
  motherBreedingValue: string;
  recipientCowUid: string;
  recipientName: string;
  recipientCowNo: string;
  recipientRegistrationNo: string;
  recipientKind: string;
  fatherBrevityCode: string;
  fatherBreedingValue: string;
  maternalGrandfatherRegistrationNo: string;
  maternalGreatGrandfatherRegistrationNo: string;
  maternalGreatGrandfatherSpermNo: string;
  maternalGrandmotherCowNo: string;
  maternalGrandmotherBreed: string;
  bullBrevityCode: string;
  bullBreedingValue: string;
  donorCowUid: string;
  donorBreedingValue: string;
  fatherRegistrationNoOfDonor: string;
  fatherNameOfDonor: string;
  motherBreedOfDonor: string;
  maternalGrandfatherRegistrationNoOfDonor: string;
  maternalGrandfatherSpermNoOfDonor: string;
  maternalGrandfatherNameOfDonor: string;
  introduceBullName: string;
  introduceBullRegistrationNo: string;
  introduceBullBrevityCode: string;
  introduceBullBreed: string;
  introduceBullBreedingValue: string;
  registrationNo: string;
  breedingValue: string;
  breedingPoint: string;
  pen: string;
  beforePen: string;
  breedingExclusionReason: string;
  motherBirthNumber: string;
  recipientBirthNumber: string;
  embryoRecoveryDate: string;
  breedingBull: boolean;
  maternalFather4thRegistrationNo: string;
  maternalFather4thName: string;
  maternalFather4thSpermNo: string;
};
export type FetchCowByIdApiArg = { cowId: number };

export type FetchCowSummaryApiResponse = {
  breeding: number;
  delivered: number;
  milking: number;
  dry: number;
  all: number;
  milkingAndDry: number;
  afterParturition0To30: number;
  afterParturition30To60: number;
  afterParturition60To90: number;
  afterParturition90To120: number;
  afterParturition120Over: number;
  ageBull0To6: number;
  ageBull6To12: number;
  ageBull12To18: number;
  ageBull18To24: number;
  ageBull24Over: number;
  ageCow0To6: number;
  ageCow6To12: number;
  ageCow12To18: number;
  ageCow18To24: number;
  ageCow24Over: number;
  stages: [
    {
      fatteningStageId: number;
      name: string;
      startMonthsOfAge: number;
      endMonthsOfAge: number;
      maleCount: number;
      femaleCount: number;
    },
    {
      fatteningStageId: number;
      name: string;
      startMonthsOfAge: number;
      endMonthsOfAge: number;
      maleCount: number;
      femaleCount: number;
    },
    {
      fatteningStageId: number;
      name: string;
      startMonthsOfAge: number;
      endMonthsOfAge: number;
      maleCount: number;
      femaleCount: number;
    },
    {
      fatteningStageId: number;
      name: string;
      startMonthsOfAge: number;
      endMonthsOfAge: number;
      maleCount: number;
      femaleCount: number;
    },
  ];
};
export type FetchCowSummaryApiArg = void;

export type FetchCowCowNoApiResponse = {
  cowId: number;
  cowNo: string;
  cowUid: string;
  state: string;
  eliminateDate: string;
  fallingDeadDate: string;
  cowGroupId: number;
  pen: string;
  occurredMastitisDate: string;
  occurredPerinatalDifficultyDate: string;
  mastitisCowGroupId: number;
  hormoneProgramName: string;
  nextHormoneCode: string;
  nextHormoneName: string;
}[];
export type FetchCowCowNoApiArg = void;

export type FetchCowSearchApiResponse = {
  active: boolean;
  birthday: string;
  bleId: string;
  cowGroupId: number;
  cowGroupName: string;
  cowId: number;
  cowNo: string;
  cowNoForOrder: string;
  cowUid: string;
  eliminateDate: string;
  fallingDeadDate: string;
  introduceDate: string;
  masterMarketName: string;
  pen: string;
  state: string;
}[];

export type FetchCowSearchApiArg = {
  searchType: 'cow_no' | 'cow_uid' | 'ble_id';
  searchId: string;
};

const CACHE_TAG_TYPE = 'Cow';
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [CACHE_TAG_TYPE] });

export const cowApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchCow: builder.query<FetchCowApiResponse, FetchCowApiArg>({
      query: (response) => ({
        url: '/cow',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowBull: builder.query<FetchCowBullApiResponse, FetchCowBullApiArg>({
      query: (response) => ({
        url: '/cow/bull',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowById: builder.query<FetchCowByIdApiResponse, FetchCowByIdApiArg>({
      query: ({ cowId }) => `/cow/${cowId}`,
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowSummary: builder.query<FetchCowSummaryApiResponse, FetchCowSummaryApiArg>({
      query: (response) => ({
        url: '/cow/summary',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowCowNo: builder.query<FetchCowCowNoApiResponse, FetchCowCowNoApiArg>({
      query: (response) => ({
        url: '/cow/cow_no',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowSearch: builder.query<FetchCowSearchApiResponse, FetchCowSearchApiArg>({
      query: ({ searchType, searchId }) => ({
        url: `/cow/search?${searchType}=${searchId}`,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchCowQuery,
  useFetchCowBullQuery,
  useFetchCowByIdQuery,
  useFetchCowSummaryQuery,
  useFetchCowCowNoQuery,
  useFetchCowSearchQuery,
} = cowApi;
