import { Ionicons } from '@expo/vector-icons';
import {
  Box,
  HStack,
  VStack,
  ScrollView,
  Heading,
  Text,
  Image,
  Icon,
  PresenceTransition,
  Pressable,
} from '@gluestack-ui/themed-native-base';
import { useMemo } from 'react';
import { ChannelMemberResponse } from 'stream-chat';
import { SearchHistoryItem } from '~/components/SearchHistoryItem';
import { UsersChannelItem } from '~/components/UsersChannelItem';
import { useSearchHistory } from '~/hooks/useSearchHistory';

interface Props {
  query: string;
  onSubmit: (query: string | undefined) => void;
  usersChannels: any;
  userList: ChannelMemberResponse[];
  targetUser: ChannelMemberResponse | undefined;
  onPressUser: (user: ChannelMemberResponse) => void;
}

export const SearchHistory: React.FC<Props> = ({
  query,
  onSubmit,
  usersChannels,
  userList,
  targetUser,
  onPressUser,
}) => {
  const {
    userSetting: { data },
  } = useSearchHistory();

  const searchWithUsername = useMemo(() => {
    return query.toLowerCase().startsWith('from:');
  }, [query]);

  if (!data) {
    return null;
  }

  return (
    <Box flex={1} position="relative">
      {searchWithUsername && (
        <ScrollView
          position="absolute"
          top="none"
          left="none"
          bottom="none"
          width="full"
          zIndex={2}
          _contentContainerStyle={{
            px: '3sx',
            pt: '3xs',
            pb: 'sm',
          }}
        >
          <PresenceTransition
            visible={searchWithUsername && !targetUser}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 100,
              },
            }}
          >
            <VStack backgroundColor="white" paddingY="3xs" shadow={4} rounded="lg">
              {userList.length === 0 && (
                <Text fontSize="sm" paddingX="3xs" paddingY={1}>
                  ユーザーが見つかりませんでした
                </Text>
              )}
              {userList.map((item) => (
                <Pressable
                  key={`user-${item.user_id}`}
                  onPress={() => onPressUser(item)}
                  padding="3xs"
                  _hover={{ backgroundColor: '#f6f6f6' }}
                >
                  <HStack alignItems="center" space="3xs">
                    {item.user?.image ? (
                      <Image
                        source={{ uri: item.user.image as string }}
                        alt={item.user?.name}
                        size={8}
                        rounded="full"
                      />
                    ) : (
                      <Icon as={Ionicons} name="person-circle-outline" size={8} />
                    )}
                    <Heading flex={1} size="sm" paddingY={1} noOfLines={1}>
                      {item.user?.name}
                    </Heading>
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          </PresenceTransition>
        </ScrollView>
      )}
      <ScrollView keyboardShouldPersistTaps="always">
        <VStack padding="md">
          <VStack>
            <Heading size="sm" paddingHorizontal="none" padding="xs" color="onSurface">
              最近の検索
            </Heading>
            <VStack>
              {data?.details?.search_history
                ?.filter((item) => item.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
                .reverse()
                .map((item, i) => <SearchHistoryItem query={item} key={item} onSubmit={onSubmit} />)}
            </VStack>
          </VStack>
          <VStack>
            {query &&
            usersChannels?.filter((item: any) => item.data.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
              .length > 0 ? (
              <>
                <Heading size="sm" paddingHorizontal="none" padding="xs" color="onSurface">
                  ルーム
                </Heading>
                <VStack>
                  {usersChannels
                    ?.filter((item: any) => item.data.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
                    .slice(0, 20)
                    .map((item: any, i: any) => <UsersChannelItem channel={item} key={item.cid} />)}
                </VStack>
              </>
            ) : null}
          </VStack>
        </VStack>
      </ScrollView>
    </Box>
  );
};
