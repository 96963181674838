import { HStack, Pressable, Text, VStack } from '@gluestack-ui/themed-native-base';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import ArrowLeftIcons from '~/icons/ArrowLeftIcons';
import type { Channel as StreamChannel } from 'stream-chat';
export const CustomThreadHeader: React.FC<{
  closeThread: any;
  thread: any;
  channel: StreamChannel;
}> = ({ closeThread, thread, channel }) => {
  return (
    <HStack
      px="md"
      py="sm"
      alignItems="center"
      borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
      gap="2xs"
    >
      <Pressable onPress={closeThread} pr="xs">
        <ArrowLeftIcons />
      </Pressable>
      <VStack>
        <Text fontSize="lg" fontWeight="bold" color="onSurface">
          スレッド
        </Text>
        <Text fontSize="sm" fontWeight="regular" color="onSurface">
          {channel?.data?.name}
        </Text>
      </VStack>
    </HStack>
  );
};
