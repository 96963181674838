import React from 'react';

const EditIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_2632_34149" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2632_34149)">
        <path
          d="M4.19963 20.9749C3.84963 21.0582 3.54546 20.9707 3.28713 20.7124C3.0288 20.4541 2.9413 20.1499 3.02463 19.7999L3.89963 15.5499L8.44963 20.0999L4.19963 20.9749ZM10.0746 18.8749L5.12463 13.9249L15.4496 3.5999C15.833 3.21657 16.308 3.0249 16.8746 3.0249C17.4413 3.0249 17.9163 3.21657 18.2996 3.5999L20.3996 5.6999C20.783 6.08324 20.9746 6.55824 20.9746 7.1249C20.9746 7.69157 20.783 8.16657 20.3996 8.5499L10.0746 18.8749Z"
          fill="#737373"
        />
      </g>
    </svg>
  );
};

export default EditIcons;
