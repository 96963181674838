import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { ShowPinMessagesProvider } from '~/contexts/PinMessageContext';

export const ChatDMListScreen: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute();
  // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
  const channelId = route?.params?.channelId;
  // @ts-expect-error TS(2339): Property 'messageId' does not exist on type 'objec... Remove this comment to see the full error message
  const messageId = route?.params?.messageId;

  return (
    <ShowPinMessagesProvider>
      <ChatChannelListWeb type="dm" channelId={channelId} messageId={messageId} isDM />
    </ShowPinMessagesProvider>
  );
};
