import { VStack, HStack, Alert, Button, Text } from 'native-base';
import { useCallback } from 'react';
import { FlatListTable } from './FlatListTable';

export const AlertDupFound = ({ dupSensorCows, onCancel, onConfirm }) => {
  const renderDupItem = useCallback(({ item }: { item: any }) => {
    return (
      <HStack space="md" borderWidth={1}>
        <Text width="50%">{item.cowUid}</Text>
        <Text width="50%">{item.bleId}</Text>
      </HStack>
    );
  }, []);

  return (
    <VStack space="lg" p={4} flex={1}>
      <VStack space="md">
        <Alert status="warning">
          既に他の個体に登録されているセンサーが見つかりました。このセンサーを別の個体に使用する場合は、先に現在の登録を解除する必要があります。
        </Alert>

        <FlatListTable
          labels={['個体識別番号', 'センサー番号']}
          properties={['cowUid', 'bleId']}
          data={dupSensorCows}
          renderItem={renderDupItem}
        />
      </VStack>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        borderTopWidth={1}
        borderColor="gray.100"
        paddingTop={4}
      >
        <Button onPress={onCancel} variant="ghost">
          キャンセル
        </Button>
        <Button onPress={onConfirm}>除外して紐づけ処理を進める</Button>
      </HStack>
    </VStack>
  );
};
