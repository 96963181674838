import { Foundation } from '@expo/vector-icons';
import { Box, HStack, VStack, Pressable, Text, Image, useToken } from '@gluestack-ui/themed-native-base';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ImageEditor } from 'expo-image-editor';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { useState, useCallback } from 'react';
import { useGetCurrentUserQuery, usePutUsersByIdMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { BaseScreenBuilder, BaseFormBuilder, type FieldsProps } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useAuth } from '~/hooks';
import type { SettingsStackParamList } from '~/routes/type';
import type { FC } from 'react';

const fields: FieldsProps = [
  {
    type: 'text',
    key: 'name',
    label: '名前',
    rules: {
      required: {
        value: true,
        message: '名前を入力してください',
      },
      maxLength: {
        value: 15,
        message: '15文字以内で入力してください',
      },
    },
  },
  {
    type: 'text',
    key: 'yomi',
    label: '名前(カナ)',
    rules: {
      required: {
        value: true,
        message: '名前(カナ)を入力してください',
      },
      pattern: {
        value: /^[ァ-ンヴー\s]*$/,
        message: '全角カタカナで入力してください',
      },
    },
  },
];

export const SettingsProfileScreen: FC = () => {
  const { isUmotionUser } = useAuth();
  const navigation = useNavigation<NavigationProp<SettingsStackParamList>>();
  const [primary] = useToken('colors', ['primary']);
  const queryResult = useGetCurrentUserQuery({});
  const [update, { isLoading }] = usePutUsersByIdMutation();

  const [shouldShowEditor, setShouldShowEditor] = useState(false);
  const [imageUri, setImageUri] = useState('');

  const selectPhoto = useCallback(async () => {
    const response = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (response.granted) {
      const pickerResult = await ImagePicker.launchImageLibraryAsync();
      if (!pickerResult.canceled) {
        setImageUri(pickerResult.assets[0].uri);
        setShouldShowEditor(true);
      }
    } else {
      Alert.alert('システムの設定からこのアプリケーションが写真にアクセスする許可をしてください。', '', []);
    }
  }, []);

  const onEditingComplete = useCallback(
    async (cropResult: any) => {
      const formData = new FormData();

      const resizeResult = await manipulateAsync(
        cropResult.uri,
        [
          {
            resize: {
              width: 200,
              height: 200,
            },
          },
        ],
        { compress: 1, format: SaveFormat.JPEG }
      );

      // ファイルをバイナリ化
      const bin = atob(resizeResult.uri.replace(/^.*,/, ''));

      // バイナリデータに変換する
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }

      // Fileオブジェクトを生成
      const imageFile = new File([buffer.buffer], 'profile_image', { type: 'image/jpeg' });
      formData.append('user[avatar]', imageFile);

      update({
        id: queryResult.data?.id ?? 0,
        // @ts-expect-error TS(2741): Property 'user' is missing in type 'FormData' but ... Remove this comment to see the full error message
        body: formData,
      })
        .unwrap()
        .catch((error) => {
          Alert.alert(error, '', []);
        });
    },
    [queryResult.data?.id]
  );

  const onSubmit = useCallback(
    async (sendingData: Record<string, string>) => {
      const formData = new FormData();
      for (const key in sendingData) {
        formData.append(`user[${key}]`, sendingData[key]);
      }

      return update({
        id: queryResult.data?.id ?? 0,
        // @ts-expect-error TS(2741): Property 'user' is missing in type 'FormData' but ... Remove this comment to see the full error message
        body: formData,
      })
        .unwrap()
        .then(navigation.goBack);
    },
    [queryResult.data?.id]
  );

  return (
    <ScreenWidthModal title="プロフィール">
      <BaseScreenBuilder title="プロフィール">
        <VStack gap="md">
          <HStack justifyContent="center">
            <Pressable onPress={selectPhoto} position="relative">
              {queryResult?.data?.avatar ? (
                <Image
                  source={{ uri: queryResult?.data?.avatar }}
                  alt="プロフィール画像"
                  width={20}
                  height={20}
                  borderRadius="full"
                />
              ) : (
                <Box width={20} height={20} backgroundColor="onSurfaceBright" borderRadius="full" />
              )}
              <Box position="absolute" right="0" bottom="0" backgroundColor="primary" p="2xs" borderRadius="full">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_331_8831)">
                    <path
                      d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                      fill="white"
                    />
                    <path
                      d="M16.6665 3.33317H14.0248L12.9915 2.20817C12.6832 1.8665 12.2332 1.6665 11.7665 1.6665H8.23317C7.7665 1.6665 7.3165 1.8665 6.99984 2.20817L5.97484 3.33317H3.33317C2.4165 3.33317 1.6665 4.08317 1.6665 4.99984V14.9998C1.6665 15.9165 2.4165 16.6665 3.33317 16.6665H16.6665C17.5832 16.6665 18.3332 15.9165 18.3332 14.9998V4.99984C18.3332 4.08317 17.5832 3.33317 16.6665 3.33317ZM9.99984 14.1665C7.69984 14.1665 5.83317 12.2998 5.83317 9.99984C5.83317 7.69984 7.69984 5.83317 9.99984 5.83317C12.2998 5.83317 14.1665 7.69984 14.1665 9.99984C14.1665 12.2998 12.2998 14.1665 9.99984 14.1665Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_331_8831">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
            </Pressable>
          </HStack>
          {isUmotionUser ? (
            <>
              <HStack alignItems="center" justifyContent="space-between">
                <VStack>
                  <Text color="onSurface" fontSize="sm" lineHeight="sm">
                    名前
                  </Text>
                  <Text color="onSurface" fontSize="md">
                    {queryResult?.data?.name}
                  </Text>
                </VStack>
              </HStack>
              <HStack alignItems="center" justifyContent="space-between">
                <VStack>
                  <Text color="onSurface" fontSize="sm" lineHeight="sm">
                    名前(カナ)
                  </Text>
                  <Text color="onSurface" fontSize="md">
                    {queryResult?.data?.yomi}
                  </Text>
                </VStack>
              </HStack>
              <VStack backgroundColor="surface" p="sm" rounded="md">
                <HStack gap="xs" alignItems="center" flex={1} w="100%" pr="md">
                  <Foundation name="info" size={20} color={primary} />
                  <Text color="onSurface" bold>
                    U-motion IDでログイン中:
                  </Text>
                </HStack>
                <Text color="onSurface" lineHeight="xs">
                  名前は、U-motionのユーザー管理画面で変更できます
                </Text>
              </VStack>
            </>
          ) : (
            <BaseFormBuilder
              defaultValues={{
                name: queryResult?.data?.name ?? '',
                yomi: queryResult?.data?.yomi ?? '',
              }}
              // @ts-expect-error
              queryResult={queryResult}
              fields={fields}
              onSubmit={onSubmit}
              onCancel={navigation.goBack}
              isLoading={isLoading}
            />
          )}
        </VStack>
        <ImageEditor
          visible={shouldShowEditor}
          onCloseEditor={() => setShouldShowEditor(false)}
          imageUri={imageUri}
          fixedCropAspectRatio={1}
          lockAspectRatio
          minimumCropDimensions={{
            width: 100,
            height: 100,
          }}
          onEditingComplete={onEditingComplete}
          throttleBlur={false}
          mode="crop-only"
        />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
