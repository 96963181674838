import { Avatar, HStack, Pressable, ScrollView, Spinner, Stack, Text, VStack } from '@gluestack-ui/themed-native-base';
import { SearchIndex } from 'emoji-mart';
import normalizeUrl from 'normalize-url';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Attachment,
  Channel,
  CustomMessageActionsListProps,
  MessageInput,
  MessageList,
  renderText,
  ScrollToBottomButton,
  Thread,
  Window,
} from 'stream-chat-react';
import { channelType, getStreamChatChannelTypeById } from '../ChatChannelMessagesScreen/channelType';
import { ChatCustomThreadInput, CustomMessageAvatar } from '~/components';
import { ChatCustomMessage } from '~/components/ChatCustomMessage';
import { ChatCustomPinIndicator } from '~/components/ChatCustomPinIndicator';
import ChatCustomReactionSelectorWeb from '~/components/ChatCustomReactionSelectorWeb.web';
import ChatCustomReactionsListWeb from '~/components/ChatCustomReactionsListWeb';
import { anchorComponent } from '~/components/CustomAnchorComponent.web';
import { CustomSuggestionItem } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionItem.web';
import { CustomSuggestionList } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionList.web';
import { CustomDateSeparator } from '~/components/CustomDateSeparator';

import { CustomHeader } from '~/components/CustomHeader.web';
import { CustomMessageActionsListWeb } from '~/components/CustomMessageActionsListWeb';
import CustomMessageOptions from '~/components/CustomMessageOptions.web';
import { CustomMessageTimestamp } from '~/components/CustomMessageTimestamp.web';
import { CustomThreadHeader } from '~/components/CustomThreadHeader';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { useDraft } from '~/hooks/useDraft.web';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import CloseIcons from '~/icons/CloseIcons';
import CreateColumnIcons from '~/icons/CreateColumnIcons';
import HomeIcons from '~/icons/HomeIcons';
import { useAllChannel } from './index.web';
import type { Channel as StreamChannel, UserResponse } from 'stream-chat';
import type { AttachmentProps, ThreadHeaderProps } from 'stream-chat-react';

const ChannelWindow = (props: { width: number }) => {
  const { width } = props;
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();

  const customRenderText = useCallback(
    (text?: string, mentionedUsers?: UserResponse[]) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  const CustomThreadInputWrapper = useCallback(
    () => <ChatCustomThreadInput isChecked={isCheckedRef.current} toggleChecked={toggleCheckedRef} isThread />,
    [isCheckedRef, toggleCheckedRef]
  );

  return (
    <HStack width={width}>
      <Window hideOnThread>
        <MessageList messageLimit={20} renderText={customRenderText} />
        <MessageInput
          overrideSubmitHandler={overrideSubmitHandler}
          shouldSubmit={shouldSubmit}
          grow
          mentionQueryParams={{
            options: { limit: 100 },
          }}
        />
      </Window>
      <Thread
        Input={CustomThreadInputWrapper}
        additionalMessageInputProps={{
          overrideSubmitHandler,
          shouldSubmit,
          grow: true,
          mentionQueryParams: {
            options: { limit: 100 },
          },
        }}
      />
    </HStack>
  );
};

const ChannelColumnInner = (props: { channel: StreamChannel; width: number }) => {
  const { channel, width } = props;

  const isDM = useMemo(() => channelType(channel?.cid ?? '') === 'DM', [channel]);
  const { removePinnedChannel } = usePinnedChannels();

  const CustomEditInputWrapper = useCallback(() => <ChatCustomThreadInput isEdit />, []);

  const renderAttachment = useCallback((props: AttachmentProps) => {
    // Remove duplicate attachments by title and title_link
    const uniqueAttachments = props.attachments.filter(
      (item, index, self) =>
        !item.og_scrape_url || // affects only OGAttachment
        index ===
          self.findIndex(
            (t) =>
              !!t.og_scrape_url && // comparison target must be OGAttachment
              t.title === item.title &&
              (t.title_link ? normalizeUrl(t.title_link) : '') ===
                (item.title_link ? normalizeUrl(item.title_link) : '')
          )
    );
    return <Attachment {...props} attachments={uniqueAttachments} />;
  }, []);

  const renderUnreadMessagesSeparatorEmpty = useCallback(() => {
    return null;
  }, []);

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  const ThreadHeaderCustom = useCallback(
    (props: ThreadHeaderProps) => {
      return <CustomThreadHeader closeThread={props.closeThread} thread={props.thread} channel={channel} />;
    },
    [channel]
  );

  return (
    <HStack flex={1} width="100%">
      <Channel
        channel={channel}
        channelQueryOptions={{
          messages: {
            limit: 20,
          },
        }}
        Avatar={CustomMessageAvatar}
        EditMessageInput={CustomEditInputWrapper}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<Omit<any, "ref"> &... Remove this comment to see the full error message
        ReactionSelector={ChatCustomReactionSelectorWeb}
        ReactionsList={ChatCustomReactionsListWeb}
        // @ts-expect-error TS(2322): Type '(props: Props) => React.JSX.Element' is not ... Remove this comment to see the full error message
        AutocompleteSuggestionList={CustomSuggestionList}
        // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<CustomSuggestionIt... Remove this comment to see the full error message
        AutocompleteSuggestionItem={CustomSuggestionItem}
        Message={ChatCustomMessage}
        MessageNotification={ScrollToBottomButton}
        CustomMessageActionsList={renderMessageActionList}
        MessageTimestamp={CustomMessageTimestamp}
        MessageOptions={CustomMessageOptions}
        Input={ChatCustomThreadInput}
        Attachment={renderAttachment}
        HeaderComponent={() => (
          <CustomHeader channelSelected={channel} multiSection onClose={() => removePinnedChannel(channel.cid)} />
        )}
        ThreadHeader={ThreadHeaderCustom}
        emojiSearchIndex={SearchIndex}
        DateSeparator={CustomDateSeparator}
        UnreadMessagesSeparator={channel?.countUnread() == 0 ? renderUnreadMessagesSeparatorEmpty : undefined}
        PinIndicator={ChatCustomPinIndicator}
      >
        <ChannelWindow width={width} />
      </Channel>
    </HStack>
  );
};

type Props = {
  width: number;
  channel?: StreamChannel;
};

export const ChannelColumn = ({ width: columnWidth, channel: propChannel }: Props) => {
  const [selectedChannel, setSelectedChannel] = useState<StreamChannel | undefined>(propChannel);

  const { allChannels } = useAllChannel();
  const { pinnedChannels } = usePinnedChannels();

  useEffect(() => {
    setSelectedChannel(propChannel);
  }, [propChannel, pinnedChannels]);

  if (allChannels?.length < 1) {
    return <Spinner />;
  }
  return (
    <HStack width={columnWidth} height="100%" flexGrow={0}>
      {selectedChannel && (
        <VStack height="100%">
          <ChannelColumnInner channel={selectedChannel} width={columnWidth} />
        </VStack>
      )}
    </HStack>
  );
};

export const AddChannelColumn = () => {
  const [isOpenChoose, setIsOpenChoose] = useState<boolean>(false);

  const { allChannels } = useAllChannel();
  const { addPinnedChannel } = usePinnedChannels();

  return (
    <HStack height="100%" flexGrow={0}>
      {!isOpenChoose ? (
        <VStack justifyContent="center" height="100%" backgroundColor="surface">
          <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
            <HStack px="sm" gap="2xs" alignItems="center">
              <CreateColumnIcons />
              <Text fontSize="md" fontWeight="bold" color="primary">
                列を追加
              </Text>
            </HStack>
          </Pressable>
        </VStack>
      ) : (
        <VStack height="100%" backgroundColor="surfaceBrightest">
          <HStack
            px="md"
            py="xl"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <Text fontSize="xl" fontWeight="bold" color="onSurface">
              列を追加
            </Text>
            <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
              <CloseIcons />
            </Pressable>
          </HStack>
          <ScrollView flex="1">
            <VStack p="md">
              <Stack py="2xs">
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  1つ選択してください
                </Text>
              </Stack>
              {allChannels.map((channel) => (
                <Pressable
                  onPress={() => {
                    addPinnedChannel(channel.cid);
                    setIsOpenChoose(!isOpenChoose);
                  }}
                  key={channel.cid}
                >
                  <HStack py="xs" gap="xs" alignItems="center">
                    <Avatar
                      // @ts-expect-error TS(2239)
                      source={{ uri: channel?.data?.created_by?.image }}
                      height="30"
                      width="30"
                      backgroundColor="onSurfaceBrightest"
                    />
                    <HStack gap="2xs" alignItems="center">
                      {!channel?.data?.isDM && (
                        <Stack>
                          <HomeIcons />
                        </Stack>
                      )}

                      <Text fontSize="md" fontWeight="bold" color="onSurface">
                        {channel.data?.name}
                      </Text>
                    </HStack>
                  </HStack>
                </Pressable>
              ))}
            </VStack>
          </ScrollView>
        </VStack>
      )}
    </HStack>
  );
};

export const ChannelColumnWithId = ({ width, channelId }: { width: number; channelId: string }) => {
  const { chatClient } = useStreamChatAuthContext();
  const [channel, setChannel] = useState<StreamChannel | undefined>();

  useEffect(() => {
    const initChannel = async (id: string) => {
      const channelId = id?.split(':').slice(-1).join('');
      const channel = chatClient?.channel(getStreamChatChannelTypeById(id), channelId);

      if (!channel?.initialized) {
        await channel.watch();
      }

      setChannel(channel);
    };

    if (initChannel) {
      initChannel(channelId);
    }
  }, [channelId]);

  return (
    <HStack height="full">
      <ChannelColumn width={width} channel={channel} />
    </HStack>
  );
};
