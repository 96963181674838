import { Ionicons } from '@expo/vector-icons';
import { Box, Text, Image, Pressable, useToken } from '@gluestack-ui/themed-native-base';
import { useLinkTo } from '@react-navigation/native';
import { Video, ResizeMode } from 'expo-av';
import format from 'format-duration';
import { useState } from 'react';
import type { Media } from '~/components/ChannelInfo/Media';
import type { ReactElement } from 'react';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

type Props = {
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
  item: Media;
};

const ChannelInfoMediaContent = ({ appChannel, item }: Props): ReactElement | null => {
  const linkTo = useLinkTo();
  const [surfaceBrightest] = useToken('colors', ['surfaceBrightest']);

  const [duration, setDuration] = useState<number>();

  return (
    <>
      {item.type === 'video' ? (
        <Pressable
          onPress={() => linkTo(`/chat/${appChannel?.id}/${item.id}`)}
          width="full"
          flex={1}
          p="3xs"
          position="relative"
        >
          <Video
            source={{ uri: item.url }}
            style={{ aspectRatio: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            resizeMode={ResizeMode.COVER}
            onReadyForDisplay={(videoData) => {
              // @ts-ignore
              videoData.srcElement.style.position = 'initial';
            }}
            onLoad={(videoData) => {
              // @ts-ignore
              setDuration(Math.floor(videoData.target.duration));
            }}
          />
          <Box position="absolute" bottom="2xs" left="2xs">
            <Ionicons name="play" color={surfaceBrightest} size={20} />
          </Box>
          {duration && (
            <Box position="absolute" bottom="2xs" right="xs">
              <Text color="surfaceBrightest" fontSize="sm">
                {format(duration * 1000)}
              </Text>
            </Box>
          )}
        </Pressable>
      ) : (
        <Pressable onPress={() => linkTo(`/chat/${appChannel?.id}/${item.id}`)} width="full" flex={1} p="3xs">
          <Image source={{ uri: item.url }} alt="" aspectRatio={1} />
        </Pressable>
      )}
    </>
  );
};

export default ChannelInfoMediaContent;
