import { baseApi as api } from '../uMotionBaseQuery';

const addTagTypes = ['BleId'] as const;

const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCowBleIds: build.query<GetCowBleIdsApiResponse, GetCowBleIdsApiArg>({
        query: (queryArg) => ({ url: `/ble_id/cow/${queryArg.cowId}`, method: 'GET' }),
        providesTags: ['BleId'],
      }),
      unequipBleId: build.mutation<UnequipBleIdApiResponse, UnequipBleIdApiArg>({
        query: (queryArg) => ({
          url: `/ble_id/cow/${queryArg.cowId}`,
          method: 'PUT',
          body: { endDate: queryArg.endDate },
          validateStatus: (response, body) => response.status === 200,
          responseHandler: async (response) => {
            // レスポンスが空の場合は null を返す
            const text = await response.text();
            if (!text) return null;
            try {
              return JSON.parse(text);
            } catch {
              return null;
            }
          },
        }),
        invalidatesTags: ['BleId'],
      }),
      equipBleId: build.mutation<EquipBleIdApiResponse, EquipBleIdApiArg>({
        query: (queryArg) => ({
          url: `/ble_id/cow`,
          method: 'POST',
          body: queryArg,
          validateStatus: (response, body) => response.status === 200,
          responseHandler: async (response) => {
            // レスポンスが空の場合は null を返す
            const text = await response.text();
            if (!text) return null;
            try {
              return JSON.parse(text);
            } catch {
              return null;
            }
          },
        }),
        invalidatesTags: ['BleId'],
      }),
      deleteBleIdRecord: build.mutation<DeleteBleIdRecordApiResponse, DeleteBleIdRecordApiArg>({
        query: (queryArg) => ({
          url: `/ble_id/cow/${queryArg.cowId}/${queryArg.date}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['BleId'],
      }),
    }),
    overrideExisting: false,
  });

export { injectedRtkApi as bleIdApi };

export type GetCowBleIdsApiResponse = /** status 200 成功 */ CowBleId[];
export type GetCowBleIdsApiArg = {
  cowId: string;
};

export type UnequipBleIdApiResponse = /** status 200 成功 */ void;
export type UnequipBleIdApiArg = {
  cowId: string;
  endDate: string;
};

export type EquipBleIdApiResponse = /** status 201 作成成功 */ void;
export type EquipBleIdApiArg = EquipParams;

export type DeleteBleIdRecordApiResponse = /** status 204 削除成功 */ void;
export type DeleteBleIdRecordApiArg = {
  cowId: string;
  date: string;
};

export type CowBleId = {
  bleId: string;
  startDate: string;
  endDate: string;
  sensorType: number;
};

export type EquipParams = {
  cowId: number;
  bleId: string;
  startDate: number;
};

export const { useGetCowBleIdsQuery, useUnequipBleIdMutation, useEquipBleIdMutation, useDeleteBleIdRecordMutation } =
  injectedRtkApi;
