import { Center, Box, useColorModeValue } from '@gluestack-ui/themed-native-base';
import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';

interface Props {
  web?: number;
  backgroundColor?: string;
}

export const ScreenWidthAdjuster: React.FC<Props & React.PropsWithChildren> = ({
  children,
  web = 800,
  backgroundColor = 'white',
}) => {
  const { width } = useWindowDimensions();

  return Platform.OS !== 'web' ? (
    <>{children}</>
  ) : (
    <Center flex={1} backgroundColor={backgroundColor}>
      <Box
        backgroundColor={useColorModeValue('white', 'rgb(18, 18, 18)')}
        width={{ base: width, md: web < width ? web : width }}
        flex={1}
      >
        {children}
      </Box>
    </Center>
  );
};
